.booking-view {
	padding: 70px 0 50px;

	h1 {
		font-weight: 600;
		color: var(--secondary);
	}

	p.lead {}

	h2 {
		font-size: 26px;
		display: flex;
		align-items: baseline;
		font-weight: 600;
		line-height: 24px;
		justify-content: flex-start;
		margin: 0 0 30px;
	}

	.Direkt-Title {
		display: flex;
		align-items: center;
		justify-content: space-between;

		@media (max-width: 767px) {
			flex-wrap: wrap;
		}

		span {
			margin: 0 0 15px 0;
			font-weight: 600;

			@media (max-width: 767px) {
				margin: 0 0 30px 0;
				width: 100%;
			}
		}
	}

	.checkbox {

		label:after,
		label:before {}

		label {
			font-weight: normal;
			line-height: 19px;

			&:after {
				top: -2px !important;
			}
		}

		a {
			position: relative;
			top: 0;
			color: var(--black);
			text-decoration: underline !important;
		}

	}

	.privacy-area {
		a {
			top: 0 !important;
		}
	}

	.form-control {
		border: 1px solid #f2f2f2;
		/*text-transform: uppercase;*/
		margin-bottom: 10px;
		border-radius: 0;
		box-shadow: inherit;
		padding: 0;
		height: 40px;
		background-color: #f1f5f6;
		padding: 0 15px;
	}

	textarea {
		height: 80px !important;
		padding: 10px 15px !important;
	}

	.cart-details {
		background-color: #cbd5dd;
		padding: 15px;
		box-shadow: none;
		max-width: 450px;
		margin: 55px 0 20px;

		.quoteselect {
			display: none;
		}

		h4 {
			color: var(--secondary);
		}

		.price-table {

			.popover {
				font-size: 12px;
				background-color: #fff6df;
				border: 1px solid #febb02 !important;
				z-index: 2;

				.popover-title {
					text-transform: capitalize;
					padding: 0 0 10px 0;
					margin: 0;
					font-size: 12px !important;
					background-color: transparent;
					border-bottom: none;
					border-radius: 5px 5px 0 0;
					font-family: var(--d-font);
					font-weight: 400;
					margin-bottom: 10px;
					padding-bottom: 4px;
					border-bottom: 1px solid #febb02;
					color: #47403a;
				}

				.arrow {
					&:after {
						border-top-color: #febb02 !important;
						border-bottom-color: #febb02 !important;
					}
				}
			}

			.total {
				td {
					font-size: 16px;
					color: var(--secondary);
				}
			}

			td {
				.btn {
					display: none !important;
				}
			}

			.price {
				color: var(--primary) !Important;
			}

			tfoot {
				tr {
					td {
						border-top: 1px solid #f1f5f6;
					}
				}
			}
		}

		.unit-details {
			margin-top: 10px;
			padding-left: 0;

			li {
				&:first-child {
					padding-left: 0;

					&:before {
						display: none;
					}
				}
			}

			li {
				margin-left: 0px;
				color: var(--black);
				position: relative;
				list-style: none;
				padding-left: 25px;
				font-size: 15px;
				margin-bottom: 4px;

				&:before {
					content: "\f00c";
					color: var(--secondary);
					position: absolute;
					left: 0;
					font-family: Font Awesome\ 5 Pro !important;
					top: 0;
				}
			}
		}
	}

	.voucher-bl {
		.panel-title {
			text-transform: uppercase;
			font-size: 14px;

		}

		.pl-0 {
			@media (min-width:768px) {
				padding-left: 0;
			}
		}

		.input-check-bl {
			@media (min-width:768px) {
				display: flex;
				justify-content: space-between;
			}

			.form-control {
				@media (min-width:768px) {
					margin-right: 10px;
				}

				margin-bottom: 10px;
				height: 45px;
			}
		}
	}

	.pd-form {
		label {
			font-weight: normal;
			text-align: left;
			font-weight: 700;
			text-transform: capitalize;
		}

		label.lrequired {
			font-weight: 700;
		}

	}

	.final-col {
		.checks {
			margin-bottom: 30px;

		}

		margin-top: 10px;
		margin-bottom: 20px;
	}

	.flow-btn {
		margin-left: 20px;
		margin-bottom: 20px;
		font-size: 16px !important;
		letter-spacing: 1px;
		text-transform: uppercase;
		border-radius: 0;
		padding: 10px 20px !important;

		&:hover {
			background: var(--HoverColor) !important;
		}

		@media (max-width:575px) {
			font-size: 14px !important;
		}
	}


	.pay-online-box {
		min-height: 240px;
		display: none;

		.amount-options-list {
			padding-left: 10px;
		}

		.amount-option-btn {
			list-style-type: none;
		}

		.amount-option {
			border: none;
			font-weight: bold;
			padding: 0;
			margin-bottom: 4px;
			margin-left: 10px;
		}

		.amount-or {
			margin-top: 2px;
			font-weight: normal;
			font-style: italic;
		}

		.bank-detail-box {
			padding-left: 10px;
		}
	}

	.booking-bl {
		img {
			width: 100%;
		}
	}

}

.regiotuete {
	margin-bottom: 30px;
	margin-top: 30px;

	.h2 {
		line-height: 34px !important;
	}
}