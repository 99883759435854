@import "setup.css";

.header-style2 {
	.mainHeader {

		border-bottom: 0px solid #f9f9f9;
		background-color: rgba(255, 255, 255, 1) !important;

		.navbar-area {

			.topNav {
				ul {
					li {
						a {
							text-decoration: inherit;
							text-shadow: none;
							color: var(--black);

							i {
								color: var(--white) !important;
							}
						}
					}
				}
			}


		}
	}
}

.navbar-area {

	>.srch-btn {
		color: var(--primary) !important;



		i.fal,
		i.far {
			@media (max-width:992px) {
				display: none;
			}
		}
	}

	.topNav {
		ul {
			li {
				a {


					i {
						color: var(--white) !important;
					}
				}
			}
		}

		.social-header {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			padding: 0 15px 30px 0;

			@media (max-width: 992px) {
				position: absolute;
				top: 0;
				right: 0;
				padding: 5px 15px;
				border-bottom: 1px solid rgb(0 0 0 / 20%);
				width: 100%;
				background: #f4f4f4;
			}

			a.icon,
			.dropdown-toggle {
				width: 30px;
				height: 30px;
				background: #1c2639;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 100%;
				color: #fff;
				font-size: 15px;
				margin: 0 0 3px 12px;
				border: none;

				@media (max-width: 767px) {
					width: 25px;
					height: 25px;
					font-size: 12px;
				}

				&:hover {
					opacity: 0.6;
				}

			}

			.dropdown-menu {
				background: #1c2639;
				width: 235px;
				left: -210px;
				border-radius: 0;
				padding: 15px 15px 0px 15px;

				p {
					font-size: 16px;
					color: #fff;
					margin: 0 0 15px 0;
					line-height: 24px;

					a {
						color: #fff;

						&:hover {
							opacity: 0.6;
						}
					}

					.links {
						text-decoration: underline;
						color: #337ab7;
					}
				}

				.btn-dropdown {
					background-color: #007da7;
					color: #FFF !important;
					border: 1px solid #026B8E;
					margin-top: 10px;
					margin-bottom: 10px;
				}

				.btn-dropdown:hover,
				.btn-dropdown:focus,
				.btn-dropdown:active,
				.btn-dropdown.active {
					color: #FFF;
					background-color: #0187B4;
					border: 1px solid #0187B4;

				}
			}
		}
	}

	.nav-item.favorites {
		display: flex;

		a {
			padding: 6px 0 0 20px;
		}

		.nav-right {
			a {
				padding: 0;
			}
		}
	}
}

.nav-right {
	.srch-btn {
		padding: 2px 8px;
	}
}

.vo-nav-bar {

	&.mainHeader {
		/* position: fixed; */
		position: relative;
		max-width: 1240px;
		width: 100%;
		top: 0px;
		z-index: 50;
		display: flex;
		align-items: center;
		padding: 25px 0 15px 0;
		transition: all ease-in .2s;
		background-color: #fff;

		@media (max-width:992px) {
			padding: 50px 0 5px 0 !important;
			border-bottom: 3px solid #f9f9f9;
			background-color: #fff !important;
			box-shadow: 0 3px 6px rgb(0 0 0 / 20%);
		}

		@media (max-width: 767px) {
			position: fixed !important;
			padding: 45px 0 5px 0 !important;
		}

		.navbar-area {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 0 25px;

			@media (max-width:1140px) {
				padding: 0;
			}

			@media (max-width:992px) {
				margin-left: 0;
				justify-content: center;
				padding: 0 0 0 20px;
			}

			.srch-btn {
				font-size: 20px;
				color: var(--white);

				@media (max-width:992px) {
					margin-right: 5px;
					color: var(--primary);
				}
			}
		}

		.logo {
			a {
				width: auto;
				background: #fff;
				display: block;
				padding: 0;
				border-radius: 7px;

				@media (max-width:1199px) {
					max-width: 220px;
				}

				@media (max-width:1024px) {
					max-width: 180px;
				}

			}

			img {
				min-width: 186px;
				width: 100%;

				@media (max-width: 992px) {
					min-width: unset;
					width: auto;
					max-width: 130px;
				}
			}


		}

		.nav-right {
			display: flex;
			justify-content: flex-end;

			.fav-count {
				color: var(--black);
				position: relative;
				margin-left: 5px;
				top: 4px;

				@media all (min-width: 993px) and (max-width: 1240px) {
					top: 6px;
				}


				.fa {
					font-size: 18px;
				}

				.sub-count {
					display: block;
					position: absolute;
					top: -6px;
					left: 7px;
					width: 18px;
					height: 18px;
					background: red;
					background: var(--primary);
					color: var(--white);
					text-align: center;
					font-size: 12px;
					line-height: 18px;
					border-radius: 50%;
				}
			}
		}

		.topNav {
			.navbar {
				min-height: inherit;
				margin: 0;

				@media (max-width:992px) {
					position: inherit;
				}
			}

			.navbar-toggler {
				background: transparent;
				font-size: 24px;
				color: var(--grey2);
				padding: 0;
				border: none;
				box-shadow: none;
				display: none;

				@media (max-width:992px) {
					display: inline-block;
					position: absolute;
					left: 20px;
					top: 73px;

				}
			}

			.col-menu-1 {
				display: flex;
				max-width: 100%;
				width: 100%;
				align-items: baseline;

				.two-col-drop {
					width: 50%;

					li {
						width: 100% !important;
						border-bottom: none;
					}

					li.title {
						padding: 10px 20px;
						color: #fff;
						text-transform: uppercase;
						background: #031f3e;
						border-bottom: 1px solid rgb(255 255 255 / 40%);
						margin: 0 0 0 0;

						a {
							padding: 0;
						}
					}
				}
			}

			ul {
				margin: 0;
				padding: 0px;

				&.two-col-drop {
					display: flex;
					align-items: center;
					flex-wrap: wrap;
					width: 440px;

					li {
						@media (min-width:993px) {
							width: 50% !important;
						}
					}
				}

				li {
					list-style: none;
					padding: 0;
					margin: 0 0 0 15px;
					letter-spacing: 1px;

					&.service-bl .dropdown-menu {
						left: inherit;
						right: 0;
						min-width: 200px;
					}

					@media (max-width:1240px) {
						margin: 0 2px;
					}

					a {
						font-size: 14px;
						color: var(--textprimary);
						display: inline-block;
						padding: 5px 0;
						background: transparent;
						text-align: left;
						text-transform: uppercase;
						font-weight: 500;
						border-radius: 3px;

						&:hover {
							i {
								color: #bba74b;
							}
						}

						i {
							margin-left: 5px;
							color: var(--secondary);
						}

						@media (max-width:1240px) {
							padding: 5px 10px;
							font-size: 14px;
						}

						@media (max-width:992px) {
							padding: 7px 0;

						}

						@media all (min-width: 993px) and (max-width: 1260px) {
							padding: 5px 3px;
							font-size: 12px;
						}

						span {
							padding: 3px 5px;
							border-radius: 3px;
							background-color: var(--secondary);
							color: var(--white);
							display: inline-block;
							line-height: 13px;
							font-size: 14px;
							margin-left: 3px;
						}
					}

					&:hover {
						a {
							opacity: 1;
							color: #bba74b;
							border-radius: 0;
						}

						.dropdown-menu {
							display: block;
						}
					}

					.dropdown-menu {
						min-width: 440px;
						left: 0px;
						margin: 0px;
						padding: 0;
						background-color: #023061;
						border: none;
						border-radius: 0 3px 3px 3px;
						margin-top: -1px;

						@media (max-width:992px) {
							ul {
								padding: 0;
							}
						}

						@media all and (min-width: 992px) and (max-width: 1250px) {
							min-width: 390px;
						}

						li {
							float: left;
							width: 100%;
							border-bottom: 1px solid rgba(255, 255, 255, .2);
							margin: 0px;
							padding: 0;

							&:last-child {
								border-bottom: none;
							}

							&:hover {
								background: #355981;
							}
						}

						a {
							font-size: 14px;
							display: block;
							padding: 10px 20px;
							color: #fff;
							text-decoration: none;

							&:hover {
								opacity: 0.7;
							}
						}
					}
				}

			}
		}
	}

	&.affix {
		padding: 14px 0 !important;
		border-bottom: 3px solid #f9f9f9;
		background-color: rgba(255, 255, 255, 1);



		@media (max-width: 992px) {
			padding: 50px 0 15px !important;
		}


		.navbar-area {

			.topNav {
				ul {
					li {
						a {
							text-decoration: inherit;
							text-shadow: none;

						}
					}
				}
			}


		}
	}

	@media (max-width:992px) and (min-width: 768px) {
		.navbar-collapse.collapse {
			display: none !important
		}

		.navbar-collapse.collapse.in,
		.navbar-collapse.collapsing {
			display: block !important
		}
	}

	@media screen and (max-width: 992px) {

		.topNav {

			.navbar-collapse {
				position: absolute;
				left: 0px;
				right: 0px;
				top: 58px;
				background: var(--primary);
				padding: 0px;
				overflow: scroll !important;
				max-height: 450px;


				ul {
					magin: 0px;
					float: left;
					width: 100%;
					padding: 10px 15px;

					li {
						float: left;
						width: 100%;

						margin: 0px;

						a {
							color: #ffffff;
						}

						&:hover {
							a {
								color: #ffffff;
							}

							.dropdown-menu {
								display: none;
							}
						}

						&.open {
							.dropdown-menu {
								display: block;
								position: static;
							}
						}

						.dropdown-menu {
							position: static;
							float: none;
							width: auto;
							margin-top: 0;
							background-color: transparent;
							border: 0;
							box-shadow: none;

							li {
								a {
									color: #fff;
									text-decoration: none;
								}
							}

						}
					}
				}
			}
		}
	}
}

.mm-page {
	&.mm-slideout {
		z-index: inherit;
	}
}

@media (max-width: 992px) {

	.mobile-navigation {
		ul.mm-listview {
			li {

				&.last {
					-webkit-box-pack: center;
					-ms-flex-pack: center;
					justify-content: center;

					a {
						border: 1px solid var(--orangeColor);
						border-radius: 100px;
						text-transform: uppercase;
						padding: 10px 30px;
						max-width: 125px;
						font-size: 13px;
						margin: 22px 0 0 0;

						&:hover {
							background: var(--orangeColor);
							border-color: var(--orangeColor);
						}
					}
				}
			}
		}

	}

	.mm-menu_offcanvas {
		width: 100vw;
		min-width: 100vw;
		z-index: 51;
		max-width: 100vw;
	}

	.mm-wrapper_opening .mm-menu_offcanvas.mm-menu_opened~.mm-slideout {
		-webkit-transform: translate3d(100vw, 0, 0);
		transform: translate3d(100vw, 0, 0);
	}

	.mm-wrapper_opening .mm-menu_offcanvas.mm-menu_opened~.mm-wrapper__blocker.mm-slideout {
		-webkit-transform: translate3d(87vw, 0, 0);
		transform: translate3d(87vw, 0, 0);
		height: 50px;
	}

	.mm-wrapper_opening .mm-menu_offcanvas.mm-menu_opened~.mm-slideout .mm-sronly {
		height: 50px !important;
		width: 50px !important;
		clip-path: inherit !important;
		-webkit-clip-path: inherit !important;
		clip: inherit !important;
		font-size: 0;
	}

	.navbar-nav,
	.navbar-nav>li {
		float: none !Important;
		padding: 0 15px;
	}



	.mm-wrapper_opening .mm-menu_offcanvas.mm-menu_opened~.mm-slideout .mm-sronly:before {
		content: "\f410";
		font-family: 'Font Awesome 5 Free';
		font-style: normal;
		font-weight: 400;
		font-size: 30px;
		color: #fff;
		padding: 7px;
		float: left;
	}

	.mm-menu_offcanvas {
		display: none;
	}

	.mm-wrapper_opening .mm-menu_offcanvas {
		display: block;
	}

	.mm-wrapper_opening .mm-page.mm-slideout {
		display: none;

	}

	.mm-wrapper_opened .main-footer {
		display: none;
	}

	.mm-navbar {
		background: none;
		border-bottom: 1px solid rgba(255, 255, 255, .5);
		padding-top: 50px;
		margin-bottom: 20px;
		padding-bottom: 10px;
	}

	.mm-navbar__title>span {
		color: var(--white);
		font-size: 20px;
		font-weight: bold;
	}

	.mm-panel {
		background-image: var(--mobileMenuImg);
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;
		margin: 0;
		position: relative;

		&:before {
			width: 100%;
			height: 100%;
			background: rgb(28 72 103/70%);
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			display: block;
			content: " ";
			position: absolute;
		}

		&:after {
			content: none;
		}

		&.dropdown-menu {
			box-shadow: none !important;
			padding: 0 !important;
			margin: 0 !important;
			border: none !important;
			border-radius: 0 !important;
		}
	}

	.mm-listitem {
		text-align: center;
		font-size: 20px;
		color: var(--white);

		a {
			span {
				padding: 0 8px 2px;
				line-height: 1;
				font-size: 14px;
				border-radius: 3px;
				background-color: var(--primary);
				position: relative;
				top: -2px;
			}
		}


	}

	.mm-listitem:after {
		display: none;
	}

	.mm-listitem__btn {
		border: none !important;
		position: absolute;
		right: 0;
		top: 12px;
		color: var(--white);
	}

	.mm-btn_next {
		position: absolute;
		left: 0;
		width: 100%;
		height: 30px;
		top: 6px;

		&:after {
			right: 30px;
		}
	}


	.mm-btn:after,
	.mm-btn:before {
		border-color: var(--white);
	}

	.mm-listview {
		max-height: 79vh;
		padding-bottom: 35px;
		overflow-y: scroll;
	}


	.mm-wrapper_opening .mm-menu_offcanvas.mm-menu_opened~.mm-wrapper__blocker.mm-slideout {
		-webkit-transform: translate3d(87vw, 0, 0);
		transform: translate3d(87vw, 0, 0);
		height: 50px;
		z-index: 51;
	}

	.mm-wrapper_opening .mm-menu_offcanvas.mm-menu_opened~.mm-slideout .mm-sronly {
		height: 50px !important;
		width: 50px !important;
		clip-path: inherit !important;
		-webkit-clip-path: inherit !important;
		clip: inherit !important;
		font-size: 0;
	}

	.mm-wrapper_opening .mm-menu_offcanvas.mm-menu_opened~.mm-slideout .mm-sronly:before {
		content: "\f410";
		font-family: 'Font Awesome 5 Free';
		font-style: normal;
		font-weight: 400;
		font-size: 30px;
		color: #fff;
		padding: 7px;
		float: left;
	}

	.col-menu-1 {
		max-height: 78vh;
		padding-bottom: 35px;
		overflow-y: scroll;
		position: relative;
		padding: 0 25px;

		.two-col-drop {
			width: 100%;
			list-style: none;
			padding: 0;

			li {
				text-align: center;
				font-size: 20px;
				color: #fff;
				color: var(--white);

				a {
					text-overflow: ellipsis;
					white-space: nowrap;
					overflow: hidden;
					padding: 10px 20px;
					flex-grow: 1;
					flex-basis: 10%;
					display: block;
				}
			}

			li.title {
				max-width: 100%;
				padding: 10px 20px;
				background: rgb(255 255 255/50%);
				margin: 0 auto;
				list-style: none;

				a {
					padding: 0;
				}
			}
		}
	}
}