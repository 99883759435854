.indexpage {
	.stoerer {
		position: absolute;
		right: 20px;
		top: 20px;
		z-index: 2;
		height: 100px;
		background-color: var(--primary);
		border-radius: 50%;
		color: #fff;
		width: 185px;
		height: 185px;
		-webkit-transform: rotate(30deg);
		transform: rotate(30deg);
		font-size: 16px;
		text-align: center;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		a {
			color: var(--color-white);
		}

		.btn {
			background-color: var(--primary);
			border-radius: 50%;
			color: #FFF;
			width: 135px;
			height: 135px;
			transform: rotate(-20deg);
			font-size: 12px;

			@media (max-width:767px) {

				width: 105px;
				height: 105px;
				font-size: 10px;
			}
		}
	}

	.prora-banner {


		position: absolute;
		top: 340px;
		left: 0;
		right: 0;
		margin: auto;
		background-color: #fff;
		z-index: 5;
		max-width: 1200px;
		box-shadow: 0 0.1rem 1rem rgba(0, 0, 0, .1);
		padding: 30px;

		@media(min-width: 992px) {
			width: calc(100% - 80px);
		}

		@media(max-width: 991px) {
			width: 520px;
		}

		@media(max-width: 560px) {
			width: 460px;
		}

		@media(max-width: 490px) {
			width: 320px;
		}

		.min-cont-bl {
			min-height: 260px;
			display: flex;
			align-items: center;

			@media (max-width:992px) {
				min-height: inherit;
			}
		}


		.close-banner {
			position: absolute;
			right: 20px;
			top: 10px;
			border: 1px solid var(--primary);
			width: 29px;
			height: 29px;
			text-align: center;

			a {
				text-decoration: none;
				font-size: 20px;
				color: var(--primary);
			}
		}

		.content {
			margin-top: 10px;

			@media(min-width: 992px) {
				display: flex;
			}

			.picture {
				display: flex;
				position: relative;

				img {
					object-fit: cover;
					max-height: 332px;
					width: 100%;
				}




			}

			.text-container {
				@media(min-width: 992px) {
					padding: 0 20px;
				}

				@media(max-width: 991px) {
					padding: 20px 0;
				}

				.title {
					font-size: 24px;
					color: var(--primary);

					@media(max-width: 560px) {
						font-size: 20px;
					}
				}

				.subtitle {
					font-size: 26px;
					color: var(--primary);

					@media(max-width: 560px) {
						font-size: 16px;
					}
				}

				.text {
					font-size: 18px;
					padding-top: 0px;
					margin-top: 30px;

					@media(max-width: 560px) {
						font-size: 16px;
					}

					ul {
						padding: 20px;
						margin: 0px;
					}

				}
			}
		}
	}


	#unitofthedaysection {
		.unit-list-section {
			padding-bottom: 70px;
			position: relative;

			@media (max-width: 767px) {
				padding-bottom: 30px;
			}



			.popover {
				@media (max-width: 767px) {
					max-width: 185px;
					min-width: unset;
					margin-left: -80px !important;
				}

				@media all and (min-width: 768px) and (max-width: 1199px) {
					margin-left: -80px !important;
				}

				.arrow {
					@media (max-width: 767px) {
						left: unset !important;
						right: 0;
					}

					@media all and (min-width: 768px) and (max-width: 1199px) {
						left: unset !important;
						right: 33px;
					}
				}
			}
		}



		.owl-carousel {
			.owl-stage-outer {
				overflow: hidden;
				position: relative;
			}

		}

		.next-prev-btns {
			.owl-nav {
				button {
					span:before {
						color: #153154 !important;
						font-family: 'Font Awesome 5 Pro';
						font-weight: 400;
					}
				}
			}
		}
	}

	.index-title {
		padding: 20px 25px;
		background: rgb(28 72 103 / 15%);

		.inner-pag-title {
			color: var(--textprimary);
			font-size: 24px;
			text-transform: uppercase;
			font-weight: 600;
			display: block;
			padding: 0;
			font-family: var(--d-bold);
			width: auto;
			max-width: 100%;
			margin: 0 auto;
			text-align: center;

			@media (max-width: 767px) {
				font-size: 20px;
			}

			&:after {
				display: none;
			}
		}
	}

	.home-first-text-section {
		background: rgb(28 72 103/15%);
		margin-top: 2px;
		padding-top: 30px;
		border: 3px solid red;

		p {
			font-weight: 600;

			a {
				color: var(--textprimary);

				&:hover {
					color: var(--HoverColor);
				}
			}
		}
	}

	.parallax-section {
		background-image: url(RESOURCE/img/bg-home-parallax.jpg);
		position: relative;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		background-attachment: fixed;

		&:before {
			background-color: var(--white);
			height: 130px;
			width: 100%;
			display: block;
			position: absolute;
			left: 0;
			top: 0;
			content: "";

			@media (max-width:992px) {
				height: 80px;
			}
		}

		&:after {
			background-color: var(--white);
			height: 130px;
			width: 100%;
			display: block;
			position: absolute;
			left: 0;
			bottom: 0;
			content: "";

			@media (max-width:992px) {
				height: 80px;
			}
		}


		.parallax-content {
			padding: 80px;
			position: relative;
			z-index: 1;
			background-color: var(--blue4);
			color: var(--white);
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;
			max-width: 800px;


			.section-title {
				margin-bottom: 40px;

				&:after {
					opacity: .2;
				}

				@media (max-width:992px) {
					margin-bottom: 20px;
				}
			}

			@media (max-width:992px) {
				min-height: 600px;
				padding: 20px;
			}

			p {
				font-size: 24px;
				color: #6e8dad;
				margin-bottom: 40px;

				@media (max-width:992px) {
					font-size: 14px;
					margin-bottom: 20px;
				}
			}

			a {
				color: var(--white);

				&:hover {
					color: var(--primary);
				}
			}
		}
	}

	.bg-li-blue {
		@media (max-width:992px) {
			background-color: var(--white);
		}

		.section-title.align-left {
			margin-bottom: 0;

			&:after {
				@media (min-width:993px) {
					opacity: .2;
				}
			}
		}

		.testimonial-slide {
			padding: 100px 0;
			background-image: url(https://r.v-office.com/preview/v115/1601984139000/img/bg-testimonial-circle.png);
			background-repeat: no-repeat;
			background-position: center;
			background-size: 430px;

			@media (max-width:992px) {
				padding: 30px 0;
			}

			@media (max-width:450px) {
				background-position: center center;
				background-size: 300px;
			}

			.cl-img {
				margin: 0 40px 40px;
				position: relative;

				@media (max-width:992px) {
					margin: 0 20px 30px;
				}

				img {
					position: relative;
					z-index: 1;

					@media (max-width:992px) {
						left: -8px;
						top: -8px;
					}
				}

				&:after {
					border: 3px solid #aecad7;
					position: absolute;
					left: 30px;
					height: 100%;
					top: 30px;
					width: 100%;
					content: "";

					@media (max-width:992px) {
						left: 15px;
						top: 15px;
					}
				}
			}

			.owl-nav {
				display: none;
			}

			.cl-dt {
				text-align: center;
				padding-top: 4px;
				display: block;

				h3 {
					color: var(--textprimary);
				}

				h5 {
					a {
						text-transform: uppercase;
						color: rgba(0, 0, 0, .4);
					}
				}
			}

			.cl-rat {
				color: var(--textprimary);
				font-size: 18px;

				@media (max-width:992px) {
					text-align: center;
				}
			}

			.cl-cont-bl {
				padding: 20px;


				@media (min-width:993px) {
					padding: 0 0 0 30px;
				}

				@media (max-width:992px) {
					margin-top: 0;
				}

				.testi-title {
					color: var(--d-blue);
					margin-bottom: 50px;

					@media (max-width:992px) {
						text-align: center;
					}
				}

				p {
					font-size: 30px;
					margin-bottom: 30px;
					line-height: 1.4;
					color: var(--grey2);

					@media (max-width:992px) {
						font-size: 21px;
					}
				}
			}

			.owl-dots {
				bottom: 0px;
				width: 50%;
				left: 50%;
				transform: translate(-50%, 0);
				font-size: 27px;
				text-align: center;
				position: absolute;

				.owl-dot {
					width: 50px;
					height: 0;
					display: inline-block;
					border-bottom: 5px solid var(--textprimary);
					margin: 0 8px;
					opacity: .2;

					&.active {
						border-bottom-color: var(--primary);
						opacity: 1;
					}

					@media (max-width:992px) {
						width: 40px;
					}
				}
			}
		}
	}

	.blog-block {
		border: 1px solid #dddddd;
		box-shadow: 0 0 10px rgba(0, 0, 0, .1);
		border-radius: 10px;
		overflow: hidden;

		@media (min-width:993px) {
			margin-bottom: 40px;
		}

		.bl-img {
			height: 250px;
			overflow: hidden;
			display: block;

			img {
				object-fit: cover;
				width: 100%;
				height: 100%;
			}
		}

		.date {
			color: var(--grey);
		}

		.lt-bl-cont {
			padding: 15px 20px;

			.bl-title {
				margin-top: 0;
				color: var(--black);
				font-size: 18px;
				text-align: left;

				a {
					color: inherit;
				}
			}
		}

	}


	.flex-blog-col {
		@media (min-width:993px) {
			display: flex;
			align-items: center;
			flex-direction: row-reverse;
			flex-wrap: wrap;
		}

		@media (max-width:992px) {
			text-align: center;
		}


		.blog-col {
			@media (max-width:992px) {
				margin-top: 40px !important;
			}

			&:nth-child(1),
			&:nth-child(3) {
				position: relative;

				@media (min-width:993px) {
					top: -25px;
				}
			}

			&:nth-child(2),
			&:nth-child(4) {
				position: relative;

				@media (min-width:993px) {
					top: 25px;
				}
			}

			@media (max-width:992px) {
				max-width: 440px;
				margin: 0 auto;
				padding: 0 15px;
			}

		}
	}

	.category-bl {
		margin: 0;

		.col-lg-6 {
			padding: 0;
			position: relative;


			.row {
				margin: 0;

				.col-sm-6 {
					padding: 0;
					position: relative;
				}
			}
		}

		.big-cat-bl {
			border: 1px solid #e8e8e8;
			width: 100%;
			overflow: hidden;

			@media (min-width:993px) {
				height: 791px;

				&:after {
					content: "";
					height: 100%;
					width: 100%;
					background: rgb(8, 155, 224);
					background: linear-gradient(342deg, rgba(8, 155, 224, 0.82) 0%, rgba(0, 0, 0, 0.82) 53%);
					position: absolute;
					z-index: 1;
					top: 0;
					left: 0;
					visibility: hidden;
					opacity: 0;
					padding: 30px;
					transition: all 0.25s ease 0s;
				}
			}

			.cat-main-title {
				visibility: visible;
				opacity: 1;
				transition: all 0.25s ease 0s;
			}


			@media (min-width:993px) {
				&:hover {
					&:after {
						visibility: visible;
						opacity: 1;
					}

					.cat-cont-bl {
						visibility: visible;
						opacity: 1;
					}

					.cat-main-title {
						visibility: hidden;
						opacity: 0;
					}
				}


			}


			>img {

				@media (min-width:993px) {
					object-fit: cover;
					height: 100%;
					width: 100%;
				}
			}

			.cat-titile {
				@media (min-width:993px) {
					color: var(--white);
					font-size: 30px;
				}

				font-size: 20px;
				margin: 0 0 20px;


			}

			p {
				font-size: 24px;

				@media (max-width:992px) {
					font-size: 18px;
					margin-bottom: 30px;
				}
			}
		}



		.half-cat-bl {
			border: 1px solid #e8e8e8;
			width: 100%;
			overflow: hidden;

			@media (min-width:993px) {
				height: 396px;

				&:after {
					content: "";
					height: 100%;
					width: 100%;
					background: rgb(8, 155, 224);
					background: linear-gradient(342deg, rgba(8, 155, 224, 0.82) 0%, rgba(0, 0, 0, 0.82) 53%);
					position: absolute;
					z-index: 1;
					top: 0;
					left: 0;
					visibility: hidden;
					opacity: 0;
					padding: 30px;
					transition: all 0.25s ease 0s;
				}
			}

			.cat-main-title {
				visibility: visible;
				opacity: 1;
				transition: all 0.25s ease 0s;
			}


			.cat-titile {
				@media (min-width:993px) {
					color: var(--white);
					font-size: 30px;
				}

				margin: 0 0 20px;
			}


			@media (min-width:993px) {
				&:hover {
					&:after {
						visibility: visible;
						opacity: 1;
					}

					.cat-cont-bl {
						visibility: visible;
						opacity: 1;
					}

					.cat-main-title {
						visibility: hidden;
						opacity: 0;
					}
				}
			}

			>img {
				@media (min-width:993px) {
					object-fit: cover;
					height: 100%;
					width: 100%;
				}
			}

			h4 {
				@media (min-width:993px) {
					bottom: 18px;
					right: 24px;
					position: absolute;
					text-align: right;
					text-shadow: 0 0px 7px rgba(0, 0, 0, .7);
					font-size: 28px;
				}

				@media (max-width:992px) {
					font-size: 24px;
				}
			}
		}

		p {
			font-size: 20px;
			margin-bottom: 30px;
			opacity: .5;
			margin-bottom: 40px;
			max-width: 550px;

			@media (min-width:993px) {
				color: var(--white);
			}

			@media (max-width:992px) {
				font-size: 18px;
				margin-bottom: 30px;
			}
		}

		.cat-cont-bl {
			@media (min-width:993px) {
				visibility: hidden;
				opacity: 0;
				transition: all 0.25s ease 0s;
			}

			@media (max-width:992px) {
				padding: 20px;
			}
		}


		.big-cat-gr {
			position: relative;

			@media (min-width:993px) {
				position: absolute;
				z-index: 1;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: 2;
				padding: 40px;
			}

			.link {
				font-size: 18px;
				text-transform: capitalize;
			}

			.cat-main-title {
				text-shadow: 0 0px 7px rgba(0, 0, 0, .7);

				@media (min-width:993px) {
					font-size: 28px;
					position: absolute;
					bottom: 40px;
					right: 40px;

				}

				a {
					color: var(--white);

					&:hover {
						color: var(--primary);
					}
				}


				@media (max-width:992px) {
					font-size: 24px;
					position: absolute;
					z-index: 5;
					top: -60px;
					right: 17px;
					display: none;
				}
			}
		}
	}

	.under-banner-section {
		margin-bottom: 30px;

		@media (max-width: 767px) {
			padding: 30px 0 0px 0;
			margin-bottom: 60px;
		}

		.owl-nav {
			top: 45%;

			@media (max-width: 767px) {
				top: 40%;
			}
		}

		.pad75 {
			@media (max-width: 767px) {
				padding: 30px 0 0;
			}
		}

		.box {
			@media (max-width: 767px) {}

			h2 {
				margin: 0 0 15px;
				font-size: 24px;
				font-weight: 600;
				color: var(--textprimary);

				@media all and (min-width: 768px) and (max-width: 992px) {
					font-size: 20px;
				}
			}

			.single-unit {
				margin-bottom: 0 !important;
				max-width: 100%;
			}

			.next-prev-btns {
				.owl-nav {
					@media all and (min-width: 768px) and (max-width: 992px) {}
				}
			}
		}

		.next-prev-btns {
			.owl-nav {
				button {
					background: transparent !important;
					box-shadow: none;

					span:before {
						text-shadow: 0 0 8px rgb(0 0 0 / 70%);
						font-size: 30px;
					}
				}
			}
		}

		.owl-carousel {
			display: block;

			.owl-item {
				img {
					height: 320px;
					object-fit: cover;

					@media (max-width: 767px) {
						height: auto;
					}

					@media all (min-width: 768px) and (max-width: 991px) {
						height: 210px;
					}
				}

				.badge {
					background: #d0dee9;
					color: var(--textprimary);
					width: 100%;
					min-height: 50px;
					display: flex;
					flex-wrap: wrap;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					border-radius: 0;
					position: static;


					h4 {
						font-family: var(--d-bold);
						margin: 15px 0 0;
						font-size: 16px;
						line-height: 22px;


						@media (max-width: 574px) {
							font-size: 15px;
							line-height: 20px;
						}

						@media all (min-width: 768px) and (max-width: 991px) {
							font-size: 15px;
							line-height: 20px;
						}
					}

					h4.mt-0 {
						margin-top: 0px;
					}

					h4.mb-0 {
						margn-bottom: 0px;
					}


					p {
						color: var(--textprimary);
						margin: 0;
						font-weight: 600;
						font-size: 16px;
						line-height: 20px;

						@media (max-width: 574px) {
							font-size: 13px;
							line-height: 18px;
						}

						@media all (min-width: 768px) and (max-width: 991px) {
							font-size: 13px;
							line-height: 18px;
						}
					}

					.big {
						font-size: 30px;
						font-weight: 700;
						font-family: var(--d-font);
						line-height: 30px;

						@media (max-width: 574px) {
							font-size: 40px;
						}

						@media all (min-width: 768px) and (max-width: 991px) {
							font-size: 40px;
						}
					}

					h6 {
						color: var(--textprimary);
						margin: 5px 0 0 0;
						font-weight: 600;
						font-size: 14px;
						line-height: 20px;

						@media (max-width: 574px) {
							font-size: 13px;
							line-height: 18px;
						}

						@media all (min-width: 768px) and (max-width: 991px) {
							font-size: 13px;
							line-height: 18px;
						}
					}
				}
			}
		}

		.neu-bei-uns {
			.owl-carousel {
				.badge {
					width: 100%;
					height: auto;
					padding: 0;
					left: 0;
					border-radius: 0;
					min-height: 50px;
					position: static;
				}
			}
		}
	}

	.map-section {
		/*padding-top: 50px;*/
		background: #eff4f7;

		h2 {
			margin: 0 0 30px 0;
			padding: 0 0;
			font-size: 26px;
			font-weight: 600;
			line-height: 34px;

			@media (max-width: 767px) {
				font-size: 22px;
				line-height: 30px;
			}
		}

		.map-img {
			display: block;

			@media (max-width: 1249px) {
				display: none;
			}
		}

		.map-img-mob {
			display: none;

			@media (max-width: 1249px) {
				display: block;
			}
		}

		.map-pin-block {
			position: relative;
			display: flex;
			flex-wrap: wrap;
			background: #f4f6f9;

			@media (max-width: 767px) {
				justify-content: center;
			}

			.map-pin-block-inner {
				@media (max-width: 1249px) {
					max-width: 600px;
					width: 100%;
					margin: 0 auto;
					display: flex;
					flex-wrap: wrap;
					align-items: unset;
				}

				@media (max-width: 767px) {
					max-width: 480px;
					align-items: unset;
				}

				@media (max-width: 575px) {

					max-width: 320px;

					align-items: unset;
					padding: 0 10px;
					margin-top: 10px;

				}
			}

			.Nordsee,
			.Ostsee {
				@media (max-width: 1249px) {
					width: 300px;
				}

				@media (max-width: 767px) {
					width: 240px;
				}

				@media (max-width: 575px) {
					width: 150px;
				}
			}


			.map-pin {
				position: absolute;
				color: var(--secondary);
				font-size: 40px;
				line-height: 40px;

				span {
					-webkit-animation-duration: 1.8s;
					animation-duration: 1.8s;
					-webkit-animation-fill-mode: both;
					animation-fill-mode: both;
					-webkit-animation-timing-function: ease-in-out;
					animation-timing-function: ease-in-out;
					animation-iteration-count: 1;
					-webkit-animation-iteration-count: 1;

					@media (max-width: 1249px) {
						animation: none !important;
					}
				}

				&:hover {
					span {
						cursor: pointer;
						animation-name: bounce;
						-moz-animation-name: bounce;
					}
				}

				@keyframes bounce {

					10%,
					90%,
					20%,
					50%,
					80% {
						-webkit-transform: translateY(0);
						-ms-transform: translateY(0);
						transform: translateY(0);
					}

					40% {
						-webkit-transform: translateY(-20px);
						-ms-transform: translateY(-20px);
						transform: translateY(-20px);
					}

					60% {
						-webkit-transform: translateY(-10px);
						-ms-transform: translateY(-10px);
						transform: translateY(-10px);
					}
				}

				a {

					@media (max-width: 1249px) {
						display: flex;
						align-items: center;
					}

					&:hover {
						label {
							font-weight: 700;
						}
					}
				}

				&.Nordsee-Title {
					label {
						text-transform: uppercase !important;
						font-size: 16px !important;
						display: block;
						margin: 5px 0 10px 0 !important;

					}

					@media (min-width: 1250px) {
						display: none !important;
					}
				}

				&.Ostsee-Title {
					label {
						text-transform: uppercase !important;
						font-size: 16px !important;
						display: block;
						margin: 5px 0 10px 0 !important;
					}

					@media (min-width: 1250px) {
						display: none !important;
					}
				}

				label {
					color: var(--secondary);
					font-size: 18px;
					font-weight: 400;
					margin: 0 0 0 10px;
					cursor: pointer;

					@media (max-width: 1249px) {
						position: static !important;
						margin: 0 !important;
						font-weight: 700;
					}

					@media (max-width: 1199px) {
						font-size: 16px !important;
						line-height: 22px !important;
					}

					@media (max-width: 767px) {
						font-size: 14px !important;
						line-height: 20px !important;
						text-transform: unset !important;
					}

					a {
						color: var(--secondary);
					}

				}

				@media (max-width: 1249px) {
					position: relative;
					padding: 10px 15px 0;
					width: 33.33% !important;
					float: left;
					bottom: unset !important;
					left: unset !important;
				}

				@media (max-width: 767px) {
					width: 100% !important;
					position: relative;
					padding: 0 0 10px 0;
					float: left;
					bottom: unset !important;
					left: unset !important;
					background: #f4f6f9;
					top: unset !important;
					right: unset !important;
					display: block !important;
				}

				@media all and (min-width: 768px) and (max-width: 1249px) {
					position: relative;
					padding: 10px 10px 10px 10px;
					width: 100% !important;
					float: left;
					bottom: unset !important;
					left: unset !important;
					background: #f4f6f9;
					top: unset !important;
					right: unset !important;
					display: block !important;
				}



				a {
					color: var(--secondary);


					&:hover {
						color: var(--HoverColor);
					}
				}

				&.niederlande {
					bottom: 175px;
					left: 225px;

					label {
						position: absolute;
						left: -100px;
						top: -22px;
					}
				}

				&.borkum {
					bottom: 148px;
					left: 113px;

					label {
						position: absolute;
						left: -85px;
						top: -5px;
					}
				}

				&.bensersiel {
					bottom: 140px;
					left: 243px;

					label {
						position: absolute;
						left: -68px;
						top: 28px;
					}
				}

				&.wangerooge {
					bottom: 182px;
					left: 272px;

					label {
						position: absolute;
						left: -40px;
						top: -54px;
					}
				}

				&.schillig {
					bottom: 118px;
					left: 282px;

					label {
						position: absolute;
						right: 39px;
						top: 30px;
					}
				}

				&.wremen {
					bottom: 164px;
					left: 360px;

					label {
						position: absolute;
						top: 20px;
						left: 7px;
					}
				}

				&.grobenbrode {
					top: 174px;
					left: 682px;

					label {
						position: absolute;
						top: -6px;
						right: 120px;
					}
				}

				&.travemunde {
					top: 256px;
					left: 658px;

					&:hover {
						z-index: 3;
					}

					label {
						position: absolute;
						top: -5px;
						right: 115px;
					}
				}

				&.boltenhagen {
					top: 253px;
					left: 694px;

					&:hover {
						z-index: 3;
					}

					label {
						position: absolute;
						top: 30;
						right: 75px;
					}
				}

				&.wismar {
					top: 275px;
					left: 737px;


					label {
						position: absolute;
						top: 10px;
						right: -28px;

					}
				}

				&.kuehlungsborn {
					top: 220px;
					left: 763px;

					label {
						position: absolute;
						top: 29px;
						right: -13px;
					}
				}

				&.borgerende {
					top: 219px;
					right: 407px;

					label {
						position: absolute;
						top: 10px;
						right: -25px;
					}
				}



				&.rugen {
					top: 95px;
					right: 273px;

					label {
						text-transform: uppercase;
						font-weight: 700;
						cursor: unset;
					}

					.map-icon-3 {
						display: none;

						@media (max-width: 1249px) {
							display: block;
						}

					}


				}

				&.usedom {
					top: 253px;
					right: 202px;

					label {
						text-transform: uppercase;
						font-weight: 700;
						cursor: unset;
					}

					.map-icon-3 {
						display: none;

						@media (max-width: 1249px) {
							display: block;
						}

					}
				}

				&.glowe {
					top: 103px;
					right: 220px;

					&:hover {
						z-index: 3;
					}

					label {
						position: absolute;
						right: -40px;
						top: -8px;
					}
				}

				&.binz {
					top: 157px;
					right: 194px;

					&:hover {
						z-index: 3;
					}

					label {
						position: absolute;
						top: -10px;
						right: -40px;
					}
				}

				&.bansin {
					top: 217px;
					right: 164px;

					&:hover {
						z-index: 3;
					}

					label {
						position: absolute;
						top: -30px;
						right: -50px;
					}
				}

				&.heringsdorf {
					top: 230px;
					right: 142px;

					&:hover {
						z-index: 3;
					}

					label {
						position: absolute;
						top: -20px;
						right: -40px;
					}
				}

				&.ahlbeck {
					top: 247px;
					right: 132px;

					label {
						position: absolute;
						top: -14px;
						right: -40px;
					}
				}

				&.prora {
					display: flex;
					right: 213px;
					top: 120px;

					label {
						position: absolute;
						top: 0;
						right: -40px;
					}
				}

				&.trassenheide {

					display: flex;
					right: 72px;
					top: 180px;

					>a {
						margin-top: 24px;
						position: relative;
						left: 19px;

						@media (max-width: 1250px) {

							left: 0;
						}


					}

					&:hover {
						z-index: 3;
					}

					label {
						color: var(--secondary);
						font-size: 18px;
						font-weight: 400;
						margin: -14px 0 0 10px;

						@media (max-width: 1249px) {
							display: none;
						}
					}
				}




				.fas {
					position: relative;
					z-index: 1;
				}

				span {
					font-size: 0;
					padding: 0 0 0 40px;
					font-weight: 600;

					@media (max-width: 991px) {
						background-size: 22px !important;
						height: 30px !important;
						margin: 0 !important;
						padding: 0 0 0 30px !important;
					}

					@media (max-width: 1249px) {
						font-size: 16px;
						line-height: 28px;
						background-size: 22px !important;
						height: 30px !important;
						width: auto !important;
					}
				}

				.map-icon-1 {
					background: url(RESOURCE/img/map-icon-new-1.svg);
					width: 32px;
					height: 42px;
					display: block;
					background-repeat: no-repeat;
					padding: 0 0 0 30px !important;
				}

				.map-icon-2 {
					background: url(RESOURCE/img/location2.svg);
					width: 32px;
					height: 42px;
					display: block;
					background-repeat: no-repeat;
					padding: 0 0 0 30px !important;

					@media all and (min-width: 768px) and (max-width: 991px) {}
				}

				.map-icon-3 {
					background: url(RESOURCE/img/map-marker-solid-1.svg);
					width: 32px;
					height: 36px;
					display: block;
					background-repeat: no-repeat;
					padding: 0 0 0 30px !important;

					@media (max-width: 1249px) {
						background-size: 20px !important;
					}

				}

			}
		}


	}

	.apartment-section {
		padding: 70px 0 50px 0;

		h2 {
			margin: 0 0 30px;
			font-size: 26px;
			font-weight: 600;

			@media (max-width: 767px) {
				font-size: 22px;
				line-height: 30px;
			}
		}
	}

	.three-box {
		margin: 0 0 50px 0;

		.boxes {
			background: #eaf0f3;
			padding: 50px 0 20px 0;
			display: flex;
			flex-wrap: wrap;
		}

		.box {
			background: #bbdce7;
			text-align: center;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-wrap: wrap;
			width: 260px;
			height: 260px;
			flex-direction: column;
			border-radius: 100%;
			padding: 10px 48px;
			margin: 0 auto 30px;

			h4 {
				margin: 0 0 20px 0;
			}

			h5 {
				margin: 0 0 0 0;
				font-size: 17px;
				line-height: 24px;
				font-weight: 600;
			}
		}
	}

	.urlaub-section {
		margin-top: 20px;

		h2 {
			margin: 0 0 30px;
			font-size: 26px;
			font-weight: 600;
			line-height: 32px;

			@media (max-width: 767px) {
				font-size: 22px;
				line-height: 30px;
			}
		}

		h6 {
			margin: 0 0 50px 0;
			padding: 20px 0 0 0;
		}
	}

	.schenken-section {
		margin: 0 0 50px 0;

		.hide-mob {
			@media (min-width:768px) {
				display: block !important;
			}

			@media (max-width:767px) {
				display: none !important;
			}


		}

		.show-mob {
			@media (max-width:767px) {
				display: block !important;
			}

			@media (min-width:768px) {
				display: none !important;
			}
		}

		.boxes {
			background: var(--primary);
			padding: 0px 0;
			display: flex;
			flex-wrap: wrap;

			.left {
				padding: 20px;
			}

			.right {
				background: #fff;
				padding: 0;
			}

			a.img-link {
				display: block;
				width: 100%;

				img {
					width: 100%;
				}
			}

			.box {
				/* background: #bbdce7;
				text-align: center;
				display: flex;
				-webkit-box-align: center;
				align-items: center;
				-webkit-box-pack: center;
				justify-content: center;
				flex-wrap: wrap;
				width: 270px;
				height: 270px;
				-webkit-box-orient: vertical;
				-webkit-box-direction: normal;
				flex-direction: column;
				border-radius: 100%;
				padding: 10px 48px;
				margin: 0 auto; */

				background: transparent;
				border-radius: 0;
				padding: 0;
				width: auto;
				height: auto;

				@media (max-width: 767px) {
					height: auto;
				}

				img {
					display: block;
					width: 100%;
					max-width: 400px;

					@media (max-width: 767px) {
						width: 100%;
					}
				}


				h3 {
					color: var(--textprimary);
				}

				@media all and (min-width: 768px) and (max-width: 991px) {
					width: 230px;
					height: 230px;
				}

				h4 {
					margin: 0 0 20px 0;

					img {
						width: 120px;
					}
				}

				h5 {
					margin: 0;
					font-size: 20px;
					font-weight: 600;
					line-height: 26px;
					color: var(--textprimary);
				}
			}
		}

		.box-content {
			@media (max-width: 767px) {
				max-width: 100%;
				width: 100%;
				margin: 0 auto;
			}
		}

		h2 {
			margin: 30px 0 15px;
			font-size: 60px;
			font-weight: 500;
			margin-top: 10px;
			line-height: 60px;
			color: var(--textprimary);

			span {
				font-style: italic;
				display: block;
				width: 100%;
			}

			@media (max-width: 992px) {
				font-size: 52px;
				line-height: 52px;
			}

			@media (max-width: 767px) {
				font-size: 42px;
				line-height: 42px;
				margin-top: 30px;
			}
		}

		p {
			font-size: 26px;
			line-height: 30px;
			margin: 0 0 30px;
			color: var(--textprimary);

			@media (max-width: 767px) {
				font-size: 20px;
			}
		}

		h6 {
			margin: 0;
			display: flex;
			align-items: center;
			padding: 0 20px 0 0;
			justify-content: flex-start;

			a {
				background: var(--textprimary);
				padding: 10px 20px;
				color: #fff;
				font-size: 16px;
				letter-spacing: 1px;
				border: 1px solid var(--textprimary);

				&:hover {
					background: var(--HoverColor);
					opacity: 1;
				}


				i {
					margin: 0 0 0 10px;
				}
			}
		}
	}

	.gallery-section {
		margin-bottom: 30px;

		.col-md-3 {
			width: 30%;

			@media (max-width: 767px) {
				width: 100%;
			}
		}

		.col-md-6 {
			width: 40%;

			@media (max-width: 767px) {
				width: 100%;
			}
		}

		.box {
			margin-bottom: 30px;
			position: relative;

			img {
				height: 280px;
				object-fit: cover;

				@media (max-width: 767px) {
					height: auto;
				}

				@media all (min-width: 768px) and (max-width: 991px) {
					height: 200px;
				}
			}

			.box-link {
				position: absolute;
				top: 0;
				color: #fff;
				width: 100%;
				height: 100%;
				text-shadow: 0 0 7px rgb(0 0 0 / 70%);
				font-size: 20px;
				font-weight: 500;
				padding: 20px;
				bottom: 0;
				display: flex;
				align-items: flex-end;
				justify-content: flex-end;
				background: rgb(0 0 0 / 15%);
				letter-spacing: 0.3px;
				transition: all .2s linear;
				text-align: right;

				&:hover {
					background: rgb(0 0 0 / 25%);
				}
			}
		}

		.height-box {
			img {
				height: 590px;

				@media (max-width: 767px) {
					height: auto !important;
				}

				@media all (min-width: 768px) and (max-width: 991px) {
					height: 430px;
				}
			}
		}
	}
}

.vo-index-body {
	.popover {

		min-width: 200px;
		font-size: 16px;
		border: none;
		box-shadow: 0 0 10px rgb(0 0 0 / 30%);
		width: 100%;

		@media (max-width: 574px) {
			max-width: 143px;
			min-width: unset;

		}

		@media (max-width: 767px) {
			margin-left: -5px !important;
		}

		.arrow {
			@media (max-width: 1249px) {
				left: 20px !important;
			}

			@media (max-width: 767px) {
				left: 13px !important;
			}
		}

		.popover-content {
			.map-popover {
				padding: 0 !important;

				@media (max-width: 767px) {
					padding: 0 !important;
				}

				.popover-title {
					display: block !important;
					padding: 0px 0 10px 0 !important;
					color: var(--secondary);
					font-size: 14px !important;
				}

				a {
					color: #666;

					&:hover {
						color: var(--HoverColor);
					}
				}
			}
		}

		a {
			width: 100%;
			display: block;
			font-size: 14px;
			line-height: 20px;
			padding: 8px 15px;
			border-bottom: 1px solid rgb(204 204 204/50%);
			border-left: 0 solid #ccc;
			color: #000;
			font-weight: 400;

			&:last-child {
				border-bottom: none;
			}

			@media (max-width: 767px) {
				padding: 9px 5px;
				font-size: 13px;
			}
		}
	}

	.popover {
		@media all (min-width: 768px) and (max-width: 1023px) {
			margin-left: 10px !important;
		}

		@media all (min-width: 1024px) and (max-width: 1249px) {
			margin-left: -32px;
		}
	}

	.box-price {
		.popover {
			font-size: 12px;
			background-color: #fff6df;
			max-width: 250px;
			width: 100%;
			margin-left: -105px;
			border: 1px solid #febb02 !important;
			z-index: 2;
		}
	}

	.unit-list-section {
		.min-row {
			margin: 0;
		}

		.next-prev-btns {
			position: relative;

			.owl-nav {
				position: absolute;
				top: 45%;
				left: 0;
				width: 100%;

				button {
					width: 25px;
					height: 25px;
					border-radius: 50%;
					border-radius: 50%;
					box-shadow: 0 0 8px rgba(0, 0, 0, .21);
					background-color: transparent !important;
					position: absolute;
					left: 0;
					box-shadow: none;

					@media (max-width:992px) {
						left: -6px !important;
					}

					span {
						font-family: "Font Awesome 5 Free";
						font-weight: 900;
						-webkit-font-smoothing: antialiased;
						display: inline-block;
						font-style: normal;
						font-variant: normal;
						text-rendering: auto;
						line-height: 1;
						color: transparent;

						&:before {
							color: #153154 !important;
							font-family: "Font Awesome 5 Free";
							position: relative;
							left: 2px;
							font-size: 25px;
							top: 1px;
						}
					}

					&.owl-next {
						position: absolute;
						right: -20px;
						left: initial;

						@media (max-width:992px) {
							right: -6px !important;
							left: unset !important;
						}

						span {

							&:before {
								content: "\f054";
								left: 3px;
							}
						}
					}

					&.owl-prev {
						left: -20px;

						span {

							&:before {
								content: "\f053";

							}
						}
					}
				}
			}
		}

		.search-unit-bluebar {
			align-items: center;
			justify-content: space-between;
			padding: 5px 15px;
			width: 100%;
			background: #cbd5dd;
			display: flex;
			flex-wrap: wrap;

			@media (max-width: 992px) {
				flex-wrap: wrap;
				padding: 10px;
			}

			.units {
				width: 100%;

				ul {
					display: flex;
					align-items: center;
					list-style: none;
					flex-wrap: wrap;

					li {
						margin: 0 5px 0 0;

						@media all and (min-width: 768px) and (max-width: 1199px) {
							margin: 0 1px 0 0;
						}

						.icon-bg {
							background-image: url(RESOURCE/img/Icon_alle.svg);
							width: 30px;
							height: 30px;
							display: block;
							background-size: 55px;
						}

						.balcony {
							background-position: -12px -304px;
						}

						.sauna {
							background-position: -12px 142px;
						}

						.seeview {
							background-position: -12px -256px;
						}

						.pets {
							background-position: -12px -501px;
						}

						.wifi {
							background-position: -12px -646px;
						}

						.lift {
							background-position: -12px -699px;
						}

						.nonsmoking {
							background-position: -12px -600px;
						}

						.geschirrspuler {
							background-position: -12px -353px;
						}

						.kamin {
							background-position: -12px -550px;
						}

						.kinderbett {
							background-position: -12px -402px;
						}

						.kleinkindausstattung {
							background-position: -12px -452px;
						}

						.parking {
							background-position: -12px 241px;
						}

						.waschmaschine {
							background-position: -12px 43px;
						}

						img {
							width: 18px;
						}
					}
				}
			}

			.mehr-btn-area {
				width: 100%;
				display: flex;
				justify-content: flex-end;
				margin-top: 20px;

				@media (max-width: 992px) {
					width: 100%;

				}

				@media all and (min-width: 768px) and (max-width: 1199px) {
					display: flex;
					align-items: center;
					justify-content: flex-end;
				}

				a {
					background: var(--primary);
					padding: 5px 10px;
					color: #fff;
					font-size: 12px;
					letter-spacing: 1px;
					border: 1px solid var(--white);

					i {
						color: #fff;
						margin: 0 0 0 5px;

					}

					&:hover {
						border: 1px solid var(--white);
						color: #fff;
						background: var(--HoverColor);
					}

					&:hover i {
						color: var(--white);
					}
				}
			}

		}

		.single-unit {

			@media (max-width: 767px) {
				margin-bottom: 0 !important;
			}

			.unit-img {
				box-shadow: none;
			}
		}

		.info-col {
			background: #bec8d2 !important;
			padding: 15px 15px;
			padding-bottom: 5px;
			height: 100%;

			.show-mob {
				.unit-distance {
					background-color: var(--white);
					border-radius: 15px;
					position: absolute;
					top: -55px;

					@media (max-width:992px) {
						top: -36px;
					}
				}
			}

			@media (max-width:992px) {
				background: rgb(203 213 221 / 30%);

			}



			.region-bl {
				display: inline-flex;
				align-items: center;
				position: absolute;
				top: 5px;
				right: 10px;

				span {
					color: var(--black);
					opacity: .5;
					font-size: 13px;
				}
			}

			.text-truncate {
				color: var(--secondary);
				font-size: 13px;
				font-family: var(--d-font2);
				margin-bottom: 20px;
			}

			.flex-align-center {
				padding-bottom: 14px;
				border-bottom: 1px solid #f2f2f2;
				margin-bottom: 14px;
			}

			.search-result-properties {
				margin: 0 !Important;

				li {
					display: inline-block;
					list-style: none;
					text-align: center;
					float: left;
					padding: 0 0 0 10px;

					@media (max-width:360px) {
						padding-left: 5px;
					}

					i {
						font-size: 16px;
						color: var(--d-grey);
						font-style: normal;
						width: 28px;
						height: 28px;
						background-color: var(--grey);
						border-radius: 50%;
						display: flex;
						align-items: center;
						justify-content: center;
						position: relative;

						@media (max-width:360px) {
							font-size: 14px;
							width: 26px;
							height: 26px;
						}

						&.cross {
							&:after {
								width: 1.5px;
								height: 100%;
								position: absolute;
								content: "";
								background-color: var(--d-grey);
								-webkit-transform: rotate(-45deg);
								transform: rotate(-45deg);
							}
						}
					}
				}
			}



			.unit-title {
				display: flex;
				align-items: baseline;
				flex-wrap: wrap;
				margin: 0 0 20px;

				h4 {
					display: flex;
					align-items: baseline;
					font-weight: 600;
					line-height: 24px;
					justify-content: space-between;
					flex-wrap: wrap;
					width: 100%;
					font-size: 20px;
					line-height: 26px;
					margin: 0 0 10px 0;
					font-family: var(--bold-font2);

					@media (max-width: 767px) {
						font-size: 20px;
						line-height: 26px;
					}

					small {
						width: 100%;
						font-size: 13px;
						padding: 5px 0;
						color: var(--textprimary);
					}
				}

				a {
					color: var(--textprimary);
					display: block;
					min-height: 55px;
					width: 100%;
				}

				span {
					width: 100%;
					font-size: 12px;
					font-weight: 400;
					display: flex;
					align-items: center;
					position: relative;
					top: 0;
					padding: 0;
					color: #565a5c;

					@media (max-width:767px) {
						justify-content: flex-start;
						padding: 0;
						top: 0;
					}

					.fav-icon {
						margin: 0 0 0 10px;
					}
				}

				@media (max-width:992px) {
					font-size: 16px;
				}

				@media (max-width:767px) {
					flex-wrap: wrap;
					margin: 0 0 20px 0;
				}
			}

			.unit-price-rat {
				display: -webkit-box;
				display: flex;
				flex-wrap: wrap;
				-webkit-box-pack: justify;
				justify-content: space-between;
				-webkit-box-align: center;
				align-items: center;
				flex-wrap: wrap;

				.prices {

					width: 100%;
					padding: 15px 0;

					.offer-price-box {
						display: flex;
						align-items: center;
						flex-wrap: wrap;

						.discount-price {
							margin: 0 5px 0 0;

							@media (max-width: 575px) {
								font-size: 16px;
							}
						}

						.pro {
							margin: 0 0 0 5px;
						}

					}
				}


				.unit-link {
					text-transform: uppercase;
					color: var(--primary);
					font-size: 13px;
					font-weight: 700;
					position: relative;
					top: 2px;
				}
			}

			.alternatives {

				margin: 20px 0 12px 0;
				font-size: 14px;

				.altheading {
					color: var(--red);
					font-family: var(--d-font2);
					margin-bottom: 10px;
					display: block;
				}

				.li-bullet {

					li {
						list-style: none;
						background-color: #cbd5dd;
						padding: 10px 15px;
						color: var(--black);
						opacity: 1;
						border: 1px solid #cfebf9;
						font-family: var(--d-font2);
						border-bottom: none;

						&:first-child {
							border-radius: 5px 5px 0 0;
						}

						&:last-child {
							border-radius: 0 0 0px 0px;
							border-bottom: 1px solid #cfebf9;
						}

						a {
							color: var(--secondary);
						}
					}
				}
			}

			.offer-price-box {
				.main-price {
					strong {
						text-decoration: line-through;
					}
				}

				.discount-price[title="Last-Minute"] {
					strong {
						color: var(--red);
					}
				}

				>.discount-price {
					font-size: 21px;
					font-weight: 600;
					color: var(--secondary);
				}

				.popover {
					@media (max-width: 374px) {
						max-width: 218px;
						margin-left: -79px;
					}

					@media all (min-width:375px) and (max-width: 767px) {
						margin-left: -79px;
						max-width: 218px;
					}

					@media all (min-width: 992px) and (max-width: 1199px) {
						margin-left: -95px;
						max-width: 230px;
					}
				}
			}

			.offer-bl-srch {
				text-align: center;
				position: relative;
				z-index: 1;

				.offer-box {
					padding-top: 12px;
					border-top: 1px solid #cbd5dd;
					margin-top: 0;
					margin-bottom: 12px;
					display: block;

					@media (max-width: 992px) {
						border-top: 1px solid rgb(0 0 0 / 10%);
					}
				}


				.popover {
					font-size: 12px;
					background-color: #fff6df;
					border: 1px solid #febb02 !important;
					z-index: 2;

					.popover-title {
						text-transform: capitalize;
						padding: 0 0 10px 0;
						margin: 0;
						font-size: 12px !important;
						background-color: transparent;
						border-bottom: none;
						border-radius: 5px 5px 0 0;
						font-family: var(--d-font);
						font-weight: 400;
						margin-bottom: 10px;
						padding-bottom: 4px;
						border-bottom: 1px solid #febb02;
						color: #47403a;
					}

					.arrow {
						&:after {
							border-top-color: #febb02 !important;
							border-bottom-color: #febb02 !important;
						}
					}
				}

				.offer-box {
					a {
						background-image: url(RESOURCE/img/offer-bg.png);
						position: relative;
						padding: 0;
						display: inline-flex;
						align-items: center;
						background-repeat: no-repeat;
						background-position: center;
						max-width: 270px;
						background-size: 270px;
						height: 38px;
						display: flex;
						align-items: center;
						justify-content: center;
						margin: 0 auto;
						color: var(--secondary);
						font-size: 14px;

						@media (max-width: 400px) {
							background-size: 240px;
						}

					}

					.offer-text {
						background-image: url(RESOURCE/img/bg-offer-text.png);
						background-repeat: no-repeat;
						background-position: center;
						text-transform: uppercase;
						font-size: 14px;
						width: 116px;
						height: 38px;
						display: flex;
						align-items: center;
						justify-content: center;
						text-transform: uppercase;
						font-weight: bold;
						background-size: 116px;
						color: var(--black);
					}

					ul {
						li {}
					}
				}
			}



			.rentRate {
				.minrent {
					margin: 0;

					strong {
						font-size: 18px;
						font-weight: 600;
						color: var(--textprimary);

						@media (max-width:992px) {
							font-size: 18px;
						}
					}

					.price-bl {
						display: flex;
						align-items: center;
						font-size: 14px;
						color: var(--textprimary);
						font-weight: 500;
						flex-wrap: wrap;

						i.fa {
							color: #7f7f7f;
							font-size: 12px;
							margin-left: 5px;
						}

						.price-start {
							font-size: 18px;
							font-weight: 600;
							color: var(--textprimary);
							margin: 0 5px 0 0;
						}

						.pro {
							width: 100%;
							display: block;
							padding: 5px 0 0 0;
							color: var(--textprimary);
						}
					}

					span {
						font-size: 12px;
						color: #7e7e7e;
						display: block;
						line-height: 18px;
					}
				}

				a {
					color: var(--textprimary);
					position: relative;
					z-index: 1;
				}

				.price-info {

					i {
						color: #7f7f7f;
						font-size: 12px;
						margin-left: 5px;
					}
				}
			}

			.unit-dt-right {
				display: flex;
				align-items: center;
			}

			.unit-distance {
				padding: 0 15px;
				font-weight: bold;
				right: 0;
				top: 0;
				font-size: 12px;
				line-height: 30px;
				color: rgba(0, 0, 0, .7);
				background-color: #e6f2f7;
				border-radius: 15px;
			}

			.stars {
				position: absolute;
				right: 0;
				bottom: 0;
			}

			.unit-place {
				font-size: 16px;
				font-family: var(--d-font2);
				min-height: 180px;

				@media (max-width:1200px) {}


				li {
					/*display: inline-block !important;*/
					color: #555;
					width: 100%;
					padding: 0 0 0 10px !important;
					font-size: 14px;
					margin: 0 0 5px 0;
					position: relative;


					&:before {
						display: block;
						content: "";
						width: 1px;
						height: 12px;
						background: #555;
						position: absolute;
						left: 0;
						top: 4px;
					}

					@media (max-width:360px) {
						font-size: 14px;
						padding-left: 3px;
					}

					&:first-child {
						padding-left: 0;
						margin-left: 0;

					}

					i {
						font-size: 18px;
						opacity: .2;
						margin-right: 3px;

						@media (max-width:360px) {
							font-size: 15px;
							margin-right: 2px;
						}
					}
				}
			}

			.prices {
				.price {
					font-size: 20px;
				}
			}

			.search-result-properties {
				margin-top: 5px;
				margin-bottom: 15px;
			}
		}

		.unit-cont-bl {
			padding: 0;

			.search-unit-bluebar {
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 10px;
				background: #cbd5dd;
				width: 100%;
				flex-wrap: wrap;
			}

			h6 {
				position: static;
				width: 100%;
				display: flex;
				flex-wrap: wrap;
				justify-content: flex-end;

				a {
					background: var(--textprimary);
					padding: 5px 10px;
					color: #fff;
					font-size: 12px;
					letter-spacing: 1px;
					border: 1px solid var(--white);
					font-weight: 500;
					display: block;
				}
			}
		}
	}



}