@import "common.css";


.header-style2 {
	&.no-fix {
		.mainHeader {
			@media (min-width: 768px) {
				position: relative !important;
			}
		}
	}
}

.btn-360 {
	background-image: url(RESOURCE/img/bg-360-view.png);
	width: 100px;
	height: 100px;
	border-radius: 80px;
	background-color: var(--primary);
	position: absolute;
	right: 15px;
	top: 15px;
	z-index: 2;
	background-size: 70px;
	background-repeat: no-repeat;
	background-position: center;
	border: none;

	@media (max-width:767px) {
		background-size: 40px;
		width: 60px;
		height: 60px;
	}

	&:hover {
		background-color: var(--HoverColor);
	}
}

.header-style2 {
	&.no-fix {
		.mainHeader {
			@media (max-width: 767px) {
				position: fixed !important;
			}
		}
	}
}

.unit-offer {
	padding: 30px;
	background: #eaf0f3;
	position: relative;
	margin-bottom: 30px;
	margin-top: 20px;

	.offer-minstay {
		font-size: 13px;

	}

	.offer-minstay-xy {
		font-size: 13px;

	}

	.offer-condition {
		font-size: 13px;
	}

	.offer-banner {
		position: absolute;
		background-color: #c00;
		color: var(--white);
		top: -13px;
		left: -8px;
		padding: 2px 10px;
		text-transform: uppercase;
		font-size: 1rem;
		border-radius: 5px;
		box-shadow: 1px 0 3px 0 rgb(0 0 0 / 35%);

	}


}

.unit-view {


	.unit-navbar-v1 {
		background-color: var(--white);
		position: fixed;
		visibility: hidden;
		opacity: 0;
		z-index: 14;
		top: 0;
		width: 100%;
		left: 0;
		box-shadow: 0 1px 1px rgba(0, 0, 0, .15);

		&.shownavbar {
			visibility: visible;
			opacity: 1;
		}

		.fl-menu-itm {
			padding: 20px 10px;
			color: var(--unit-navbar-font-color-item);
			display: inline-block;
			font-weight: bold;
			font-size: 16px;
			border-bottom: 3px solid transparent;

			&.active {
				border-bottom: 3px solid var(--primary);
			}

			sup {
				background-color: #c00;
				color: #fff;
				border-radius: 5px;
				padding: 1px 7px;
				margin-left: 2px;
				font-family: var(--d-font);
				font-size: 1rem;
				font-weight: 400;
				margin-left: 5px;
			}

			&:hover {
				color: var(--unit-navbar-font-color-item-hover);
				background-color: var(--unit-navbar-bg-color-item-hover);
				text-decoration: none;
			}

			@media (max-width:1200px) {
				font-size: var(--font-size-md);
			}

		}


		@media (max-width:992px) {
			display: none;
		}
	}

	.common-unit-hint {
		font-style: italic;
		margin-top: 30px;

		p {
			font-size: 14px;
			line-height: 20px;
			margin-bottom: 0px;
		}
	}


	h2 {
		font-size: 24px;
		color: var(--textprimary);
		display: flex;
		align-items: baseline;
		font-weight: 600;
		line-height: normal;
		justify-content: flex-start;
		margin: 10px 0 20px;
	}

	.title-img-container {
		width: 100%;
		cursor: pointer;
	}

	.search-form-area {
		.region-box {
			@media (max-width: 767px) {
				width: 100% !important;
			}
		}
	}

	.button-section {
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 50px 0 0 0;
		flex-wrap: wrap;

		.btn {
			background: var(--secondary);
			padding: 10px 20px;
			color: #fff;
			font-size: 16px;
			letter-spacing: 1px;
			border: 1px solid var(--secondary);
			text-transform: uppercase;
			border-radius: 0;
			max-width: 170px;
			width: 100%;
			margin: 5px 10px;

			&:hover {
				background: var(--HoverColor);
			}

			@media (max-width: 379px) {
				max-width: 370px;
			}
		}
	}

	.flow-buttons {
		.btn {
			padding: 10px 20px;
			border-radius: 0;
			font-size: 14px;
			text-transform: uppercase;
			letter-spacing: 1px;

			&.btn-go {
				margin-left: 15px;
				margin-bottom: 20px;
				font-size: 14px;
				padding: 10px 20px;
				border-radius: 0;
				letter-spacing: 1px;
				text-transform: uppercase;

				&:hover {
					background: var(--HoverColor);
				}

			}
		}
	}

	.subrow {
		.book-btn {
			&.btn-lg {
				height: 40px;
				font-size: 16px;
				border-radius: 0;
				text-transform: uppercase;
			}
		}
	}

	.ratings {
		.stars {
			margin-top: 0;
			color: var(--primary);
			display: inline-flex;

			.fa {
				color: var(--primary);
			}

		}

		.rating-gr-bl {
			background: rgb(242, 246, 248);
			background: linear-gradient(331deg, rgba(242, 246, 248, 1) 0%, rgba(234, 238, 241, 1) 100%);
			box-shadow: 0 0 8px rgba(0, 0, 0, .15);
			max-width: 460px;
			margin-bottom: 15px;

			.rating-head {
				text-align: center;
				padding: 30px 20px;
				margin-bottom: 10px;

				h3 {
					margin-top: 0;
					font-family: var(--d-font);
					font-weight: normal;
					font-size: 24px;
				}

				.rating-st-bl {
					display: inline-flex;
					max-width: 250px;
					text-align: center;
					border-radius: 20px;
					padding: 8px 20px;
					background-color: var(--white);
					font-size: 18px;
					font-family: var(--d-font);
					margin: 15px 0 0;

					.stars {
						font-size: 16px;
						margin-top: 0;
					}

					span {
						margin-right: 10px;
					}
				}

				.blue-link {
					color: var(--secondary);
					text-decoration: underline;
				}
			}

			.rating-row {
				padding: 18px 20px;
				border-top: 2px solid #e2e6e8;

				span {
					font-size: 18px;
					font-family: var(--d-font);
				}

				.stars {
					font-size: 16px;
				}
			}
		}

		.feedbacks {
			blockquote {
				p {
					/* font-family: 'Indie Flower', cursive; */
					font-family: 'ComicNeue-Regular';
					font-weight: 600;
				}

				footer {
					&:before {
						content: "-";
					}
				}
			}

			.rating-head {
				.stars {
					color: var(--primary);
					display: inline-block;
					margin-left: 15px;
				}
			}
		}


		.cl-feed-bl {
			@media (min-width:600px) {
				max-height: 220px;
				overflow: hidden;
			}
		}

		.cl-feedbl {
			box-shadow: 0 0 8px rgba(0, 0, 0, .15);
			padding: 20px;
			border-radius: 15px;
			background-color: var(--white);
			position: relative;
			min-height: 355px;

			@media (max-width:992px) {
				padding: 20px 30px;
				padding-top: 30px;
			}

			footer {
				font-size: 14px;
				position: absolute;
				bottom: 20px;
				color: var(--grey3);
			}

			i {
				font-size: 40px;
				position: absolute;
				color: var(--grey);
				top: -25px;
			}

			.cli-name {
				color: var(--secondary);
				font-family: var(--d-font);
				font-weight: normal;
			}
		}

		.feedback-title {
			font-size: 20px;
			font-family: 'ComicNeue-Regular';
			font-weight: 600;
			padding-bottom: 30px;
		}

		.comment {
			margin-top: 10px;
			padding: 10px 20px;
			margin: 0 0 20px;
			font-size: 17.5px;
			border-left: 5px solid #eee;

			.agency {
				font-weight: bold;
			}

			.answer {
				font-style: italic;
				font-size: 15px;
				padding-top: 20px;

				p,
				a,
				div {
					font-size: 15px;
					line-height: 20px;
				}


			}
		}
	}


	.search-section {
		margin: 0 -10px;

		.asd__wrapper {
			background: #fff !important;
		}

		.searchbox {
			padding: 0 10px;
			position: relative;

			i {
				position: absolute;
				right: 17px;
				top: 0;
				opacity: .2;
				font-size: 20px;
			}
		}

		.search-popup-trigger {
			cursor: pointer;
		}

		.form-control:disabled,
		.form-control[readonly] {
			background-color: white;
		}

	}



	.unit-icons {
		span {
			background-image: url(RESOURCE/img/unit-icons.png);
			width: 36px;
			height: 36px;
			display: inline-block;
			border-radius: 50%;
			margin-right: 8px;
			background-repeat: no-repeat;
			display: inline-block;
			background-color: #9D9D9D;
			margin-bottom: 5px;

			&.pets-cross {
				background-position: -253px -1px;
			}

			&.smoke {
				background-position: -304px -1px;
			}

			&.balcony {
				background-position: -122px -1px;
			}

			&.washing {
				background-position: -76px -1px;
			}

			&.pool {
				background-position: 0 0;
			}

			&.wlan {
				background-position: -24px 3px;
				background-size: 405px;
			}

			&.spulmachine {
				background-position: -350px 0px;
			}

			&.oven {
				background-position: 8px -2px;
			}

			&.shower {
				background-position: -192px -5px;
				background-size: 565px;
			}

			&.bathtub {
				background-position: 0 0;
			}

			&.pets {
				background-image: url(RESOURCE/img/unit-icons2-new.png);
				background-position: -44px 2px;
			}

			&.sauna {
				background-image: url(RESOURCE/img/unit-icons2-new.png);
				background-position: -133px 3px;
			}

			&.terrace {
				background-image: url(RESOURCE/img/unit-icons2-new.png);
				background-position: -89px 1px;
			}

			&.swimming {
				background-image: url(RESOURCE/img/unit-icons2-new.png);
				background-position: 3px 0px;
			}

			&.beachchair {
				background-position: -398px -1px;
			}

			&.seeview {
				background-position: -456px 0px;
			}
		}
	}

	#booking {
		padding: 0 0 20px 0;

		.quoteselec select:after {
			color: var(--primary) !important;
		}

		.form-control {
			border: none;
			border-bottom: 2px solid #f2f2f2;
			text-transform: uppercase;
			margin-bottom: 30px;
			border-radius: 0;
			box-shadow: inherit;
			padding: 0;

			&.slselect {
				background-color: #eaf0f3;
				padding-left: 10px;
				padding-right: 20px !important;
				border: 1px solid var(--primary);
			}

		}

		.taxnote {
			padding: 20px 5px 0 5px;
			font-family: var(--d-font);
			color: #666;
			font-size: 14px;
			line-height: 20px;
		}



		@media (max-width:992px) {
			margin-bottom: 0;
		}

		@media (max-width:767px) {
			padding-bottom: 0;

		}

		.info-col-main.filters {
			display: flex;
			width: 100%;
			flex-wrap: nowrap;
			background: var(--primary);
			padding: 15px;
			align-items: center;

			@media (max-width: 767px) {
				flex-wrap: wrap;
			}

			.info-col-header {
				padding: 0 15px 0 0;

				@media (max-width: 767px) {
					padding: 0 0 10px 0;
					width: 100%;
				}

				@media all and (min-width: 768px) and (max-width: 991px) {
					padding: 0 0 0 0;
				}

				h4 {
					font-size: 16px;
					color: #fff;
					margin: 0;
					font-weight: 400;
					letter-spacing: 1px;

					@media all and (min-width: 768px) and (max-width: 991px) {
						font-size: 14px;
					}
				}
			}

			.search-section {
				width: 80%;
				display: flex;
				align-items: center;
				margin: 0;

				@media (max-width: 767px) {
					flex-wrap: wrap;
					width: 100%;
				}

				@media all and (min-width: 768px) and (max-width: 991px) {
					flex-wrap: wrap;
					width: 78%;
				}

				.searchbox {
					padding: 0 10px;
					position: relative;
					max-width: 285px;
					width: 100%;

					@media (max-width: 767px) {

						width: 100%;
						max-width: 100%;
						flex: unset;
						padding: 5px 0;
					}

					.form-control {
						margin-bottom: 0;
						border: none !important;
						padding: 0 35px 0 15px;

						@media all and (min-width: 768px) and (max-width: 991px) {
							font-size: 12px;
						}
					}

					i {
						right: 20px;
						top: 7px;

						@media (max-width:767px) {
							right: 15px;
							top: 13px;
						}
					}
				}
			}
		}

	}

	.unit-list-section {

		@media (max-width: 767px) {
			margin-top: 135px;
		}

		.owl-item {
			position: relative;

			&:before {
				position: absolute;
				left: 0;
				top: 0;
				right: 0;
				bottom: 0;
				background-color: rgba(255, 255, 255, .7);
				content: ' ';
				transition: all 0.5s ease;
			}

			&.active {
				&:before {
					background-color: rgba(255, 255, 255, 0);
				}
			}
		}

		.owl-dots {
			justify-items: center;
			position: absolute;
			max-width: 100%;
			width: 100%;
			left: 0;
			right: 0;
			margin: 0 auto;
			flex-wrap: wrap;
			flex-direction: initial;
			bottom: 100px;
			text-align: center;
			display: block;

			@media (max-width: 991px) {
				bottom: 20px;
				padding: 0 20px;

			}

			@media all (min-width: 992px) and (max-width: 1140px) {
				bottom: 20px;
				padding: 0 20px;
			}

			.owl-dot {
				margin: 0 5px;
				opacity: 0.5;

				span {
					width: 10px;
					height: 10px;
					background: #fff;
					display: block;
					border-radius: 100px;

				}

				&.active {
					opacity: 1;

				}
			}
		}

		&.nxt-prev-btns {
			position: relative;

			.owl-nav {
				position: absolute;
				top: calc(50% - 50px);
				left: 0;
				width: 100%;

				@media (max-width: 767px) {
					top: calc(50% - 25px);
				}



				button {
					position: absolute;
					left: 40px;
					font-size: 45px;

					@media (max-width:767px) {
						left: 15px;
						font-size: 28px;
					}

					span {
						display: inline-block;
						font-style: normal;
						font-variant: normal;
						text-rendering: auto;
						line-height: 1;
						color: transparent;

						&:before {
							color: var(--white);
							font-family: "Font Awesome 5 Pro";
							position: relative;
							left: 3px;
						}
					}

					&:hover {
						span {}
					}

					&.owl-next {
						position: absolute;
						right: 40px;
						left: initial;

						@media (max-width:767px) {
							right: 10px;
						}

						span {

							&:before {
								content: "\f054";
								left: 8px;
							}
						}
					}

					&.owl-prev {
						span {

							&:before {
								content: "\f053";

							}
						}
					}
				}
			}
		}

		.item {
			overflow: hidden;


			/* @media (max-width:767px) {
				height: 480px;
			}

			@media (max-width:575px) {
				height: 300px;
			} */

			>a {
				/* position: absolute; */
				left: 0;
				top: 0;
				right: 0;
				bottom: 0;
				z-index: 2;
			}

			img {
				width: 100%;
				object-fit: cover;
				height: auto;
				position: relative;

				@media (max-width: 1920px) {
					height: calc(100vh - 135px);
				}

				@media (max-width: 1140px) {
					height: 100%;
				}
			}

			p.img-loding {
				position: absolute;
				width: 100%;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				margin: 0;
				font-size: 20px;
				font-weight: 600;
				z-index: -1;
			}
		}
	}

	.search-box-home {
		@media (max-width: 1140px) {
			position: relative;
			bottom: inherit;
			background: var(--secondary);
		}
	}

	.title-img {

		position: relative;
		width: 100%;
		height: 450px;

		-webkit-background-size: cover;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;


		.v-left-gallery-btn,
		.v-right-gallery-btn {
			font-size: 160px;
			position: absolute;
			top: 50%;
			margin-top: -57px;
		}

		.v-left-gallery-btn:hover,
		.v-right-gallery-btn:hover {
			color: #585858;
		}

		.v-right-gallery-btn {
			right: 40px;
		}

		.v-left-gallery-btn {
			left: 40px;
		}

	}


	.unit-gallery {
		background: #eaf0f3;
		padding: 50px 0;
		margin: 0 0 50px 0;

		.v-img-gallery {
			top: 0;
			width: 100%;
			max-width: 100%;
			justify-content: flex-start;
			flex-wrap: Wrap;
		}
	}

	.panorama {
		position: relative;
		width: 100%;
		height: 700px;
	}

	.main-section {
		position: relative;
		padding: 0;

		.map-strip {
			@media (min-width:993px) {
				min-height: 600px;
			}

			.time-li {
				@media (min-width:993px) {
					min-height: 420px;
				}
			}
		}

		.unit-calendar {
			background: #eaf0f3;
			padding: 20px 30px;
			margin: 0 0 30px 0;

			@media (max-width: 767px) {
				padding: 30px 15px;

			}

			@media (max-width: 374px) {
				padding: 0;
				background: transparent;
			}

			.container {
				max-width: 1170px;
				width: 100%;
				padding: 0;

			}

			.popover {
				font-size: 12px;
				background-color: #fff6df;
				border: 1px solid #febb02 !important;
				z-index: 2;

				.popover-title {
					text-transform: capitalize;
					padding: 0 0 10px 0;
					margin: 0;
					font-size: 12px !important;
					background-color: transparent;
					border-bottom: none;
					border-radius: 5px 5px 0 0;
					font-family: var(--d-font);
					font-weight: 400;
					margin-bottom: 10px;
					padding-bottom: 4px;
					border-bottom: 1px solid #febb02;
					color: #47403a;
				}

				.arrow {
					&:after {
						border-top-color: #febb02 !important;
						border-bottom-color: #febb02 !important;
					}
				}
			}
		}

		.preisliste-section {
			padding: 0 0 30px;

			.popover {
				font-size: 12px;
				background-color: #fff6df;

				.arrow {
					border-top-color: #ddd;
					border-top-color: #febb02 !important;
					border-bottom-width: 0;

					&:after {
						border-top-color: #febb02 !important;
						border-bottom-color: #febb02 !important;
					}
				}

				.popover-title {
					text-transform: capitalize;
					padding: 0 0 10px 0;
					margin: 0;
					font-size: 12px !important;
					background-color: transparent;
					border-bottom: none;
					border-radius: 5px 5px 0 0;
					font-family: var(--d-font);
					font-weight: 400;
					margin-bottom: 10px;
					padding-bottom: 4px;
					border-bottom: 1px solid #febb02;
					color: #47403a;
				}

				.popover-content {
					ul {
						list-style: none !important;

						li {
							font-size: 11px;
							font-weight: 500;
							list-style: unset;
							padding-left: 0;
							font-family: var(--d-font);
							color: #47403a;
							position: relative;
							padding-bottom: 10px;
							padding-left: 15px;

							&:before {
								display: block;
								content: " ";
								font-family: "Font Awesome 5 Free";
								content: "\f054";
								font-weight: 900;
								color: #E2D69E;
								font-size: 12px !important;
								position: absolute;
								left: 0;
								right: 0;
							}
						}
					}
				}
			}

			.table.sltable {
				border: none;
				border-collapse: collapse;

				tr {
					/* &:first-child {
						background: #cbd5dd;
						border: none;
						font-size: 16px;

						td {
							border: none;
							padding: 15px;
							font-weight: 700;
						}
					} */

					td:first-child {
						border: none;
						padding: 15px;
						font-weight: 700;
						border: none;
						font-size: 15px;
						width: 600px;
						font-family: var(--d-bold);

						@media (max-width: 991px) {
							min-width: 350px;
						}

						@media (max-width: 767px) {
							min-width: 200px;
							padding: 10px;
						}

						@media (max-width: 480px) {
							width: auto;
							min-width: 80px;
							word-break: break-word;
							max-width: 100px;
							white-space: inherit;
						}

						span {
							@media (max-width: 480px) {
								white-space: break-spaces;
							}
						}
					}

					td:last-child {
						min-width: 200px;

						@media (max-width: 767px) {
							min-width: auto;
							width: 80px;
							padding: 10px;
						}
					}

					td.subinfo {
						padding: 10px 15px;
						font-size: 15px;
						border: none;
						font-family: var(--d-thin);

						span {
							font-weight: 400;
						}
					}

					td {
						padding: 10px 15px;
						font-size: 15px;
						border: none;
						vertical-align: middle;
					}

					.rtext {
						text-align: right;
						padding-right: 120px !important;
						width: 200px;
						vertical-align: middle;

						@media (max-width: 767px) {
							padding: 10px !important;
							width: auto;
						}

						@media (max-width: 480px) {
							padding: 10px !important;
						}

						@media all and (min-width: 768px) and (max-width: 991px) {
							padding-right: 60px !important;
						}
					}


					&:nth-child(odd) {
						background-color: #e4e9ed;
					}

					&:nth-child(even) {
						background-color: #fff;
					}
				}
			}
		}

		.page-header {
			margin-top: 0;
			border-bottom: none;
			margin-bottom: 0;
			position: relative;

			.unit-fav-star {
				position: absolute;
				left: 0;
				top: -90px;
				z-index: 10;
				max-width: 450px;
				width: 100%;

				@media (max-width:992px) {
					width: 100%;
					display: flex;
					justify-content: space-between;
					top: -100px;
				}


				.stars {
					font-size: 18px;
					color: var(--primary);
					margin-right: 5px;
					letter-spacing: 2px;
					display: inline-block;

					@media (max-width:992px) {
						font-size: 13px;

					}
				}

				.btn-icon-fav {
					max-width: 280px;
					display: inline-block;
					background-color: rgba(255, 255, 255, .7);
					border-radius: 30px;
					font-size: 18px;
					border: none;
					padding-top: 10px;
					font-family: var(--d-font);

					@media (max-width:992px) {
						font-size: 16px;
						padding-bottom: 10px;
						padding-top: 8px;
						max-width: 240px;
					}

					@media (max-width:360px) {
						font-size: 13px;
						max-width: 186px;
					}


					i {
						font-size: 22px;
						margin-right: 10px;
						color: var(--white);
						position: relative;
						top: 1px;

						@media (max-width:360px) {
							font-size: 16px;
							margin-right: 4px;
						}

					}

					&:hover {
						background-color: var(--primary);
					}
				}
			}



			@media (max-width:992px) {
				margin-top: 10px;
			}

			.heading1 {
				margin-bottom: 10px;
			}

			.unit-pr-dt-gallery {
				@media (min-width:993px) {
					display: flex;
					justify-content: space-between;

				}
			}

			.unit-dt-row {
				@media (min-width:1300px) {
					right: -45px;
				}

				@media (min-width:993px) {
					display: flex;
					justify-content: space-between;
					position: relative;
					right: 0;
				}

				@media (max-width:992px) {
					margin-top: 20px;
					padding-bottom: 15px;
				}

				.back-btn {
					color: var(--primary);

					.fal {
						margin-right: 5px;
					}
				}

				.unit-dt-bl {
					margin-right: 20px;
					margin-top: 15px;

					@media (max-width:992px) {
						margin-top: 0;
					}

					>div {
						font-family: var(--d-font);
						padding: 0 15px;
						display: inline-block;
						font-size: 18px;
						text-align: center;

						i {
							opacity: .2;

							@media (max-width:992px) {
								margin-right: 7px;
							}

							@media (min-width:993px) {
								margin-bottom: 12px;
							}
						}

						span {

							font-size: 18px;
							text-align: center;

							@media (min-width:993px) {
								display: block;
							}
						}

						@media (max-width:992px) {
							padding: 0 15px 0 7px;
						}
					}

					.row {
						display: flex;
						flex-wrap: wrap;
					}
				}
			}




		}



		.unit-head-section {
			margin: 0 0 30px;
			border-bottom: 0px solid #ecf3fd;

			.bg-blue {
				background-color: var(--primary);
			}


		}

		h3 {
			margin-top: 40px;
			font-size: 22px;
			font-weight: normal;

			@media (max-width:992px) {
				font-size: 18px;
				margin-top: 0;
			}

			.stars {
				display: inline-block;
				margin-left: 22px;
				color: #dfb62d;
			}
		}

		.col-md-3.bg-white {
			@media (max-width:992px) {
				padding-top: 15px;
			}
		}



		.unit-price-area {
			font-family: var(--d-font);
			margin-top: 15px;

			@media (max-width:992px) {
				margin-top: 0;
				padding-right: 120px;
				position: relative;

				.btn-booking {
					position: absolute;
					right: 0;
					top: 15px;
				}
			}

			h3 {
				font-size: 40px;
				margin-bottom: 0;
				color: var(--secondary);
				line-height: 1;
				font-weight: normal;
				margin: 0;

				@media (max-width:992px) {
					font-size: 28px;
				}

				.ut-crt-price {
					color: var(--black);
				}

				.ut-crt-price {
					display: inline-block;
					margin-top: 0;
					line-height: 1;
				}
			}

			.unit-price-dt {
				font-size: 16px;
				margin-top: 5px;
				display: block;
				color: var(--grey3);

				@media (max-width:992px) {
					font-size: 14px;
				}
			}
		}


		.main-col {

			position: static;
			padding-right: 30px;

			@media (max-width:992px) {
				padding-right: 15px;
			}

			.stars {
				margin-top: 10px;
				margin-right: 0;
				color: var(--secondary);
			}

			.description {
				padding: 15px 15px 5px 15px;
			}

			.backlink {
				margin-top: 20px;
			}


			.intro {
				i {
					font-size: 26px;
					color: var(--primary2);
				}

				>div {
					text-align: center;
				}
			}

			.properties {

				.prop.missing {
					text-decoration: line-through;
					font-weight: normal;
				}

				.stars {
					display: inline-block;
					color: #dfb62d;
				}
			}

			.feedbacks {
				margin-top: 30px;

				/*blockquote {
					div {
						font-family: 'Indie Flower', cursive;
					}

				}*/
			}


		}

		.unit-prop-bl {
			position: relative;
			margin-bottom: 30px;

			.datepicker-trigger {
				display: inline-block;
				border: 2px solid #3b8295;
				/* box-shadow: 0 2px 10px rgba(0,0,0,.1); */
				border-radius: 10px;
				overflow: inherit;
				border: none;

				.asd__wrapper {
					border: none;
					overflow: inherit !important;
				}
			}

			&.showLess {
				.unit-pro-bl {
					max-height: 150px;
					overflow: hidden;
					margin-bottom: 15px;
					font-family: var(--d-thin);

					@media (max-width:992px) {
						margin-bottom: 25px;
					}
				}

				&.showFull {
					.unit-pro-bl {
						max-height: inherit;
						overflow: visible;


					}
				}
			}

			.unit-pro-bl {
				strong {
					font-size: 16px;
				}

				p {
					font-size: 15px;
					line-height: 25px;
					margin-bottom: 10px;
				}
			}

		}


		.show-hide-btn {
			color: var(--textprimary);
			font-size: 16px;
			text-transform: uppercase;
			margin-top: 20px;
			font-weight: 500;
			font-family: var(--d-bold);

			@media (max-width:992px) {
				font-size: 14px;
			}
		}


		.legend-cal {
			text-align: center;
			padding: 0;
			margin: 15px 0;

			ul {
				display: flex;
				align-items: baseline;
				flex-wrap: wrap;
				position: relative;
				padding-left: 70px;

				@media (max-width: 767px) {
					max-width: 500px;
					margin: 0 auto;
					padding: 0;
				}

				@media all (min-width: 768px) and (max-width: 1199px) {
					max-width: 600px;
					margin: 0 auto;
				}
			}

			li {
				display: inline-flex;
				align-items: center;
				min-height: 30px;
				padding-right: 15px;
				margin: 5px 0;
				text-align: left;


				@media (max-width: 767px) {

					text-align: left;
					align-items: unset;
					margin: 10px 0;
					min-height: unset;
				}

				@media all (min-width: 768px) and (max-width: 992px) {
					text-align: left;
					align-items: unset;
					min-height: auto;
					margin: 10px 0;
				}

				a {
					color: var(--textprimary);
					text-decoration: underline;

				}

				.day {
					width: 30px;
					height: 30px;
					background-color: #7e7975;
					display: inline-block;
					margin: 0 10px;
					border: 1px solid #e4e7e7;

					@media (max-width:992px) {
						width: 15px;
						height: 15px;
						min-width: 15px;
					}

					&.day-free {
						background: var(--available);
					}

					&.day-full {
						background: var(--not_available);
					}

					&.day-end {
						background: linear-gradient(135deg, var(--not_available) 50%, var(--available) 50%) !important;
					}

					&.day-start {
						background: linear-gradient(135deg, var(--available) 50%, var(--not_available) 50%) !important;
					}

					&.day-select {
						background: var(--selected);
					}

					&.day-disabled {
						background: var(--disabled);
					}

				}
			}

			li.first {
				top: 0;
				position: absolute;
				left: 0;

				@media (max-width: 767px) {
					top: 0px;
					position: relative;
				}
			}

			li.last {
				align-items: flex-start;

				span {
					max-width: 100%;
					width: 100%;
					text-align: left;

					@media all (min-width: 768px) and (max-width: 1199px) {
						max-width: 100%;
					}
				}

				@media (max-width: 767px) {
					width: 100%;
				}

				@media all (min-width: 768px) and (max-width: 1199px) {
					width: 100%;
				}
			}
		}

		.tick-li {
			display: flex;
			flex-wrap: wrap;
			margin-bottom: 20px;
			list-style: none;

			li {
				width: 33%;
				padding-left: 30px;
				padding-right: 15px;
				min-height: 30px;
				position: relative;

				@media (max-width:767px) {
					width: 48%;
				}

				@media (max-width:480px) {
					width: 100%;
				}

				&:before {
					content: "\f00c";
					font-family: "Font Awesome 5 Free";
					-webkit-font-smoothing: antialiased;
					font-weight: 600;
					display: inline-block;
					position: absolute;
					left: 0;
					top: 0;
					color: var(--primary);
				}
			}
		}

		@media (min-width:768px) {
			.info-col.noaction {
				position: absolute;
				right: 0;
				top: -41px;

				width: 33.33333333%;
				/*
				padding: 3px;
				padding-top: 10px;
				position: -webkit-sticky;
				position: sticky;
				top: 10px;
           */

				.info-col-header {
					height: 40px;
					padding: 1px;
					background-color: var(--primary);
					color: var(--white);
					text-align: center;

					h2 {
						margin-top: 10px;
						font-size: 18px;
					}
				}

				.info-col-main {
					border: 1px solid #888;
					border-bottom: none;
					width: 100%;
				}

				.subprice {
					width: 100%;
					border: 1px solid #888;
					border-top: none;
				}

				.fav-section {
					margin-top: 20px;
					border-bottom: 1px solid #888 !important;
					padding: 15px;
				}

			}
		}



		.unit-act-side {

			background-color: var(--grey);
			margin: 0 -15px 20px;
			padding: 15px 20px;

			@media (max-width:992px) {
				margin: 0 0 20px;
			}

			.btn+.btn {
				margin-top: 15px;
			}

			.btn {
				font-size: 20px;
				padding: 7px 15px;
				position: relative;
			}

		}



		.fav-section {
			background-color: var(--grey);
			margin: 0 -15px 20px;
			padding: 15px;

			@media (max-width:992px) {
				margin: 0 0 20px;
			}

			.serv-title {
				margin-bottom: 15px;
				padding-bottom: 6px;
				border-bottom: 1px solid var(--primary);
				font-size: 16px;
				color: var(--primary);
				font-family: var(--d-font);
			}

			.unit-fav-ct {
				display: flex;
				justify-content: space-between;
				margin-bottom: 20px;
				font-size: 15px;

				span {
					display: block;
				}



				.unit-img-bl {
					width: 50%;
				}
			}

		}

		.info-col {


			max-width: 800px;

			.info-col-main {

				padding: 15px 15px 1px 15px;
			}

			.subprice {

				padding: 1px 15px 15px 15px;

				.filters {}


				.book-btn {
					margin-top: 30px;
					margin-bottom: 10px;
				}

				.flow-buttons {
					button {}
				}
			}




		}

		.price-list {
			max-width: 400px;

			.subline {
				.labelcol {
					padding-left: 20px;
				}

				td {
					border-top: none;
				}
			}

		}
	}


	.subsection {
		@media (min-width:993px) {
			position: absolute;
			width: 45%;
			right: 0;
			top: 0;
			height: 100%;
		}

		position: relative;
		height: 550px;

		@media (min-width:993px) {
			&:before {
				background-image: url(RESOURCE/img/bg-overlap-map.png);
				height: 100%;
				width: 330px;
				background-size: cover;
				content: "";
				position: absolute;
				z-index: 1;
				top: 0;
			}
		}

		.unit-map {
			height: 100%;
			width: 100%;
		}
	}



	.matterport-showcase {
		text-align: center;

		iframe {
			min-height: 240px;
			margin: 16px 0;
			width: 100%;
		}

	}

	.subsection {
		.map-center-bl {
			@media (min-width:993px) {
				position: absolute;
				margin: 0 auto;
				left: 50%;
				top: 50px;
				transform: translate(-50%, 0);
			}
		}

		.map-cont-bl {
			padding: 15px;
			display: inline-block;
			max-width: 360px;

			width: 100%;
			background-color: rgba(255, 255, 255, .85);

			@media (min-width:993px) {
				padding: 30px;
				left: 0;
				top: 50px;

				z-index: 999;
			}

			strong {
				margin-bottom: 5px;
				display: block;
			}

			ul {
				li {
					width: 100%;
					display: flex;
					justify-content: space-between;
					list-style: none;
					border-bottom: 1px solid var(--grey2);

					&:last-child {
						border-bottom: none;
					}

					div {
						display: inline-block;
						text-align: right;
					}
				}
			}
		}
	}

	.v-img-gallery {
		max-width: 500px;
		position: relative;
		top: -25px;
		z-index: 1;
		width: 100%;
		display: inline-flex;
		justify-content: flex-end;

		@media (max-width:992px) {
			top: -25px;
			height: auto;
			justify-content: flex-start;
		}

		@media (max-width:380px) {
			top: -45px;
		}

		a {
			position: relative;
			display: inline-block;
			border: none;
			box-shadow: none;
			width: 33.33%;
			border-radius: 0;
			padding: 0 15px;
			margin: 15px 0;

			@media (max-width: 767px) {
				width: 33.33%;
				padding: 0 5px;
				margin: 5px 0;
			}

			@media (max-width: 575px) {
				width: 50%;
				padding: 0 10px;
				margin: 10px 0;
			}

			/* &:nth-child(n + 4) {
				display: none;
			} 

			&:nth-child(3) {
				&:before {
					z-index: 5;
					content: "+ mehr";
					position: absolute;
					color: var(--white);
					background-color: rgba(0, 0, 0, 0.5);
					width: 100%;
					height: 100%;
					text-align: center;
					display: inline-block;
					padding-top: 25%;
					font-weight: 700;
					font-size: 20px;
				}
			}
        */
		}

		.img-thumbnail {
			border: none;
			padding: 0;
			width: 100%;
			border-radius: 0;
		}
	}

	.unit-title-bx {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		justify-content: space-between;
		margin: 10px 0 20px 0;

		h2 {
			font-size: 24px;
			color: var(--secondary);
			display: flex;
			align-items: baseline;
			font-weight: 600;
			line-height: unset;
			justify-content: space-between;
			margin: 0;

			@media (max-width: 767px) {
				flex-wrap: wrap;
			}

			a {
				font-weight: 400;
				font-size: 16px;
				margin: 0 0 0 0px;
				color: var(--textprimary);
			}

			small {
				font-family: var(--d-font);
				font-size: 21px;
				color: #ccc;
				position: relative;
				top: -10px;

				@media (max-width:992px) {
					font-size: 16px;
				}
			}
		}

		span.Objekt {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			color: var(--secondary);
			font-size: 14px;
			font-weight: 400;
			padding: 0 0 0 20px;
			max-width: 210px;
			width: 100%;

			@media (max-width: 767px) {
				width: 100%;
				padding: 0;
				justify-content: flex-start;
				margin: 10px 0 0 0;
			}

			button {
				font-size: 0;
				border: none;
				background: transparent;
				display: flex;
				align-items: center;
				justify-content: center;
				color: var(--secondary);
				padding: 0;
				margin: 0 0 0 12px;

				i {
					font-size: 14px;
					color: var(--secondary);
				}

				span {
					font-size: 0;
				}
			}
		}
	}

	.unit-dt-row {
		.unit-dt-bl {
			background: #eaf0f3;

			.top-strip {
				display: flex;
				flex-wrap: wrap;
				list-style: none;
				width: 100%;
				background: #cbd5dd;
				font-size: 16px;
				padding: 15px 10px 5px 10px;

				li {
					padding: 0 15px 10px 0;
					font-size: 14px;
					display: flex;
					align-items: center;

					.unit-prop-icon {
						background-image: url(RESOURCE/img/Icon_alle.svg);
						width: 30px;
						height: 30px;
						display: block;
						background-size: 55px;
						margin: 0 7px 0 0;
					}

					.beds {
						background-position: -12px -9px;
					}

					.squaremeters {
						background-position: -12px -59px;
					}

					.hundwillkommen {
						background-position: -14px -504px;
					}

					.wlan {
						background-position: -12px -649px;
					}

					.balkon {
						background-position: -12px -304px;
					}

					.meerblick {
						background-position: -12px -256px;
					}

					.lift {
						background-position: -12px -699px;
					}

					.nonsmoking {
						background-position: -12px -600px;
					}

					.dishwasher {
						background-position: -12px -353px;
					}

					.kamin {
						background-position: -12px -553px;
					}

					.kinderbett {
						background-position: -12px -402px;
					}

					.kleinkindausstattung {
						background-position: -12px -452px;
					}

					.parking {
						background-position: -12px 241px;
					}

					.waschmaschine {
						background-position: -12px 43px;
					}


					em {
						font-style: normal;
					}
				}
			}

			h3 {}


			.row.unit-prop-accordian {
				display: flex;
				flex-wrap: wrap;
				margin: 0;
				padding: 30px 15px;

				#accordion {
					width: 100%;
					margin: 0;

					.panel-default {
						border: none;
						box-shadow: none;
						margin: 0 0 15px 0;


						.panel-heading {
							padding: 0;
						}

						.panel-title {
							margin: 0;

							a {
								display: block;
								width: 100%;
								color: #fff;
								font-size: 20px;
								font-weight: 500;
								padding: 13px 35px 13px 15px;
								min-height: 50px;
								background: var(--secondary);
								position: relative;

								@media (max-width: 574px) {
									font-size: 16px;
									padding: 15px 35px 15px 15px;
								}

								&.collapsed {
									span {
										&:before {
											display: block;
											width: 30px;
											height: 30px;
											font-family: Font Awesome\ 5 Free;
											content: "\f067";
											color: #fff;
											font-weight: 800;
											font-size: 14px;
										}
									}
								}

								span {
									position: absolute;
									right: 0;
									top: 17px;

									&:before {
										display: block;
										width: 30px;
										height: 30px;
										font-family: Font Awesome\ 5 Free;
										content: "\f068";
										color: #fff;
										font-weight: 800;
										font-size: 14px;
									}
								}
							}
						}

						.panel-body {
							.boxe {
								.bottom-list {
									padding: 0;
								}
							}
						}
					}
				}
			}
		}
	}

	.unit-pro-bl {
		margin: 40px 0 0 0;

		h2 {
			font-size: 24px;
			color: var(--textprimary);
			display: flex;
			align-items: baseline;
			font-weight: 600;
			line-height: 24px;
			justify-content: flex-start;
			margin: 10px 0 20px 0;
		}

	}

	.unit-tbl-row {
		td {
			border-top: 1px solid var(--grey2);
			padding: 15px 0;

			&:first-child {
				font-family: var(--d-font);
				width: 25%;
			}
		}
	}


	@media all and (max-width:992px) {

		.title-img {
			height: 350px;

			.v-left-gallery-btn,
			.v-right-gallery-btn {
				font-size: 80px;
			}

		}

	}

	.properties {

		display: flex;
		flex-wrap: wrap;
		padding: 30px 0 0 0;

		.prop-list {
			padding: 0 15px;
			width: 33.33%;
			margin: 0 0 30px 0;

			@media (max-width: 991px) {
				width: 50%
			}

			@media (max-width: 575px) {
				width: 100%
			}
		}

		.desc-list {
			padding: 0 15px;
			margin: 0 0 30px 0;


		}


		h3 {
			margin: 0 0 15px;
			color: var(--secondary);
			font-weight: 500;
			font-size: 20px;
			border-bottom: 1px solid var(--secondary);
			padding: 0 0 10px 0;
			font-family: var(--d-bold);
		}

		p {
			font-size: 14px;
			line-height: 20px;
			margin-bottom: 10px;
		}

		.bottom-list {
			display: flex;
			flex-wrap: wrap;
			list-style: none;
			width: 100%;
			margin: 0 0 0 0 !important;
			padding: 0;

			li {
				width: 100%;
				margin: 0 0 5px;
				color: #303537;
				position: relative;
				padding: 0 0 0 15px;
				font-size: 14px;

				@media (max-width: 575px) {
					width: 100%;
				}

				&:before {
					display: block;
					content: "\f054";
					color: #e2d69e;
					position: absolute;
					left: 0;
					top: 2px;
					font-family: "Font Awesome 5 Pro";
					font-size: 12px;
				}
			}
		}

		.prop {
			color: var(--grey2);
			font-size: 18px;
			margin-bottom: 25px;
			position: relative;
			padding-left: 30px;

			@media (max-width:992px) {
				font-size: 13px;
				margin-bottom: 8px;
			}

		}
	}




	@media (min-width:768px) and (max-width:992px) {

		.title-img {
			height: 465px;
		}

		.panorama {
			height: 465px;
		}

		.matterport-showcase iframe {
			min-height: 360px;
		}


	}

	@media (min-width:993px) and (max-width:1199px) {

		.title-img {
			height: 580px;
		}

		.panorama {
			height: 580px;
		}

		.matterport-showcase iframe {
			height: 600px;
			max-width: 1068px;
			margin: 0 auto 15px;

		}


	}


	@media screen and (min-width: 993px) {
		.matterport-showcase iframe {
			height: 600px;
			max-width: 1068px;
			margin: 0 auto 15px;

		}

	}

}


.unit-form-section {
	background-image: url(RESOURCE/img/bg-contact-unit.jpg);
	background-size: cover;
	background-repeat: no-repeat;
	padding: 115px 0 250px;
	position: relative;

	@media (max-width:992px) {
		padding: 50px 0 150px;
	}

	&:after {
		background: rgb(42, 82, 152);
		background: linear-gradient(318deg, rgba(42, 82, 152, 0.8) 0%, rgba(42, 82, 152, 0.8) 100%);
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		content: "";
	}

	.container {
		position: relative;
		z-index: 1;

		>.row {
			margin: 0;

			>.col-md-6 {
				padding: 0;
			}
		}
	}

	.grey-text {
		opacity: .25;
		color: var(--white);
	}

	h2 {
		color: var(--white);
	}

	.unit-left-cont {
		padding: 35px 50px;
		background-color: var(--secondary);
		margin-top: 100px;
		box-shadow: 2px 1px 10px rgba(0, 0, 0, .2);
		width: 101%;
		position: relative;
		left: 1%;
		z-index: 1;

		@media (max-width:992px) {
			margin-top: 55px;
			padding: 40px 30px;
			width: inherit;
			left: 0;
			margin-left: 20px;
		}

		@media (max-width:380px) {
			padding: 30px 20px;
		}


		h3 {
			font-size: 36px;
			font-weight: bold;
			color: var(--textprimary);
			margin: 0 0 30px;
			opacity: 0.5;

			@media (max-width:992px) {
				font-size: 24px;
			}
		}

		p {
			font-size: 14px;
			color: var(--textprimary);
			margin-top: 30px;
			margin-bottom: 0;
			line-height: 2;

			@media (max-width:992px) {
				font-size: 12px;
			}
		}

		.icon-bl {
			position: relative;
			padding-left: 65px;
			min-height: 50px;
			margin-bottom: 10px;
			display: inline-flex;
			align-items: center;
			font-family: var(--d-font);

			@media (max-width:380px) {
				padding-left: 50px;
				min-height: 40px;
			}



			&:hover {
				span {
					color: var(--primary);
				}

				i {
					background-color: var(--primary);
				}
			}

			i {
				position: absolute;
				background-color: #044e71;
				font-size: 18px;
				width: 50px;
				height: 50px;
				display: inline-block;
				text-align: center;
				line-height: 50px;
				color: var(--white);
				border-radius: 50%;
				left: 0;
				top: 0;

				@media (max-width:380px) {
					font-size: 16px;
					width: 40px;
					height: 40px;
					line-height: 40px;
				}
			}

			span {
				color: var(--white);
				font-size: 18px;

				@media (max-width:380px) {
					font-size: 14px;
				}
			}

		}

		.fix-contact {
			position: absolute;
			width: 50%;
			right: 0;
			top: 0;
		}
	}



	.contact-form {
		box-shadow: 2px 1px 10px rgba(0, 0, 0, .2);
		background-color: var(--white);
		padding: 35px 50px 70px;
		width: 101%;
		position: relative;
		left: -1%;

		@media (max-width:992px) {
			padding: 25px 30px;
			width: inherit;
			left: 0;
			margin-left: 40px;
			position: relative;
			top: -15px;
			padding-bottom: 60px;
			z-index: 2;
		}

		label {
			color: var(--textprimary);
			font-weight: bold;
			font-size: 14px;
			text-transform: uppercase;
			margin-bottom: 0;
			padding-bottom: 10px;

			&:before {
				top: 2px;
			}
		}

		.checkbox {
			label {
				text-transform: inherit;
				font-weight: normal;
			}

			a {
				color: var(--textprimary);
				font-size: 14px;
				position: relative;
				top: -2px;
			}
		}

		.conact-btn {
			position: absolute;
			left: 0;
			width: 100%;
			text-align: center;
			bottom: -20px;

			.btn {
				padding: 11px 30px 15px;
				box-shadow: 2px 1px 10px rgba(0, 0, 0, .2);
				font-size: 20px;

				@media (max-width:992px) {
					font-size: 18px;
				}
			}
		}

		.form-control {
			border: none;
			border-bottom: 2px solid #f2f2f2;
			text-transform: uppercase;
			margin-bottom: 30px;
			border-radius: 0;
			box-shadow: inherit;
			padding: 0;
		}
	}
}

.time-li {
	position: relative;
	overflow: hidden;
	padding-bottom: 50px;
	margin-left: 40px;

	&:before {
		content: "";
		width: 2px;
		background-color: #dddddd;
		height: 100%;
		position: absolute;
		left: 7px;
		top: 10px;

		@media (max-width:992px) {
			left: 4px;
		}
	}

	li {
		position: relative;
		list-style: none;
		margin-bottom: 20px;
		padding-left: 180px;
		margin-bottom: 22px;

		@media (max-width:992px) {
			padding-left: 140px;
		}

		&:last-child {
			margin-bottom: 0;
		}

		span {
			font-size: 18px;

			@media (max-width:992px) {
				font-size: 18px;
			}
		}

		&:before {
			content: "";
			width: 15px;
			height: 15px;
			display: inline-block;
			border-radius: 20px;
			background-color: #dddddd;
			position: absolute;
			left: 0;
			top: 9px;

			@media (max-width:992px) {
				width: 10px;
				height: 10px;
			}
		}

		strong {
			background-color: var(--primary);
			color: var(--white);
			font-size: 18px;
			width: 100px;
			padding: 6px 12px 8px;
			border-radius: 15px;
			display: inline-block;
			line-height: 1;
			text-align: center;
			position: absolute;
			left: 40px;
			font-family: var(--d-font);
			font-weight: normal;

			@media (max-width:992px) {
				font-size: 16px;
				width: 85px;
				left: 45px;
			}

			span {
				font-size: 18px;

				@media (max-width:992px) {
					font-size: 16px;
				}
			}
		}
	}
}

.facility-view {
	.unit-proposals {
		.more-units-btn {
			text-align: center;
		}
	}
}

.schenken-section {
	margin: 0 0 50px 0;

	.hide-mob {
		@media (min-width:768px) {
			display: block !important;
		}

		@media (max-width:767px) {
			display: none !important;
		}


	}

	.show-mob {
		@media (max-width:767px) {
			display: block !important;
		}

		@media (min-width:768px) {
			display: none !important;
		}
	}

	.boxes {
		background: var(--primary);
		padding: 0px 0;
		display: flex;
		flex-wrap: wrap;

		.left {
			padding: 20px;
		}

		.right {
			background: #fff;
			padding: 0;
		}

		a.img-link {
			display: block;
			width: 100%;

			img {
				width: 100%;
			}
		}

		.box {
			/* background: #bbdce7;
				text-align: center;
				display: flex;
				-webkit-box-align: center;
				align-items: center;
				-webkit-box-pack: center;
				justify-content: center;
				flex-wrap: wrap;
				width: 270px;
				height: 270px;
				-webkit-box-orient: vertical;
				-webkit-box-direction: normal;
				flex-direction: column;
				border-radius: 100%;
				padding: 10px 48px;
				margin: 0 auto; */

			background: transparent;
			border-radius: 0;
			padding: 0;
			width: auto;
			height: auto;

			@media (max-width: 767px) {
				height: auto;
			}

			img {
				display: block;
				width: 100%;
				max-width: 400px;

				@media (max-width: 767px) {
					width: 100%;
					margin: 0 auto;
				}
			}


			h3 {
				color: var(--textprimary);
			}

			@media all and (min-width: 768px) and (max-width: 991px) {
				width: 230px;
				height: 230px;
			}

			h4 {
				margin: 0 0 20px 0;

				img {
					width: 120px;
				}
			}

			h5 {
				margin: 0;
				font-size: 20px;
				font-weight: 600;
				line-height: 26px;
				color: var(--textprimary);
			}
		}
	}

	.box-content {
		@media (max-width: 767px) {
			max-width: 100%;
			width: 100%;
			margin: 0 auto;
		}
	}

	h2 {
		margin: 30px 0 15px;
		font-size: 60px;
		font-weight: 500;
		margin-top: 10px;
		line-height: 60px;
		color: var(--textprimary);

		span {
			font-style: italic;
			display: block;
			width: 100%;
		}

		@media (max-width: 992px) {
			font-size: 52px;
			line-height: 52px;
		}

		@media (max-width: 767px) {
			font-size: 42px;
			line-height: 42px;
			margin-top: 30px;
		}
	}

	p {
		font-size: 26px;
		line-height: 30px;
		margin: 0 0 30px;
		color: var(--textprimary);

		@media (max-width: 767px) {
			font-size: 20px;
		}
	}

	h6 {
		margin: 0;
		display: flex;
		align-items: center;
		padding: 0 20px 0 0;
		justify-content: flex-start;

		a {
			background: var(--textprimary);
			padding: 10px 20px;
			color: #fff;
			font-size: 16px;
			letter-spacing: 1px;
			border: 1px solid var(--textprimary);

			&:hover {
				background: var(--HoverColor);
				opacity: 1;
			}


			i {
				margin: 0 0 0 10px;
			}
		}
	}
}

.unit-virtual-tour {
	padding-bottom: 45px;

	iframe {
		width: 100%;
		height: 550px;

		@media (max-width: 993px) {
			height: 450px;
		}

		@media (max-width: 767px) {
			height: 350px;
		}

		@media (max-width: 550px) {
			height: 250px;
		}
	}
}

.unit-calender-title {
	h2 {
		margin-top: 5px;
	}

	.button {
		text-align: right;

		@media (max-width: 767px) {
			text-align: left;
		}

		a {
			letter-spacing: 1px;
			padding: 10px 20px;

			&:hover {
				background: var(--HoverColor);
			}
		}
	}
}