:root {
	--introImg: url(RESOURCE/img/banner.jpg);
	--mobileMenuImg: url(RESOURCE/img/mob-bg-1.jpg);
	--navbarbreak: 768px;
	--primary: #323c5a;
	--secondary: #191e3c;
	--textprimary: #191e3c;
	--black: #000000;
	--HoverColor: #0e2533;
	/* --d-font: 'DINRoundPro', sans-serif; */
	--d-font: 'Europa-Regular', sans-serif;
	--d-thin: 'Europa-Light', sans-serif;
	--d-bold: 'Europa-Bold', sans-serif;
	--white: #fff;
	--d-blue: #033848;
	--d-blue2: #03364d;
	--h1-font: 'Europa-Bold', sans-serif;
	--grey: #e5e5e5;
	--d-grey: #727272;
	--blue3: #025a84;
	--f-nunito: 'Nunito', sans-serif;
	--blue4: #052444;
	--li-blue: #daf0fb;
	--grey2: #7b7b7b;
	--red: #f55454;
	--grey3: #999999;
	--d-font3: 'Europa Regular', sans-serif;
	--grey4: #f9f9f9;

	/*Calendar colors*/
	--not_available: #f2c7be;
	--disabled: transparent;
	--available: transparent;
	--selected: #b2efe1;
	--selection_range: #b2efe1;
	--selected_border: #fff;
	--text_color: #000;
}