@import "common.css";
@import "setup.css";

.vo-search-html {
	min-height: 100%;
	height: 100%;
}

.form-control {
	border-radius: 0;
}



.vo-search-body {
	min-height: 100%;
	height: 100%;


	.banner-forms {
		position: relative;
		min-height: 600px;
		z-index: 3;


		@media (max-width:767px) {
			min-height: 500px;
			margin-top: 135px;
		}

		.nxt-prev-btns {
			position: relative;

			.owl-nav {
				position: absolute;
				top: calc(50% - 50px);
				left: 0;
				width: 100%;

				@media (max-width: 767px) {
					top: calc(50% - 25px);
				}



				button {
					position: absolute;
					left: 40px;
					font-size: 45px;

					@media (max-width:767px) {
						left: 15px;
						font-size: 28px;
					}

					span {
						display: inline-block;
						font-style: normal;
						font-variant: normal;
						text-rendering: auto;
						line-height: 1;
						color: transparent;

						&:before {
							color: var(--white);
							font-family: "Font Awesome 5 Pro";
							position: relative;
							left: 3px;
						}
					}

					&:hover {
						span {}
					}

					&.owl-next {
						position: absolute;
						right: 40px;
						left: initial;

						@media (max-width:767px) {
							right: 10px;
						}

						span {

							&:before {
								content: "\f054";
								left: 8px;
							}
						}
					}

					&.owl-prev {
						span {

							&:before {
								content: "\f053";

							}
						}
					}
				}
			}
		}

		.banner-slide {

			.owl-dots {
				display: none;
			}

			.owl-item {
				position: relative;
			}

			.item {
				width: 100%;
				height: 100%;
				background-size: cover;
				background-position: no-repeat;
				background-position: center;

				@media (max-width:992px) {
					/* height: calc(100vh - 80px); */
				}



				img {
					width: 100%;
					object-fit: cover;
					height: auto;

					@media (max-width: 1920px) {
						height: calc(100vh - 135px);
					}

					@media (max-width: 1140px) {
						height: 100%;
					}
				}

				&.banner1 {
					background-image: url(RESOURCE/img/slider-img-1.jpg);

					@media (max-width:992px) {
						background-image: url(RESOURCE/img/slider-mob-img-1.jpg);
					}
				}

				&.banner2 {
					background-image: url(RESOURCE/img/slider-img-2.jpg);

					@media (max-width:992px) {
						background-image: url(RESOURCE/img/slider-mob-img-2.jpg);
					}
				}

				&.banner3 {
					background-image: url(RESOURCE/img/slider-img-3.jpg);

					@media (max-width:992px) {
						background-image: url(RESOURCE/img/slider-mob-img-3.jpg);
					}
				}

				&.banner4 {
					background-image: url(RESOURCE/img/slider-img-4.jpg);

					@media (max-width:992px) {
						background-image: url(RESOURCE/img/slider-mob-img-4.jpg);
					}
				}

				&.banner5 {
					background-image: url(RESOURCE/img/slider-img-5.jpg);

					@media (max-width:992px) {
						background-image: url(RESOURCE/img/slider-mob-img-5.jpg);
					}
				}

				&.banner6 {
					background-image: url(RESOURCE/img/slider-img-6.jpg);

					@media (max-width:992px) {
						background-image: url(RESOURCE/img/slider-mob-img-6.1.jpg);
					}
				}

				&.banner7 {
					background-image: url(RESOURCE/img/slider-img-7.jpg);

					@media (max-width:992px) {
						background-image: url(RESOURCE/img/slider-img-7.jpg);
					}
				}

				&.banner8 {
					background-image: url(RESOURCE/img/slider-img-8.jpg);

					@media (max-width:992px) {
						background-image: url(RESOURCE/img/slider-mob-img-8.1.jpg);
					}
				}

				&.banner9 {
					background-image: url(RESOURCE/img/slider-img-9.jpg);

					@media (max-width:992px) {
						background-image: url(RESOURCE/img/slider-mob-img-9.jpg);
					}
				}

				&.banner10 {
					background-image: url(RESOURCE/img/slider-img-10.jpg);

					@media (max-width:992px) {
						background-image: url(RESOURCE/img/slider-mob-img-10.jpg);
					}
				}

				&.banner11 {
					background-image: url(RESOURCE/img/slider-img-11.jpg);

					@media (max-width:992px) {
						background-image: url(RESOURCE/img/slider-mob-img-11.1.jpg);
					}
				}

				&.banner12 {
					background-image: url(RESOURCE/img/slider-img-12.jpg);

					@media (max-width:992px) {
						background-image: url(RESOURCE/img/slider-mob-img-12.jpg);
					}
				}

				&.banner13 {
					background-image: url(RESOURCE/img/slider-img-13.jpg);

					@media (max-width:992px) {
						background-image: url(RESOURCE/img/slider-mob-img-13.jpg);
					}
				}

				&.banner14 {
					background-image: url(RESOURCE/img/slider-img-14.jpg);

					@media (max-width:992px) {
						background-image: url(RESOURCE/img/slider-mob-img-14.jpg);
					}
				}

				/* &.banner15 {
					background-image: url(RESOURCE/img/slider-img-15.jpg);

					@media (max-width:992px) {
						background-image: url(RESOURCE/img/slider-mob-img-15.jpg);
					}
				}

				&.banner16 {
					background-image: url(RESOURCE/img/slider-img-16.jpg);

					@media (max-width:992px) {
						background-image: url(RESOURCE/img/slider-mob-img-16.jpg);
					}
				}

				&.banner17 {
					background-image: url(RESOURCE/img/slider-img-17.jpg);

					@media (max-width:992px) {
						background-image: url(RESOURCE/img/slider-mob-img-17.jpg);
					}
				}

				&.banner18 {
					background-image: url(RESOURCE/img/slider-img-18.jpg);

					@media (max-width:992px) {
						background-image: url(RESOURCE/img/slider-mob-img-18.jpg);
					}
				}

				&.banner19 {
					background-image: url(RESOURCE/img/slider-img-19.jpg);

					@media (max-width:992px) {
						background-image: url(RESOURCE/img/slider-mob-img-19.jpg);
					}
				}

				&.banner20 {
					background-image: url(RESOURCE/img/slider-img-20.jpg);

					@media (max-width:992px) {
						background-image: url(RESOURCE/img/slider-mob-img-20.jpg);
					}
				}

				&.banner21 {
					background-image: url(RESOURCE/img/slider-img-21.jpg);

					@media (max-width:992px) {
						background-image: url(RESOURCE/img/slider-mob-img-21.jpg);
					}
				} */

			}


			.owl-dots {
				bottom: 60px;
				width: 50%;
				left: 50%;
				transform: translate(-50%, 0);
				font-size: 27px;
				text-align: center;
				position: absolute;

				@media (max-width:992px) {
					display: none;
				}

				.owl-dot {
					width: 50px;
					height: 0;
					display: inline-block;
					border-bottom: 4px solid var(--white);
					margin: 0 4px;

					&.active {
						border-bottom-color: var(--primary);
					}
				}
			}
		}
	}

	.result-header {
		padding: 0 0 15px 0;

		.srt-bl-top {

			@media (max-width: 991px) {
				flex-wrap: wrap;
			}

			.flex-align-center {
				@media (max-width:992px) {
					display: block !important;
					text-align: right;
				}

				.btn-filter {
					@media (min-width:993px) {
						border-right: 0px solid var(--grey2);
						padding-right: 5px;
						margin-right: 5px;
						margin-bottom: 0;
						top: 0;
					}

					display: inline-block;
					margin-bottom: 5px;
					color: var(--secondary);
					position: relative;
					top:-6px;

					&:hover {
						color: var(--primary);
					}
				}
			}
		}

		.search-right-filter {
			background: transparent;
			display: -webkit-box;
			display: flex;
			-webkit-box-align: center;
			align-items: center;
			flex-wrap: wrap;
			margin: 0;
			padding: 0;

			@media (max-width: 767px) {
				padding: 0;
				max-width: 440px;
				margin: 20px 0 5px 0;
				width: 100%;
			}

			@media all and (min-width: 768px) and (max-width: 991px) {
				flex-wrap: wrap;
				align-items: center;
				justify-content: center;
				max-width: 575px;
				width: 100%;
			}


			.left {
				span {
					font-size: 14px;
				}

				@media (max-width: 767px) {
					display: block;
					width: 100%;
				}

				@media all and (min-width: 768px) and (max-width: 991px) {
					width: 100%;
					padding: 15px 10px 5px 0px;
				}

				span {
					@media (max-width: 767px) {
						width: 100%;
						text-align: left;
						display: block;
					}
				}


				.sort-price-new {

					margin: 0 0 0 10px;

					@media (max-width: 767px) {
						margin: 10px 0 0 0px;
					}

					.form-control {
						width: auto;
						padding-right: 30px !important;

						@media (max-width: 767px) {
							width: 100%;
						}
					}
				}

				.flex-align-center {
					justify-content: flex-start;

					@media (max-width: 767px) {
						justify-content: center;
						margin: 0 0 10px 0;
					}

					@media all and (min-width: 768px) and (max-width: 991px) {

						align-items: center;
						display: flex !important;


					}
				}

				.btn-filter {
					color: #000;
					font-size: 14px;
					margin: 0 0 0 0;

					@media (max-width: 767px) {
						text-align: left;
						display: block;
						width: 100%;
					}

					@media all and (min-width: 768px) and (max-width: 991px) {
						margin: 10px 10px 0 0;
					}
				}
			}

			.right {
				padding: 0 0;

				@media all and (min-width: 768px) and (max-width: 991px) {

					width: 100%;

				}

				.filter-map-bl {
					justify-content: flex-end;

					@media (max-width: 767px) {
						justify-content: center;
					}

					a {
						background: #e2d69e;
						font-size: 16px;
						line-height: 48px;
						color: #171f52;
						padding: 13px 20px;

						@media (max-width: 992px) {
							background: #e2d69e;
							font-size: 16px;
							color: #171f52;
							padding: 13px 20px;
							width: 100%;
							display: block;
							text-align: center;
							text-decoration: none;
							line-height: unset;
						}

						&:hover {
							color: #e2d69e;
							background: #171f52;
						}
					}
				}
			}
		}

		.search-tocken {
			display: flex;
			flex-wrap: wrap;

			@media (max-width: 767px) {
				padding: 10px 0;
				max-width: 440px;
				margin: 20px auto 20px;
			}
		}



		@media (max-width:992px) {
			padding: 0;
		}

		.unit-count-h3 {
			color: var(--secondary);
			font-size: 21px;
			/* text-shadow: 0 0 3px rgba(0, 0, 0, .3); */
			margin: 0;
			font-weight: 500;

			@media (max-width:992px) {
				font-size: 16px;
			}
		}

		.sorting-trigger {
			cursor: pointer;
		}

		.alert {
			max-width: 400px;
			margin: 20px auto 30px;
			border: none !important;
			position: relative;

			&.alert-info {
				background-color: #e6f5fc;

				strong {
					font-weight: bold;
					color: var(--secondary);
				}

				span {
					color: #8bbdd4;
				}
			}
		}

	}

	.result-pane {
		width: 100%;

		@media (max-width:992px) {
			padding: 0 0px;
		}

		color: #565a5c;


		.a-color {
			position: relative;
			z-index: 3;
			color: #007da7;
		}



		.mysearch {
			margin-bottom: 4px;
		}
	}

	.srt-bl-top {
		border-bottom: 1px solid #e6e6e6;
		padding-bottom: 15px;
		margin-bottom: 15px;
		margin-top: 20px;
	}

	.pagination-panel {
		text-align: center;

		ul {
			li.active {
				a {
					background: var(--secondary);
					color: #fff;
				}
			}

			li {
				span {
					border: 1px solid var(--secondary) !important;
				}

				a {
					color: var(--secondary);
					border-radius: 0 !important;
				}
			}
		}
	}

}


.result-header {
	.my-search-token {
		margin: 2px;
		padding: 5px 14px 6px !important;
		display: inline-block !important;
		border: 1px solid #f0f0f0 !important;
		border-radius: 24px !important;
		background-color: #f0f0f0;
		font-size: 14px;

		.close {
			color: var(--secondary) !important;
			opacity: 1 !important;
			position: relative;
			top: -1px !important;
			margin-left: 5px;
		}
	}
}


.map-view {
	.result-pane {
		display: none;
	}
}

.map-pane {
	/* 		
     background-color: #f5f5f5;
     */
	min-height: 100vh !important;

	top: 0;
	position: relative;
	transition: all 0.25s ease 0s;

	#sidemap {
		width: 100%;
		height: 100vh;
		top: 0;
		display: block;

		@media (max-width: 767px) {

			height: 550px;
			left: 0;
			position: relative !important;
		}

		@media (min-width:768px) {
			height: 500px !important;
			top: 0;
		}

		@media (min-width:993px) {
			height: calc(100vh - 460px);
		}



		&.affix {
			@media (min-width:768px) {
				height: 100vh;
				top: 0;
				position: -webkit-sticky;
				position: sticky;
			}

			@media (min-width:993px) {

				height: 500px !important;
				top: 20px;
			}


		}

		>.flex {
			width: 100%;
			height: 100%;
		}
	}

	.header {
		display: none;
		padding: 5px;
		background-color: white;
		text-align: right;

		.close {
			font-size: 46px;
			float: none;
			font-weight: normal;
		}
	}

	&.affix {


		@media (min-width:768px) {
			width: 50%;
			right: 0;
			position: fixed;
			margin-top: 0 !important;
			top: 70px;
			height: calc(100vh - 150px);
		}

		@media (max-width: 1500px) and (min-width: 768px) {
			width: 40%;
		}


	}


}

.map-unit-preview {
	color: #565a5c;
	text-align: center;

	a {
		color: inherit;
	}

	.prices {
		font-size: 14px;
		margin-top: 6px;
		font-weight: 400;
	}

	.preview-img {
		min-height: 150px;

		img {
			max-width: 225px;
		}
	}

	.lazy-preview-placeholder {
		height: 350px;
		width: 240px;
	}

}


.results,
.spinner-panel,
.more-btn-panel {
	background-color: #f5f5f5;
}

.more-btn-panel {
	padding-bottom: 40px;
}

.spinner-panel {
	padding-bottom: 20px;
}

.filter-map-bl {
	width: 100%;

	@media (min-width:993px) {}

	.btn-filter {
		color: var(--white);
		border: none;
		line-height: 20px;
		background-color: transparent;

		font-family: var(--d-font);

		@media (max-width:992px) {
			color: var(--black);
			font-size: 13px;
			text-decoration: underline;

		}
	}

	.map-btn {
		padding-left: 0;
		margin-left: 0;
		border-left: 0px solid #fff;

		@media (max-width:992px) {
			border-left: 0px solid var(--grey2);
			width: 100%;
		}

		a {
			color: var(--white);
			font-size: 14px;
			font-family: var(--d-font);

			@media (max-width:992px) {
				color: var(--black);
				font-size: 13px;
				text-decoration: underline;
			}
		}
	}
}

.results {
	background-color: var(--white);

	@media (max-width:992px) {
		padding: 0;
	}

	@media (min-width:993px) {
		padding: 0 0px !important;
	}

	.no-search-results {
		margin-top: 20px;
		margin-bottom: 27px;
		width: 100%;
		text-align: center;
	}

	.altheading {
		color: red;
	}

	h4 {
		margin-top: 0;
		color: var(--black);
	}



	.srow {
		position: relative;
		cursor: pointer;

		@media (min-width:993px) {
			margin-left: 0px;
			padding: 0;
			box-shadow: none;
			border-radius: 0;
			margin-bottom: 35px;
			border: none !important;
		}

		@media (max-width:992px) {
			margin: 0 auto 40px;
			max-width: 440px;
		}

		&:hover {
			border-color: var(--secondary);
		}

		.map-marker {
			display: inline-block;
			width: 30px;
			height: 50px;
			text-align: center;

			.fa-map-marker-alt {
				position: relative;
				top: 12px;
				cursor: pointer;
				font-size: 24px;
				color: var(--primary);
			}

			.fa-map-marker-alt:hover,
			.fa-map-marker-alt.selected {
				color: #565a5c;
			}
		}

		.img-col {
			box-shadow: none;
			border-radius: 0;
			overflow: hidden;
			position: relative;
			max-height: 100%;

			.owl-carousel {
				overflow: hidden;
				height: 100%;

				.owl-stage-outer {
					height: 100%;
				}

				.owl-stage {
					height: 100%;
				}

				.owl-item {
					height: 100%;
				}

				.owl-nav {
					button {
						width: 50px;
						height: auto;
						bottom: 0;
						top: 0;
						position: absolute;
						border-radius: 0;
						font-size: 0;
						cursor: pointer;
						transition: all 0.25s ease 0s;
						transform: none;
						color: var(--white);
						font-size: 46px;
						line-height: 8px;
						text-align: center;
					}

					.owl-prev {
						/* background: -moz-linear-gradient(left, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%);
						background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%);
						background: linear-gradient(to right, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%);
						filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#66000000', endColorstr='#00000000', GradientType=1); */
						left: 0;

						span {
							height: 20px;
							display: block;
						}

					}

					.owl-next {
						right: 0;

						/* background: -moz-linear-gradient(left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
						background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
						background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
						filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#66000000', GradientType=1); */
						span {
							height: 20px;
							display: block;
						}
					}
				}

				.owl-dots {
					position: absolute;
					width: 70%;
					bottom: 10px;
					padding: 0;
					margin: 0;
					list-style: none;
					text-align: center;
					line-height: 1;
					left: 50%;
					transform: translateX(-50%);

					.owl-dot {
						background-color: #fff;
						width: 6px !important;
						height: 6px !important;
						opacity: .40;
						margin: 0 3px !important;
						border-radius: 50%;

						&.active {
							opacity: 1;
						}
					}
				}
			}

			@media (min-width:993px) {
				left: 0;
				top: 0;
			}

			.fav-element {
				position: absolute;
				width: 40px;
				color: #1c4867;
				color: var(--primary);
				height: 40px;
				right: 10px;
				top: 10px;
				display: -webkit-inline-box;
				display: inline-flex;
				-webkit-box-align: center;
				align-items: center;
				-webkit-box-pack: center;
				justify-content: center;
				font-size: 24px;
				-webkit-transition: all .4s;
				transition: all .4s;
				cursor: pointer;
				z-index: 2;
			}

			.img-content {

				.full-img {
					width: 100%;
					height: auto;

				}


				.fav-icon {
					position: absolute;
					cursor: pointer;
					right: 7px;
					top: 5px;
					color: #fff;
					margin: 3px;
					font-size: 18px;
					width: 39px;
					text-align: center;
					height: 33px;

					line-height: 31px;
					z-index: 1;

					@media (min-width:993px) {
						background-color: rgba(0, 0, 0, .3);
						border-radius: 5px;
					}
				}


			}


		}

		.info-col {
			position: relative;
			padding: 0 15px;

			.show-mob {
				.unit-distance {
					background-color: var(--white);
					border-radius: 15px;
					position: absolute;
					top: -55px;

					@media (max-width:992px) {
						top: -36px;
					}
				}
			}

			@media (max-width:992px) {
				background: rgb(203 213 221/30%) !important;
				padding: 15px;
			}



			.region-bl {
				display: inline-flex;
				align-items: center;
				position: absolute;
				top: 5px;
				right: 10px;

				span {
					color: var(--black);
					opacity: .5;
					font-size: 13px;
				}
			}

			.text-truncate {
				color: var(--secondary);
				font-size: 13px;
				font-family: var(--d-font);
				margin-bottom: 20px;
			}

			.flex-align-center {
				padding-bottom: 14px;
				border-bottom: 1px solid #f2f2f2;
				margin-bottom: 14px;
			}

			.search-result-properties {
				margin: 0 !Important;

				li {
					display: inline-block;
					list-style: none;
					text-align: center;
					float: left;
					padding: 0 0 0 10px;

					@media (max-width:360px) {
						padding-left: 5px;
					}

					i {
						font-size: 16px;
						color: var(--d-grey);
						font-style: normal;
						width: 28px;
						height: 28px;
						background-color: var(--grey);
						border-radius: 50%;
						display: flex;
						align-items: center;
						justify-content: center;
						position: relative;

						@media (max-width:360px) {
							font-size: 14px;
							width: 26px;
							height: 26px;
						}

						&.cross {
							&:after {
								width: 1.5px;
								height: 100%;
								position: absolute;
								content: "";
								background-color: var(--d-grey);
								-webkit-transform: rotate(-45deg);
								transform: rotate(-45deg);
							}
						}
					}
				}
			}

			.unit-title {
				display: flex;
				justify-content: space-between;
				flex-direction: row;
				flex-wrap: nowrap;

				h4 {
					font-size: 18px;
					color: var(--black);
					/*display: flex;
					align-items: baseline;
              justify-content: space-between;
					flex-wrap: wrap;
					width: 100%;*/
					font-weight: 600;
					line-height: 24px;
					width: auto;


					@media (max-width: 767px) {
						font-size: 20px;
						line-height: 26px;
						width: 100%;
					}

					small {
						width: 100%;
						font-size: 13px;
						padding: 5px 0;
						color: var(--secondary);
					}
				}

				a {
					color: var(--secondary);
					font-family: var(--d-bold);
				}

				span {
					max-width: 200px;
					width: 100%;
					font-size: 12px;
					font-weight: 400;
					display: flex;
					align-items: center;
					justify-content: flex-end;
					padding: 0 0 0 10px;
					position: relative;
					top: -2px;

					@media (max-width:767px) {
						justify-content: flex-start;
						padding: 0;
						top: 0;
					}

					.fav-icon {
						margin: 0 0 0 10px;
					}
				}

				@media (max-width:992px) {
					font-size: 16px;
				}

				@media (max-width:767px) {
					flex-wrap: wrap;
					margin: 0 0 20px 0;
				}
			}

			.unit-price-rat {
				display: -webkit-box;
				display: flex;
				flex-wrap: wrap;
				-webkit-box-pack: justify;
				justify-content: space-between;
				-webkit-box-align: center;
				align-items: center;
				flex-wrap: wrap;

				.prices {

					width: 100%;
					padding: 15px 0;

					.offer-price-box {
						display: flex;
						align-items: center;
						flex-wrap: wrap;

						.discount-price {
							margin: 0 5px 0 0;

							@media (max-width: 575px) {
								font-size: 16px;
							}
						}

						.pro {
							margin: 0 0 0 5px;
						}

					}
				}


				.unit-link {
					text-transform: uppercase;
					color: var(--primary);
					font-size: 13px;
					font-weight: 700;
					position: relative;
					top: 2px;
				}
			}

			.alternatives {

				margin: 20px 0 12px 0;
				font-size: 14px;

				.altheading {
					color: var(--red);
					font-family: var(--d-font);
					margin-bottom: 10px;
					display: block;
				}

				.li-bullet {

					li {
						list-style: none;
						background-color: #cbd5dd;
						padding: 10px 15px;
						color: var(--black);
						opacity: 1;
						border: 1px solid #cfebf9;
						font-family: var(--d-font);
						border-bottom: none;

						&:first-child {
							border-radius: 5px 5px 0 0;
						}

						&:last-child {
							border-radius: 0 0 0px 0px;
							border-bottom: 1px solid #cfebf9;
						}

						a {
							color: var(--secondary);
						}
					}
				}
			}

			.offer-price-box {
				.main-price {
					strong {
						text-decoration: line-through;
					}
				}

				.discount-price[title="Last-Minute"] {
					strong {
						color: var(--red);
					}
				}

				>.discount-price {
					font-size: 21px;
					font-weight: 600;
					color: var(--secondary);
				}

				.popover {
					@media (max-width: 374px) {
						max-width: 218px;
						margin-left: -79px;
					}

					ul {
						li {
							&:before {
								content: "\f00c";
								font-family: Font Awesome\ 5 Pro;
								font-weight: 300;
							}
						}
					}

					@media all (min-width:375px) and (max-width: 767px) {
						margin-left: -79px;
						max-width: 218px;
					}

					@media all (min-width: 992px) and (max-width: 1199px) {
						margin-left: -95px;
						max-width: 230px;
					}
				}
			}

			.offer-bl-srch {
				text-align: center;
				position: relative;
				z-index: 1;

				.offer-box {
					padding-top: 12px;
					border-top: 1px solid #f2f2f2;
					margin-top: 0;
					margin-bottom: 12px;
					display: block;

					@media (max-width: 992px) {
						border-top: 1px solid rgb(0 0 0 / 10%);
					}
				}


				.popover {
					font-size: 12px;
					background-color: #fff6df;
					border: 1px solid #febb02 !important;
					z-index: 2;

					.popover-title {
						text-transform: capitalize;
						padding: 0 0 10px 0;
						margin: 0;
						font-size: 12px !important;
						background-color: transparent;
						border-bottom: none;
						border-radius: 5px 5px 0 0;
						font-family: var(--d-font);
						font-weight: 400;
						margin-bottom: 10px;
						padding-bottom: 4px;
						border-bottom: 1px solid #febb02;
						color: #47403a;
					}

					.arrow {
						&:after {
							border-top-color: #febb02 !important;
							border-bottom-color: #febb02 !important;
						}
					}
				}

				.offer-box {
					a {
						background-image: url(RESOURCE/img/offer-bg.png);
						position: relative;
						padding: 0;
						display: inline-flex;
						align-items: center;
						background-repeat: no-repeat;
						background-position: center;
						max-width: 270px;
						background-size: 270px;
						height: 38px;
						display: flex;
						align-items: center;
						justify-content: center;
						margin: 0 auto;
						color: var(--secondary);
						font-size: 14px;

						@media (max-width: 370px) {
							background-size: 260px;
						}

					}

					.offer-text {
						background-image: url(RESOURCE/img/bg-offer-text.png);
						background-repeat: no-repeat;
						background-position: center;
						text-transform: uppercase;
						font-size: 14px;
						width: 116px;
						height: 38px;
						display: flex;
						align-items: center;
						justify-content: center;
						text-transform: uppercase;
						font-weight: bold;
						background-size: 116px;
						color: var(--black);
					}

					ul {
						li {}
					}
				}
			}



			.rentRate {
				.minrent {
					margin: 0;

					strong {
						font-size: 18px;
						font-weight: 600;
						color: var(--secondary);

						@media (max-width:992px) {
							font-size: 18px;
						}
					}

					.price-bl {
						display: flex;
						align-items: center;
						font-size: 14px;
						color: var(--secondary);
						font-weight: 500;
						flex-wrap: wrap;


						i.fa {
							color: #7f7f7f;
							font-size: 12px;
							margin-left: 5px;
						}

						strong {
							font-family: var(--d-bold);
						}

						.price-start {
							font-size: 18px;
							font-weight: 600;
							color: var(--secondary);
							margin: 0 5px 0 0;
							font-family: var(--d-bold);
						}

						.pro {
							width: 100%;
							display: block;
							padding: 5px 0 0 0;
						}
					}

					span {
						font-size: 12px;
						color: #7e7e7e;
						display: block;
						line-height: 18px;
					}
				}

				a {
					color: var(--black);
					position: relative;
					z-index: 1;
				}

				.price-info {

					i {
						color: #7f7f7f;
						font-size: 12px;
						margin-left: 5px;
					}
				}
			}

			.unit-dt-right {
				display: flex;
				align-items: center;
			}

			.unit-distance {
				padding: 0 15px;
				font-weight: bold;
				right: 0;
				top: 0;
				font-size: 12px;
				line-height: 30px;
				color: rgba(0, 0, 0, .7);
				background-color: #e6f2f7;
				border-radius: 15px;
			}

			.stars {
				position: absolute;
				right: 0;
				bottom: 0;
			}

			.unit-place {
				font-size: 16px;
				font-family: var(--d-font);


				li {

					color: #555;
					width: 100%;
					padding: 0 0 0 10px !important;
					font-size: 14px;
					margin: 0 0 5px 0;
					position: relative;
					display: flex;

					span {
						display: inline-block;
						/*float: left;*/
					}

					@media (max-width:992px) {
						display: inline-block;
					}

					&:before {
						display: block;
						content: "";
						width: 1px;
						height: 12px;
						background: #555;
						position: absolute;
						left: 0;
						top: 4px;
					}

					@media (max-width:360px) {
						font-size: 14px;
						padding-left: 3px;
					}

					&:first-child {
						padding-left: 0;
						margin-left: 0;

					}

					i {
						font-size: 18px;
						opacity: .2;
						margin-right: 3px;

						@media (max-width:360px) {
							font-size: 15px;
							margin-right: 2px;
						}
					}
				}
			}

			.prices {
				.price {
					font-size: 20px;
				}
			}

			.search-result-properties {
				margin-top: 5px;
				margin-bottom: 15px;
			}
		}

		.search-unit-bluebar {
			align-items: center;
			justify-content: space-between;
			padding: 5px 15px;
			width: 100%;
			background: #bec8d2;
			display: flex;
			position: relative;
			flex-wrap: unset;



			@media (max-width: 992px) {
				flex-wrap: wrap;
				padding: 10px;
			}




			.units {
				width: 70%;

				@media (max-width: 992px) {
					width: 100%;
				}

				ul {
					display: flex;
					align-items: center;
					list-style: none;
					flex-wrap: wrap;

					li {
						margin: 0 5px 0 0;

						.icon-bg {
							background-image: url(RESOURCE/img/Icon_alle.svg);
							width: 30px;
							height: 30px;
							display: block;
							background-size: 55px;
						}

						.balcony {
							background-position: -12px -304px;
						}

						.sauna {
							background-position: -12px 142px;
						}

						.seeview {
							background-position: -12px -256px;
						}

						.pets {
							background-position: -12px -501px;
						}

						.wifi {
							background-position: -12px -646px;
						}

						.lift {
							background-position: -12px -699px;
						}

						.nonsmoking {
							background-position: -12px -600px;
						}

						.geschirrspuler {
							background-position: -12px -353px;
						}

						.kamin {
							background-position: -12px -550px;
						}

						.kinderbett {
							background-position: -12px -402px;
						}

						.kleinkindausstattung {
							background-position: -12px -452px;
						}

						.parking {
							background-position: -12px 241px;
						}

						.waschmaschine {
							background-position: -12px 43px;
						}

						.suna {
							background-position: -12px 141px;
						}

						img {
							width: 18px;
						}
					}
				}
			}

			.stars {
				width: 33.33%;

				@media (max-width: 992px) {
					width: 100%;
					padding: 7px 0;
				}
			}

			.mehr-btn-area {
				width: 33.33%;
				display: flex;
				justify-content: flex-end;
				margin-top: 0;

				@media (max-width: 992px) {
					width: 100%;

				}

				@media all and (min-width: 768px) and (max-width: 1199px) {
					display: flex;
					align-items: center;
					justify-content: flex-end;
				}

				a {
					background: var(--primary);
					padding: 5px 10px;
					color: #fff;
					font-size: 12px;
					letter-spacing: 1px;
					border: 1px solid var(--white);
					line-height: 18px;

					i {
						color: #fff;
						margin: 0 0 0 5px;

					}

					&:hover {
						border: 1px solid var(--white);
						color: #fff;
						background: var(--HoverColor);
					}

					&:hover i {
						color: var(--white);
					}
				}
			}
		}
	}
}

.sort-price {
	position: relative;
	padding-right: 18px;

	&:before,
	&:after {
		content: "";
		width: 0;
		height: 0;
		position: absolute;
		border-left: 5px solid transparent;
		border-right: 5px solid transparent;
	}

	&:before {
		border-bottom: 5px solid #ccc;
		right: 0;
		top: 4px;
	}

	&:after {
		border-top: 5px solid #ccc;
		right: 0;
		top: 13px;
	}

	.sorting-trigger {
		color: var(--secondary);
		font-size: 14px;
	}

	span {
		color: #cccccc;
		font-size: 14px;
	}
}

.search-cont {
	padding: 0 35px;
	margin-top: 30px;

	@media (max-width:992px) {
		padding: 0;
	}

	h2 {
		font-size: 28px;
	}

	p {
		color: var(--grey3);
		font-size: 16px;
		line-height: 1.5;
	}

	.btn-groups {
		.btn {
			&.btn-default {
				padding: 5px 14px 6px !important;
				display: inline-block !important;
				border: 1px solid #f0f0f0 !important;
				border-radius: 24px !important;
				background-color: #f0f0f0;
				font-size: 14px;
				margin: 0 10px 10px 0;

				&:hover {
					background-color: var(--primary);
					border-color: var(--primary);
				}
			}
		}
	}
}


.search-result-properties {
	display: inline-block;

	>div {
		float: left;
		text-align: center;
		padding: 3px;

		border-right: 1px solid #ccc;

		:last-child {
			font-weight: bold;
		}
	}

	>div:last-child {
		border: none;
	}

}



.search-bar {
	padding: 5px 10px;
	position: relative;

	@media (max-width:992px) {
		z-index: 40;
	}

	.layout-column {
		@media (min-width:993px) {
			margin-top: 70px;
		}
	}

	.daterange {
		display: inline-block;
		width: 100%;

		@media (max-width:992px) {
			display: block;
		}
	}

	@media (max-width:768px) {
		.sm-full {
			display: block;

		}
	}
}

@media (max-width:767px) {

	.list-view {
		.map-pane {
			display: none;
		}
	}

	.map-view {
		.map-pane {
			@mixin flex;
		}
	}


	.map-pane.full-screen {
		display: flex;
		position: fixed !important;
		left: 0px !important;
		right: 0px !important;
		bottom: 0px !important;
		top: 0px !important;
		z-index: 50;

		.header {
			display: block;
		}

	}

	.pagination-panel {
		display: none;
	}

}

@media all and (min-width: 768px) and (max-width: 1499px) {
	.list-view {
		.map-pane {
			display: none;
		}
	}
}

@media (max-width:1500px) and (min-width:768px) {
	.result-pane {
		max-width: 100% !important;

	}

	.map-pane {
		@mixin flex;

	}
}

@media (min-width:993px) {
	.map-pane {
		@mixin flex;

	}

	.result-pane {
		width: 100%;
		max-width: 100%;
		min-height: 750px;

		.more-btn-panel {
			display: none;
		}


	}


	.results {


		.srow {
			display: flex;
			-webkit-box-orient: horizontal;
			-webkit-box-direction: normal;
			flex-wrap: wrap;
			flex-direction: unset;


			.info-col {
				width: 60%;
				position: relative;
				background: transparent !important;

				.price-anchor {
					position: absolute;
					left: 0;
					bottom: 0;
					width: 100%;
					height: 80%;
					z-index: 1;
				}
			}

			.img-col {
				@mixin flex;
				position: relative;

				.img-content {
					position: absolute;
					@mixin size100;

					.item {
						height: 100%;
					}


					.full-img {
						height: 100%;
						object-fit: cover;

					}
				}

			}

		}
	}
}

.map-unit-preview {
	.region-bl {
		display: inline-flex;
		align-items: center;
		position: absolute;
		top: 5px;
		right: 10px;

		span {
			color: var(--black);
			opacity: .5;
			font-size: 13px;
		}
	}

	.unit-distance {
		padding: 0 15px;
		font-weight: 700;
		right: 10px;
		top: 10px;
		font-size: 12px;
		line-height: 30px;
		color: rgba(0, 0, 0, .7);
		background-color: #e6f2f7;
		border-radius: 15px;
		position: absolute;
	}

	.text-truncate {
		color: var(--secondary);
		font-size: 13px;
		font-family: var(--d-font);
		margin-bottom: 25px;
	}

	.flex-align-center {
		padding-bottom: 14px;
		border-bottom: 1px solid #f2f2f2;
		margin-bottom: 14px;
	}

	.search-result-properties {
		margin: 0 !Important;

		li {
			display: inline-block;
			list-style: none;
			text-align: center;
			float: left;
			padding: 0 0 0 10px;

			i {
				font-size: 16px;
				color: var(--d-grey);
				font-style: normal;
				width: 28px;
				height: 28px;
				background-color: var(--grey);
				border-radius: 50%;
				display: flex;
				align-items: center;
				justify-content: center;
				position: relative;

				&.cross {
					&:after {
						width: 1.5px;
						height: 100%;
						position: absolute;
						content: "";
						background-color: var(--d-grey);
						-webkit-transform: rotate(-45deg);
						transform: rotate(-45deg);
					}
				}

			}
		}
	}

	.unit-title {
		font-size: 18px;
		color: var(--black);
		margin: 30px 0 0 0;
		text-align: center;
		line-height: 24px;
	}

	.price-bl {
		font-size: 18px;
		color: #000;

		strong {
			font-size: 18px;
			font-weight: 600;
			color: var(--black);
		}
	}

	.unit-place {
		font-size: 15px;
		font-family: var(--d-font);
		text-align: left;

		li {
			display: inline-block;
			color: #555;
			width: 100%;
			padding: 0 0 0 10px !important;
			font-size: 14px;
			margin: 0 0 5px;
			position: relative;

			&:before {
				display: block;
				content: "";
				width: 1px;
				height: 12px;
				background: #555;
				position: absolute;
				left: 0;
				top: 4px;
			}

			&:first-child {
				padding-left: 0;
				margin-left: 0;

			}

			i {
				font-size: 15px;
				opacity: .2;
				margin-right: 4px;
			}
		}
	}
}



.search-bar {

	padding: 0 0;
	margin-top: 0;
	text-align: center;

	@media (max-width:992px) {
		margin-top: 0;
	}

	.panel-title,
	label {
		text-transform: uppercase;
		font-size: 15px;
	}

	.mob-input-bl {
		margin-left: 50px;
		position: relative;
		margin-right: 20px;
		color: var(--grey2);



		span {
			box-shadow: 0 0 10px rgba(0, 0, 0, .2);
			background-color: var(--white);
			padding: 13px 15px;
			border-radius: 3px;
			font-size: 14px;
			display: block;
			padding-right: 5px;

			@media (max-width:340px) {
				font-size: 13px;
			}
		}

		.icon-left {
			position: absolute;
			left: -30px;
			font-size: 24px;
			top: 8px;
		}
	}

	.searchbar {
		position: relative;
		text-align: left;
		display: block;

		@media (max-width: 767px) {
			padding: 20px 0;
		}

		.checkbox.checkbox-success label {
			vertical-align: text-bottom !important;
			line-height: 16px;
		}

		@media (min-width:993px) {
			left: 0;
			display: inline-block;
			border-radius: 0;
			margin: 0 auto;
			background-color: var(--white);
			padding-left: 0;
			box-shadow: none;
		}

		.close-filter {
			@media (max-width:992px) {
				position: fixed;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				background-color: rgba(0, 0, 0, .5);
				z-index: 10;
			}
		}

		.search-form-area {
			display: flex;
			-webkit-box-align: center;
			align-items: center;
			-webkit-box-pack: justify;
			justify-content: space-between;
			flex-wrap: wrap;
			background: #18335f;
			padding: 15px;

			h3.suche-title {
				margin: 0 0 20px 0;
				width: 100%;
				color: #bba74b;
				font-size: 18px;
				border-bottom: 1px solid #bba74b;
				padding: 0 0 10px 0;
				text-align: right;
			}

			@media (max-width:992px) {
				margin: 0px 0 0 0;
				position: relative;
				z-index: 50;
				box-shadow: 0 0 15px rgb(0 0 0 / 20%);
			}

			.form-flex {
				padding: 0 0;
				width: 100% !important;

				.box {
					.guestbox {
						.gb-td {
							.btn-default {
								line-height: 23px;
								padding: 0 0 4px;
								display: flex;
								align-items: center;
								justify-content: center;
								font-family: var(--d-font);
							}
						}
					}
				}

				@media (max-width:992px) {
					width: 100%;
				}

				.form-flex-inner {
					margin-bottom: 15px;
				}

				.form-bx-area {
					padding-right: 0;
					position: relative;
					width: 100%;

					&:first-child {
						@media (max-width:1100px) {}
					}

					@media (max-width:992px) {
						/* padding-right: 0;
						border-bottom: 2px solid var(--grey);
						padding-bottom: 3px;
						margin-bottom: 15px; */
					}

					.close {
						@media (max-width:992px) {
							position: absolute;
							width: 25px;
							height: 25px;
							border-radius: 50%;
							text-align: center;
							color: var(--black);
							font-size: 15px;
							box-shadow: 0 0 15px rgba(0, 0, 0, .2);
						}
					}

					.search-popup-trigger {
						@media (max-width:1130px) and (min-width:993px) {
							max-width: 100%;
						}
					}

					.datepicker-trigger {
						>button {
							min-width: 100%;
							text-align: left;

							@media (max-width:1130px) {
								min-width: 100%;
							}
						}
					}
				}

				.form-control {
					background-color: var(--white);
					border-radius: 0;
					width: 100%;
					height: inherit;
					box-shadow: inherit;
					font-family: var(--d-font);
					border: none;
					font-weight: 500;
					padding-right: 0;
					font-size: 14px;
					height: 40px !important;

					@media (max-width:992px) {
						height: 38px;
						padding-left: 10px;
						top: 5px;
					}
				}

				.far,
				.fas {
					position: absolute;
					right: -6px;
					top: 12px;
					color: var(--black);
					z-index: 2;
					font-size: 20px;
					pointer-events: none;

					@media (max-width:992px) {
						/* right: inherit;
						left: 0;
						top: 5px;
						font-size: 28px;
						color: var(--grey); */
					}

					&.shadow {
						width: 40px;
						text-align: center;
						right: 0;
						height: 40px;
						top: 0;
						display: flex;
						align-items: center;
						justify-content: center;
						border-left: 1px solid rgb(0 0 0 / 30%);
						background: #fff !important;
						font-size: 22px;
						color: #000;

						@media (max-width:992px) {}
					}

					&.fa-chevron-down {
						font-size: 15px !important;
					}
				}
			}

			.filter-clear {
				margin: 0 0 10px 0;
				text-align: right;
				font-size: 14px;
				color: #e2d69e;

				a {
					color: #e2d69e;
					text-decoration: underline;

					&:hover {
						color: #F64852;
					}
				}

			}

			.form-btn {
				width: 100%;

				.btn {
					-webkit-box-align: center;
					align-items: center;
					right: 0px;
					display: -webkit-box;
					display: flex;
					-webkit-box-pack: center;
					justify-content: center;
					font-size: 15px;
					border-radius: 0;
					font-family: var(--d-font);
					font-weight: 500;
					height: 40px;
					line-height: 1;
					position: relative;
					padding: 0;

					i {
						margin-left: 8px;
						color: #fff;
						font-size: 18px;
						margin-right: 10px;

						@media (max-width:992px) {
							font-size: 16px;
							margin-right: 6px;
						}
					}

					&:hover {
						background-color: var(--primary) !important;
						opacity: 0.8;
					}

					@media (max-width:992px) {
						font-size: 13px;
						position: relative;
						height: 35px;
						padding-right: 15px;
						right: 0;
					}
				}
			}
		}

		.side-checkbox {
			background-color: #F6F3E2;
			padding: 40px 15px 15px 15px;
		}
	}
}

.search-page-main {

	.search-form-area {
		.form-flex.region-box {
			@media (max-width: 767px) {
				width: 1000% !important;
			}
		}
	}

	.left {


		@media (min-width: 993px) {
			width: auto;
		}

		@media (min-width: 1199px) {
			width: auto;
		}

		@media all and (min-width: 768px) and (max-width: 991px) {
			width: auto;
		}

		@media (max-width: 374px) {
			padding: 0;
		}
	}

	.right {
		@media (min-width: 993px) {
			width: 100%;
		}

		@media (min-width: 1199px) {
			width: 100%;
		}

		@media all and (min-width: 768px) and (max-width: 991px) {
			width: 100%;
		}

		@media (max-width: 767px) {
			padding: 0 0;
		}


	}
}

.popover {
	background-color: #fff6df;
}

.vo-search-body {
	.mainHeader {
		@media (max-width:992px) {
			/* display: none !Important; */
		}
	}
}

.unit-headline {
	margin-top: 3px;
	color: var(--textprimary);
	font-size: 14px;
	line-height: 1.3;
	margin-bottom: 10px;
}