.payment-view {
	display: flex;
	padding: 100px 0 100px 0;
	flex-wrap: wrap;
	flex-direction: column;
	justify-content: center;

	h1 {
		font-size: 24px;
		font-weight: 600;
	}

	.center-block {
		margin: 0 0 20px 0;

		.btn-primary {
			background: var(--secondary);
			padding: 10px 20px;
			color: #fff;
			font-size: 16px;
			letter-spacing: 1px;
			border: 1px solid var(--secondary);
			text-transform: uppercase;
			border-radius: 0;
			line-height: 25px;

			&:hover {
				background: #0e2533;
				opacity: 1;
			}

			@meida (max-width: 480px) {
				padding: 10px 10px;
			}
		}
	}
}