.notfound-banner {

	img {
		width: 100%;
		object-fit: cover;
		height: auto;

		@media (max-width: 1920px) {
			height: calc(100vh - 135px);
		}

		@media (max-width: 1140px) {
			height: 100%;
		}
	}
}

.static-404 {
	height: auto;
	display: flex;
	padding: 70px 0;

	.container {
		.box {
			padding: 0;
			background-color: #F6F3E2;
			display: flex;
			flex-wrap: wrap;

			.left {
				padding: 50px 30px;

				@media (max-width: 767px) {
					padding: 30px 30px 10px 30px;
				}
			}

			.right {
				padding-top: 50px;
				padding-bottom: 50px;

				img {
					position: relative;
					z-index: 1;
				}

				.bg-box {
					background: #7f7f7f;
					width: 70%;
					height: 100%;
					position: absolute;
					top: 0;
					right: 0;
				}
			}

			ul {
				list-style: none;
				padding: 0;
				margin: 0 0 30px 0;

				li {

					position: relative;
					margin: 0 0 10px 0;
					padding: 0 0 0 15px;
					color: var(--secondary);

					&:before {
						display: block;
						content: "\f054";
						color: var(--secondary);
						position: absolute;
						left: 0;
						top: 2px;
						font-family: Font Awesome\ 5 Pro;
						font-size: 12px;
					}

					a {
						color: var(--secondary);
						font-size: 16px;
						text-decoration: underline !important;

						@media (max-width: 991px) {
							font-size: 14px;
						}

						&:hover {
							color: #E2D69E;
						}
					}
				}

			}


		}

		h3 {
			width: 100%;
			text-align: center;
			align-items: center;
			justify-content: center;
			font-size: 130px;
			color: var(--primary);
			margin-top: 0;
		}

		h1 {
			color: var(--secondary);
			font-size: 24px;
			font-weight: 600;
			text-align: center;
			margin: 0 0 30px 0;
			width: 100%;
			text-align: center;
		}

		ul {
			padding: 0;
			display: flex;
			flex-wrap: wrap;
			list-style: none;

			li {
				width: 100%;
				text-align: center;
				margin: 5px 0;
				display: flex;
				align-items: center;
				justify-content: center;

				a {
					display: inline-block;
					max-width: 145px;
					width: 100%;
					font-size: 15px;
					font-weight: 500;
					text-decoration: underline;
				}
			}
		}

		h4 {
			width: 100%;
			text-align: center;
			justify-content: center;
		}

		.btn.btn-arrow {
			background: var(--secondary);
			padding: 10px 20px;
			color: #fff;
			font-size: 16px;
			letter-spacing: 1px;
			border: 1px solid var(--secondary);
			border-radius: 0;

			&:hover {
				background: #0e2533;
				opacity: 1;
				border: 1px solid #0e2533;
			}
		}
	}

}