.header-style2 {
	&.no-fix {
		.mainHeader {
			@media (min-width: 768px) {
				position: relative !important;
			}
		}
	}
}

.header-style2 {
	&.no-fix {
		.mainHeader {
			@media (max-width: 767px) {
				position: fixed !important;
			}
		}
	}
}

.facility-first-block {
	background: #eaf0f3;

}

.facility-view {
	.unit-list-section {

		@media (max-width: 767px) {
			margin-top: 0 !important;
		}
	}

	.unit-map {
		padding-bottom: 20px;
		height: 500px;
	}
}

.facility-listing-main {

	h2 {
		font-size: 24px;
		color: #000;
		color: var(--secondary);
		display: flex;
		align-items: baseline;
		font-weight: 600;
		line-height: normal;
		justify-content: flex-start;
		margin: 10px 0 20px;
		flex-wrap: wrap;

		@media (max-width: 767px) {
			margin-top: 0;
		}
	}

	.inner-banner {
		background-image: url(RESOURCE/img/inner-banner-2.jpg);
		position: relative;
		background-position: center;

		@media (max-width: 767px) {
			background-image: url(RESOURCE/img/inner-banner-mob-2.jpg);
		}

		&:before {
			background: rgb(255 255 255 / 30%);
			display: block;
			content: " ";
			position: absolute;
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;
		}
	}

	.single-unit {
		position: relative;
		transition: box-shadow 0.4s;

		&:hover {
			box-shadow: 0 3px 20px 0 rgb(0 0 0 / 50%);
		}

		.object-info {
			span {
				display: flex;
				align-items: center;
				justify-content: center;
				height: auto;
				padding: 10px;
				position: absolute;
				z-index: 1;
				top: 0;
				right: 0;
				font-size: 12px;
				background: rgb(28 72 103 / 80%);
				color: #fff;
			}
		}
	}
}

.facility-banner2 {
	&.nxt-prev-btns {
		position: relative;

		.owl-nav {
			position: absolute;
			top: calc(50% - 50px);
			left: 0;
			width: 100%;

			@media (max-width: 767px) {
				top: calc(50% - 25px);
			}



			button {
				position: absolute;
				left: 40px;
				font-size: 45px;

				@media (max-width:767px) {
					left: 15px;
					font-size: 28px;
				}

				span {
					display: inline-block;
					font-style: normal;
					font-variant: normal;
					text-rendering: auto;
					line-height: 1;
					color: transparent;

					&:before {
						color: var(--white);
						font-family: "Font Awesome 5 Pro";
						position: relative;
						left: 3px;
					}
				}

				&:hover {
					span {}
				}

				&.owl-next {
					position: absolute;
					right: 40px;
					left: initial;

					@media (max-width:767px) {
						right: 10px;
					}

					span {

						&:before {
							content: "\f054";
							left: 8px;
						}
					}
				}

				&.owl-prev {
					span {

						&:before {
							content: "\f053";

						}
					}
				}
			}
		}
	}
}

.facility-first-block {
	.single-unit {
		.unit-cont-bl {
			background: #fff !important;
		}

	}
}

.single-unit {
	margin: 0 auto 30px !important;

	@media (max-width: 1200px) {
		/*min-height: 750px;*/
		background: #bec8d2;
	}

	&.facility_139902,
	&.facility_139914,
	&.facility_139891 {
		.unit-cont-bl {
			background-color: #023061 !important;


			.unit-title,
			.unit-content {
				color: #fff !important;
			}

			h5 {
				color: #fff !important;
			}

			span {
				color: #fff !important;
			}
		}
	}

	.owl-carousel {
		overflow: hidden;
		height: 100%;

		.owl-stage-outer {
			height: 100%;
		}

		.owl-stage {
			height: 100%;
		}

		.owl-item {
			height: 100%;
		}

		.owl-nav {
			button {
				width: 50px;
				height: auto;
				bottom: 0;
				top: 0;
				position: absolute;
				border-radius: 0;
				font-size: 0;
				cursor: pointer;
				transition: all 0.25s ease 0s;
				transform: none;
				color: var(--white);
				font-size: 46px;
				line-height: 8px;
				text-align: center;
			}

			.owl-prev {
				/* background: -moz-linear-gradient(left, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%);
						background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%);
						background: linear-gradient(to right, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%);
						filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#66000000', endColorstr='#00000000', GradientType=1); */
				left: 0;

				span {
					height: 20px;
					display: block;
				}

			}

			.owl-next {
				right: 0;

				/* background: -moz-linear-gradient(left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
						background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
						background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
						filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#66000000', GradientType=1); */
				span {
					height: 20px;
					display: block;
				}
			}
		}

		.owl-dots {
			justify-items: center;
			position: absolute;
			max-width: 100%;
			width: 100%;
			left: 0;
			right: 0;
			margin: 0 auto;
			flex-wrap: wrap;
			flex-direction: initial;
			bottom: 100px;
			text-align: center;
			display: block;

			@media (max-width: 991px) {
				padding: 0 15px;
				bottom: 10px;
			}

			@media all (min-width: 992px) and (max-width: 1140px) {
				padding: 0 15px;
				bottom: 220px;
			}

			.owl-dot {
				margin: 0 5px;
				opacity: 0.5;

				span {
					width: 10px;
					height: 10px;
					background: #fff;
					display: block;
					border-radius: 100px;

				}

				&.active {
					opacity: 1;

				}
			}
		}
	}

	.unit-cont-bl {
		top: 0 !important;
		margin: 0 !important;
		background: #bec8d2 !important;
		box-shadow: none !important;
		border-radius: 0 !important;

		.unit-content-list {
			color: #fff
		}
	}

	.unit-title {
		min-height: auto !important;
	}

	.img-content {
		height: 260px;
		overflow: hidden;
		display: block;

		@media (max-width: 574px) {
			height: auto;
		}

		img {
			object-fit: cover;
			height: 100%;
			width: 100%;
		}
	}

	.unit-title {
		font-size: 18px;
		color: #000;
		color: var(--black);
	}
}

.facity-heading {
	padding-top: 20px;
	padding-bottom: 10px;
}

.featur-bl {
	border: 1px solid #ddd;
	box-shadow: 0 0 10px rgba(0, 0, 0, .1);
	border-radius: 10px;
	overflow: hidden;

	@media (max-width:992px) {
		margin-bottom: 25px;
	}

	img {
		width: 100%;
	}
}

.facilitylisting-main {
	padding: 60px 0 30px 0 !important;

	.unit-head-section {
		margin: 0 0 20px 0;

		.page-header {

			padding: 0 0 0 0;

		}
	}

	.unit-pr-dt-gallery {
		padding: 40px 0 0 0;

		@media (max-width: 991px) {
			padding: 20px 0 0 0;
		}

		@media all and (min-width: 768px) and (max-width: 991px) {
			padding-top: 5px;
		}

		h1 {
			margin: 0 0 0 0 !important;
			font-size: 24px;
			color: var(--secondary);
			display: flex;
			align-items: baseline;
			font-weight: 600;
			line-height: 24px;
			justify-content: flex-start;
			width: 40%;

			@media (max-width: 991px) {
				width: 100%;
				margin: 0 !important;
			}
		}

		.facility-img-gallery {
			width: 100%;

			.item {
				.img-title {
					width: 100%;
					min-height: 50px;
					position: absolute;
					bottom: 0;
					background: rgb(28 72 103 / 70%);
					padding: 0 15px;
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 14px;
					color: #fff;
					text-align: center;
				}
			}

			.owl-nav {
				position: absolute;
				top: calc(50% - 50px);
				left: 0;
				width: 100%;

				@media (max-width: 767px) {
					top: calc(50% - 25px);
				}

				button {
					position: absolute;
					left: 40px;
					font-size: 45px;

					@media (max-width:767px) {
						left: 15px;
						font-size: 28px;
					}

					span {
						display: inline-block;
						font-style: normal;
						font-variant: normal;
						text-rendering: auto;
						line-height: 1;
						color: transparent;

						&:before {
							color: var(--white);
							font-family: "Font Awesome 5 Pro";
							position: relative;
							left: 3px;
						}
					}

					&:hover {
						span {}
					}

					&.owl-next {
						position: absolute;
						right: 50px;
						left: initial;

						@media (max-width:767px) {
							right: 10px;
						}

						span {

							&:before {
								content: "\f054";
								left: 8px;
							}
						}
					}

					&.owl-prev {
						span {

							&:before {
								content: "\f053";

							}
						}
					}
				}
			}
		}

		.v-img-gallery {
			top: 0 !important;
			width: 60%;
			max-width: 60%;

			@media (min-width: 992px) {
				display: flex !important;
				flex-wrap: wrap;
			}

			@media (max-width: 992px) {

				margin: 0 0 30px;
				display: flex;
				width: 100%;
				max-width: 100%;
				flex-wrap: wrap;
			}

			a {
				padding: 5px !important;
				background: #cbd5dd !important;
				margin: 0 !important;
				max-width: 100px;
				width: 100%;

				&:nth-child(n + 6) {
					display: none;
				}

				&:nth-child(5) {
					&:before {
						z-index: 5;
						content: "+ mehr";
						position: absolute;
						color: var(--white);
						background-color: rgba(0, 0, 0, 0.5);
						width: 90px;
						height: 60px;
						text-align: center;
						display: inline-block;
						padding-top: 0;
						font-weight: 700;
						font-size: 14px;
						display: flex;
						align-items: center;
						justify-content: center;
					}
				}
			}
		}
	}

	.description {
		margin: 0 0 50px 0;

		p {
			font-size: 15px;
			line-height: 25px;
		}

		a {
			color: var(--primary);
			font-size: 16px;
			text-transform: uppercase;
			margin-top: 20px;
			font-weight: 700;
		}
	}
}

.unit-proposals {
	.more-units-btn {
		text-align: center;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: end;

		@media (max-width: 767px) {
			padding-top: 0;
		}

		i {
			margin: 0 0 0 10px;
		}

		a {
			text-align: left;

			&:hover {
				background: var(--HoverColor);
				opacity: 1;
			}
		}

	}
}

.unit-cont-bl {
	background: #cbd5dd;
	margin: 0;
	top: 0;
	box-shadow: none;

	h6 {
		position: absolute;
		top: -50px;
		right: 15px;
		margin: 0;

		a {
			background: #323c5a;
			color: #fff;
			font-weight: 500;
			font-size: 15px;
			padding: 9px 15px;
			border: 1px solid #fff;

			i {
				margin: 0 0 0 10px;
			}

			&:hover {
				background: var(--HoverColor);
			}

		}
	}
}

.unit-place {
	.prop {
		i {
			font-size: 22px !important;
			opacity: 1 !important;
			margin-right: 3px !important;
			color: #1c2639 !important;
		}
	}

	h5 {
		font-size: 14px;
		line-height: 20px;
		margin: 10px 0 0 0;
		width: 100%;

		span {
			display: block;
			margin: 0 !important;
		}

	}
}


.form-strip {
	.contact-form {


		label {
			color: var(--black);
			font-weight: bold;
			font-size: 14px;
			text-transform: uppercase;
			margin-bottom: 0;
			padding-bottom: 10px;

			&:before {
				top: 2px;
			}

		}

		.checkbox {
			label {
				text-transform: inherit;
				font-weight: normal;
			}

			a {
				color: var(--black);
				font-size: 14px;

			}
		}

		.conact-btn {
			width: 100%;
			bottom: 0;
			margin-top: 35px;

			.btn {
				padding: 11px 30px 15px;
				box-shadow: 2px 1px 10px rgba(0, 0, 0, .2);
				font-size: 20px;

				@media (max-width:992px) {
					font-size: 18px;
				}
			}
		}

		.form-control {
			border: none;
			border-bottom: 2px solid #f2f2f2;
			text-transform: uppercase;
			margin-bottom: 30px;
			border-radius: 0;
			box-shadow: inherit;
			padding: 0;
			background-color: transparent;
		}
	}

	.unit-map {
		height: 400px;
		width: 100%;

		@media (max-width:992px) {
			margin-bottom: 20px;
		}
	}
}

.unit-view {
	.facility-banner {
		position: relative;
		height: 100%;
		z-index: 3;

		@media (max-width:767px) {
			height: 100%;
			margin-top: 123px;
		}

		.aparthotel-page-name {
			position: absolute;
			z-index: 2;
			text-align: center;
			width: 100%;
			padding: 0 0 0 0;

			img {
				margin: 0 auto;
			}

			h1 {
				color: var(--textprimary);
				font-size: 24px;
				text-transform: uppercase;
				font-weight: 600;
				display: block;
				padding: 20px 25px;
				font-family: var(--d-font);
				background: rgb(255 255 255/60%);
				width: auto;
				max-width: 100%;
				margin: 0 auto;

				@media (max-width: 767px) {
					font-size: 16px;
				}
			}
		}

		.nxt-prev-btns {
			position: relative;

			.owl-nav {
				position: absolute;
				top: calc(50% - 50px);
				left: 0;
				width: 100%;

				@media (max-width: 767px) {
					top: calc(50% - 25px);
				}



				button {
					position: absolute;
					left: 40px;
					font-size: 45px;

					@media (max-width:767px) {
						left: 15px;
						font-size: 28px;
					}

					span {
						display: inline-block;
						font-style: normal;
						font-variant: normal;
						text-rendering: auto;
						line-height: 1;
						color: transparent;

						&:before {
							color: var(--white);
							font-family: "Font Awesome 5 Pro";
							position: relative;
							left: 3px;
						}
					}

					&:hover {
						span {}
					}

					&.owl-next {
						position: absolute;
						right: 40px;
						left: initial;

						@media (max-width:767px) {
							right: 10px;
						}

						span {

							&:before {
								content: "\f054";
								left: 8px;
							}
						}
					}

					&.owl-prev {
						span {

							&:before {
								content: "\f053";

							}
						}
					}
				}
			}
		}

		.item {
			width: 100%;
			height: 100%;
			background-size: cover;
			background-position: no-repeat;
			background-position: center;

			img {
				width: 100%;
				object-fit: cover;
				height: auto;

				@media (max-width: 1920px) {
					height: calc(100vh - 135px);
				}

				@media (max-width: 1140px) {
					height: 100%;
				}
			}

			&.banner1 {
				background-image: url(RESOURCE/img/slider-img-1.jpg);

				@media (max-width:992px) {
					background-image: url(RESOURCE/img/slider-mob-img-1.jpg);
				}
			}

			&.banner2 {
				background-image: url(RESOURCE/img/slider-img-2.jpg);

				@media (max-width:992px) {
					background-image: url(RESOURCE/img/slider-mob-img-2.jpg);
				}
			}

			&.banner3 {
				background-image: url(RESOURCE/img/slider-img-3.jpg);

				@media (max-width:992px) {
					background-image: url(RESOURCE/img/slider-mob-img-3.jpg);
				}
			}

			&.banner4 {
				background-image: url(RESOURCE/img/slider-img-4.jpg);

				@media (max-width:992px) {
					background-image: url(RESOURCE/img/slider-mob-img-4.jpg);
				}
			}

			&.banner5 {
				background-image: url(RESOURCE/img/slider-img-5.jpg);

				@media (max-width:992px) {
					background-image: url(RESOURCE/img/slider-mob-img-5.jpg);
				}
			}

			&.banner6 {
				background-image: url(RESOURCE/img/slider-img-6.jpg);

				@media (max-width:992px) {
					background-image: url(RESOURCE/img/slider-mob-img-6.1.jpg);
				}
			}

			&.banner7 {
				background-image: url(RESOURCE/img/slider-img-7.jpg);

				@media (max-width:992px) {
					background-image: url(RESOURCE/img/slider-img-7.jpg);
				}
			}

			&.banner8 {
				background-image: url(RESOURCE/img/slider-img-8.jpg);

				@media (max-width:992px) {
					background-image: url(RESOURCE/img/slider-mob-img-8.1.jpg);
				}
			}

			&.banner9 {
				background-image: url(RESOURCE/img/slider-img-9.jpg);

				@media (max-width:992px) {
					background-image: url(RESOURCE/img/slider-mob-img-9.jpg);
				}
			}

			&.banner10 {
				background-image: url(RESOURCE/img/slider-img-10.jpg);

				@media (max-width:992px) {
					background-image: url(RESOURCE/img/slider-mob-img-10.jpg);
				}
			}

			&.banner11 {
				background-image: url(RESOURCE/img/slider-img-11.jpg);

				@media (max-width:992px) {
					background-image: url(RESOURCE/img/slider-mob-img-11.1.jpg);
				}
			}

			&.banner12 {
				background-image: url(RESOURCE/img/slider-img-12.jpg);

				@media (max-width:992px) {
					background-image: url(RESOURCE/img/slider-mob-img-12.jpg);
				}
			}

			&.banner13 {
				background-image: url(RESOURCE/img/slider-img-13.jpg);

				@media (max-width:992px) {
					background-image: url(RESOURCE/img/slider-mob-img-13.jpg);
				}
			}

			&.banner14 {
				background-image: url(RESOURCE/img/slider-img-14.jpg);

				@media (max-width:992px) {
					background-image: url(RESOURCE/img/slider-mob-img-14.jpg);
				}
			}

		}


		.owl-dots {
			justify-items: center;
			position: absolute;
			max-width: 100%;
			width: 100%;
			left: 0;
			right: 0;
			margin: 0 auto;
			flex-wrap: wrap;
			flex-direction: initial;
			bottom: 100px;
			text-align: center;
			display: block;

			@media (max-width: 1140px) {
				bottom: 20px;
			}



			.owl-dot {
				margin: 0 5px;
				opacity: 0.5;

				span {
					width: 10px;
					height: 10px;
					background: #fff;
					display: block;
					border-radius: 100px;

				}

				&.active {
					opacity: 1;

				}
			}
		}
	}
}