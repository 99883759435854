@import "setup.css";

.indexpage {
	.single-unit {
		.info-col {
			.unit-headline {
				line-height: 1.3;
				margin-bottom: 10px;
			}
		}
	}


	.subscribe-box {
		.form-control {
			margin: 10px 0 10px 0;
		}
	}

	.unitofthedaysection {
		.container {
			overflow: hidden;

			@media (max-width:767px) {

				overflow: visible;
			}
		}

		.owl-stage-outer {
			overflow: visible !important;
		}
	}


	.banner-forms {
		position: relative;
		height: 100%;
		z-index: 3;

		@media (max-width:767px) {

			margin-top: 135px;
		}

		@media (max-width:992px) {
			/* height: calc(100vh - 80px); */
		}

		.nxt-prev-btns {
			position: relative;

			.owl-nav {
				position: absolute;
				top: calc(50% - 50px);
				left: 0;
				width: 100%;

				@media (max-width: 767px) {
					top: calc(50% - 25px);
				}



				button {
					position: absolute;
					left: 40px;
					font-size: 45px;

					@media (max-width:767px) {
						left: 15px;
						font-size: 28px;
					}

					span {
						display: inline-block;
						font-style: normal;
						font-variant: normal;
						text-rendering: auto;
						line-height: 1;
						color: transparent;

						&:before {
							color: var(--white);
							font-family: "Font Awesome 5 Pro";
							position: relative;
							left: 3px;
						}
					}

					&:hover {
						span {}
					}

					&.owl-next {
						position: absolute;
						right: 40px;
						left: initial;

						@media (max-width:767px) {
							right: 10px;
						}

						span {

							&:before {
								content: "\f054";
								left: 8px;
							}
						}
					}

					&.owl-prev {
						span {

							&:before {
								content: "\f053";

							}
						}
					}
				}
			}
		}

		.covid-section {
			.container {
				position: relative;
			}

			a {
				position: absolute;
				z-index: 2;
				right: 15px;
				background: #1c4867;
				color: #fff;
				padding: 0 0 0 15px;
				display: flex;
				align-items: center;
				justify-content: center;
				line-height: 40px;
				border-radius: 0 0 5px 5px;

				&:hover {
					background: var(--HoverColor);
				}

				i {
					width: 40px;
					height: 40px;
					background: var(--HoverColor);
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 14px;
					margin: 0 0 0 10px;
				}
			}
		}

		/* &:before {
			background: rgb(255, 255, 255);
			background: linear-gradient(360deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.75) 100%);
			position: absolute;
			left: 0;
			width: 100%;
			height: 250px;
			content: "";
			top: 0;

			@media (max-width:992px) {
				height: 180px;
			}
		} */

		/* &:after {

			background: rgb(255, 255, 255);
			background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.75) 100%);
			position: absolute;
			left: 0;
			width: 100%;
			height: 250px;
			content: "";
			bottom: 0;

			@media (max-width:992px) {
				height: 180px;
			}
		} */

		@media (max-width:992px) {}

		.search-form-area {
			display: flex;
			align-items: center;
			justify-content: space-between;

			label {
				font-weight: 400;
			}


			@media (max-width:1140px) {
				flex-wrap: wrap;
			}

			@media (min-width:1141px) {
				padding-left: 0;
			}

			.form-flex.region-box {

				@media (max-width: 767px) {
					width: 100% !important;
				}
			}

			.form-flex.date-box {
				width: 35%;

				.datepicker-trigger {
					.asd__wrapper {
						@media (max-width: 767px) {
							padding-top: 0 !important;
						}
					}
				}
			}

			.form-flex {
				padding: 0 5px;
				width: 20%;

				.list-group {
					max-width: 100%;
					width: 100%;
					display: flex;
					flex-wrap: wrap;
					margin: 0;

					ul {
						list-style: none;
						-moz-column-count: 2;
						-moz-column-gap: 15px;
						-webkit-column-count: 2;
						-webkit-column-gap: 15px;
						column-count: 2;
						column-gap: 15px;
						display: block;


						li {
							width: 100%;
							max-width: 190px;
						}

						.Schlafzimmer-li {
							padding: 0;

							select {
								padding: 5px;
								border: 1px solid rgb(85 85 85 / 30%);
								min-height: 35px;
								color: #555;
								font-size: 13px;
								background-position: 97% 14px;
								max-width: 190px;
								margin: 5px 0;
								width: 100%;

							}
						}

						.Unterkunft-li {
							input {
								padding: 5px;
								border: 1px solid rgb(85 85 85 / 30%);
								min-height: 35px;
								color: #555;
								font-size: 13px;
								background-position: 136px 14px;
								max-width: 190px;
								margin: 5px 0;
								width: 100%;
							}
						}
					}

					.list-group-item {
						width: 50%;
						border-radius: 0;
					}
				}

				.box {
					.guestbox {
						.gb-td {
							.btn-default {
								line-height: 23px;
								padding: 0 0 4px;
								display: flex;
								align-items: center;
								justify-content: center;
								font-family: var(--d-font);
							}
						}
					}
				}


				@media (max-width:1140px) {
					width: 50% !important;
					padding: 5px 5px;
				}

				@media all and (min-width: 768px) and (max-width: 1140px) {
					width: 48% !important;
					padding: 10px 0;
				}

				&:first-child {
					border-left: none;
				}

				.form-bx-area {
					position: relative;
					padding-right: 85px;
					padding-left: 2px;
					border-bottom: transparent;
					width: 100%;
					padding: 0;

					&.pinarea {
						@media (min-width:1240px) {
							padding-right: 0;
							min-width: 100%;
						}
					}

					@media (max-width:1340px) {
						padding-right: 0;
					}

					button.form-control {
						width: 100%;

						@media (max-width:1340px) {
							font-size: 18px;
						}
					}


				}

				.fa,
				.far {
					position: absolute;
					right: 15px;
					top: 10px;
					color: var(--black);
					z-index: 2;
					font-size: 20px;
					pointer-events: none;

					@media (max-width:1340px) {}

					@media (max-width:1140px) {
						top: 10px;

					}

					&.shadow {
						font-size: 65px;
						top: 0px;
						color: var(--grey);
						z-index: 1;
						right: 14px;

						@media (max-width:1340px) {
							font-size: 48px;
						}

						@media (max-width:1140px) {
							font-size: 37px;
							top: -4px;
							opacity: .5;
						}
					}

					&.fa-map-marker-alt {
						right: 8px;

						@media (max-width:1140px) {
							right: 0;
							ding-right: 35px;
						}

						&.shadow {
							@media (max-width:1140px) {
								right: 9px !important;
							}
						}
					}
				}




				.form-control {
					background-color: var(--white);
					border: none;
					border-radius: 0;
					width: 100%;
					padding: 28px 0 15px;
					padding-right: 10px;
					padding-left: 10px;
					height: inherit;
					box-shadow: inherit;
					font-family: var(--d-font);
					padding: 0 5px;
					font-size: 14px;
					text-align: left;
					height: 40px;
					background-image: none !important;


					@media (max-width:1340px) {
						font-size: 14px !important;
						padding: 0 10px;
					}

					@media (max-width:1140px) {
						font-size: 14px !important;
						padding: 0 10px;
					}

					@media (max-width:480px) {
						font-size: 12px !important;
						padding: 0 10px;
					}
				}

				.Mehr-Suchoptionen {
					.form-control {
						background: #bfd5dc;
						border: 1px solid #fff;
						text-align: center;
					}

					.list-group {
						max-width: 100%;
					}

					.dropdown-menu {
						margin: 10px 0 0;
						padding: 15px;
						z-index: 999;
						background: #fff;
						border: 1px solid #ccc;
						border-radius: 8px;
						box-shadow: 0 0 8px 0 rgb(0 0 0 / 20%);
						width: 410px;
						left: -120px;

						&:before {
							position: absolute;
							width: 8px;
							height: 8px;
							z-index: -1;
							content: "";
							-webkit-transform: rotate(45deg);
							transform: rotate(45deg);
							background: #fff;
							top: -5px;
							left: 0;
							right: 0;
							margin: 0 auto;
						}


						@media (max-width: 767px) {
							max-width: 100%;
							left: 0;
							padding: 15px;
							width: 100%;
							height: auto;
						}


						li {
							width: 50%;
							display: inline-block;
							float: left;

							@media (max-width: 767px) {
								width: 100%;
							}
						}
					}

					.checkbox {
						margin: 5px 0;

						label {
							padding-left: 10px;
							color: #555;
							font-size: 13px;
						}
					}

					.box {
						min-width: 395px;
						width: 100%;
						max-width: 395px;

						@media (max-width: 400px) {
							min-width: 370px;
							max-width: 370px;
						}

						@media (max-width: 374px) {
							min-width: 320px;
							max-width: 320px;
						}

					}
				}
			}

			.form-flex.date-box {
				.form-control {
					padding-right: 35px;
				}

				.box.full-xxs {
					@media (max-width: 480px) {
						overflow: auto;
						padding: 125px 0 0 0 !important;
					}
				}

				.asd__wrapper {
					@media (max-width: 767px) {
						margin: 0 auto !important;

					}
				}

				@media (max-width: 767px) {
					width: 100% !important;
				}
			}

			.form-flex.form-btn {
				@media (max-width: 767px) {
					width: 100% !important;
				}
			}

			.form-btn {

				@media (max-width:1140px) {
					width: 100%;
					text-align: center;
				}

				@media all and (min-width: 768px) and (max-width: 1140px) {
					width: 48%;
					text-align: center;
				}

				.btn {
					-webkit-box-align: center;
					align-items: center;
					display: -webkit-box;
					display: flex;
					-webkit-box-pack: center;
					font-size: 14px;
					border-radius: 0;
					font-weight: 500;
					position: relative;
					margin-left: 0;
					height: 40px;
					border: 1px solid #fff;
					background: transparent;
					padding: 0 10px;
					text-transform: uppercase;
					letter-spacing: 1.5px;

					&:hover {
						background: #fff;
						color: #555;
					}

					@media (max-width:1140px) {
						font-size: 16px;
						border-radius: 0;
						position: relative;
						height: 45px;
						width: 100%;
					}

					i {
						margin-left: 0;
						color: #fff;
						font-size: 15px;
						margin-right: 0;
						margin-top: 2px;

						@media (max-width:1140px) {
							font-size: 16px;
							margin-left: 0;
							margin-top: 4px;
						}
					}

					&:hover i {
						color: #555;
					}


				}
			}
		}

		.banner-slide {
			/* position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			z-index: -1; */

			.owl-dots {
				display: none;
			}

			.owl-item {
				position: relative;
			}

			.item {
				width: 100%;
				height: 100%;
				background-size: cover;
				background-position: no-repeat;
				background-position: center;

				@media (max-width:992px) {
					/* height: calc(100vh - 80px); */
				}

				img {
					width: 100%;
					object-fit: cover;
					height: auto;

					@media (max-width: 1920px) {
						height: 686px;
					}

					@media (max-width: 1140px) {
						height: 100%;
					}
				}

				/* &.banner1 {
					background-image: url(RESOURCE/img/slider-img-1.jpg);

					@media (max-width:992px) {
						background-image: url(RESOURCE/img/slider-mob-img-1.jpg);
					}
				}

				&.banner2 {
					background-image: url(RESOURCE/img/slider-img-2.jpg);

					@media (max-width:992px) {
						background-image: url(RESOURCE/img/slider-mob-img-2.jpg);
					}
				}

				&.banner3 {
					background-image: url(RESOURCE/img/slider-img-3.jpg);

					@media (max-width:992px) {
						background-image: url(RESOURCE/img/slider-mob-img-3.jpg);
					}
				}

				&.banner4 {
					background-image: url(RESOURCE/img/slider-img-4.jpg);

					@media (max-width:992px) {
						background-image: url(RESOURCE/img/slider-mob-img-4.jpg);
					}
				}

				&.banner5 {
					background-image: url(RESOURCE/img/slider-img-5.jpg);

					@media (max-width:992px) {
						background-image: url(RESOURCE/img/slider-mob-img-5.jpg);
					}
				}

				&.banner6 {
					background-image: url(RESOURCE/img/slider-img-6.jpg);

					@media (max-width:992px) {
						background-image: url(RESOURCE/img/slider-mob-img-6.1.jpg);
					}
				}

				&.banner7 {
					background-image: url(RESOURCE/img/slider-img-7.jpg);

					@media (max-width:992px) {
						background-image: url(RESOURCE/img/slider-img-7.jpg);
					}
				}

				&.banner8 {
					background-image: url(RESOURCE/img/slider-img-8.jpg);

					@media (max-width:992px) {
						background-image: url(RESOURCE/img/slider-mob-img-8.1.jpg);
					}
				}

				&.banner9 {
					background-image: url(RESOURCE/img/slider-img-9.jpg);

					@media (max-width:992px) {
						background-image: url(RESOURCE/img/slider-mob-img-9.jpg);
					}
				}

				&.banner10 {
					background-image: url(RESOURCE/img/slider-img-10.jpg);

					@media (max-width:992px) {
						background-image: url(RESOURCE/img/slider-mob-img-10.jpg);
					}
				}

				&.banner11 {
					background-image: url(RESOURCE/img/slider-img-11.jpg);

					@media (max-width:992px) {
						background-image: url(RESOURCE/img/slider-mob-img-11.1.jpg);
					}
				}

				&.banner12 {
					background-image: url(RESOURCE/img/slider-img-12.jpg);

					@media (max-width:992px) {
						background-image: url(RESOURCE/img/slider-mob-img-12.jpg);
					}
				}

				&.banner13 {
					background-image: url(RESOURCE/img/slider-img-13.jpg);

					@media (max-width:992px) {
						background-image: url(RESOURCE/img/slider-mob-img-13.jpg);
					}
				}

				&.banner14 {
					background-image: url(RESOURCE/img/slider-img-15-3.jpg);

					@media (max-width:992px) {
						background-image: url(RESOURCE/img/slider-mob-img-15-1.jpg);
					}
				}

				&.banner15 {
					background-image: url(RESOURCE/img/slider-img-15.jpg);

					@media (max-width:992px) {
						background-image: url(RESOURCE/img/slider-mob-img-15.jpg);
					}
				}

				&.banner16 {
					background-image: url(RESOURCE/img/slider-img-16.jpg);

					@media (max-width:992px) {
						background-image: url(RESOURCE/img/slider-mob-img-16.jpg);
					}
				}

				&.banner17 {
					background-image: url(RESOURCE/img/slider-img-17.jpg);

					@media (max-width:992px) {
						background-image: url(RESOURCE/img/slider-mob-img-17.jpg);
					}
				}

				&.banner18 {
					background-image: url(RESOURCE/img/slider-img-18.jpg);

					@media (max-width:992px) {
						background-image: url(RESOURCE/img/slider-mob-img-18.jpg);
					}
				}

				&.banner19 {
					background-image: url(RESOURCE/img/slider-img-19.jpg);

					@media (max-width:992px) {
						background-image: url(RESOURCE/img/slider-mob-img-19.jpg);
					}
				}

				&.banner20 {
					background-image: url(RESOURCE/img/slider-img-20.jpg);

					@media (max-width:992px) {
						background-image: url(RESOURCE/img/slider-mob-img-20.jpg);
					}
				}

				&.banner21 {
					background-image: url(RESOURCE/img/slider-img-21.jpg);

					@media (max-width:992px) {
						background-image: url(RESOURCE/img/slider-mob-img-21.jpg);
					}
				} */

				/* &:after {
					height: 80px;
					bottom: 0;
					left: 0;
					right: 0;
					position: absolute;
					display: block;
					background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 0.5) 70%, rgba(0, 0, 0, 0.8) 100%);
					background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 0.5) 70%, rgba(0, 0, 0, 0.8) 100%);
					background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 0.5) 70%, rgba(0, 0, 0, 0.8) 100%);
					filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#cc000000', GradientType=0);
					content: "";
					opacity: 0.6;
				} */
			}


			.owl-dots {
				bottom: 60px;
				width: 50%;
				left: 50%;
				transform: translate(-50%, 0);
				font-size: 27px;
				text-align: center;
				position: absolute;

				@media (max-width:992px) {
					display: none;
				}

				.owl-dot {
					width: 50px;
					height: 0;
					display: inline-block;
					border-bottom: 4px solid var(--white);
					margin: 0 4px;

					&.active {
						border-bottom-color: var(--primary);
					}
				}
			}
		}


		.search-box-home {
			position: absolute;
			bottom: 0;
			text-align: center;
			width: 100%;
			z-index: 5;
			padding: 20px 10px;
			background: var(--primary);

			@media (max-width:1140px) {
				position: relative;
				padding: 0 5px;
			}
		}

		.searchbar {

			display: inline-block;
			text-align: left;
			background-color: transparent;
			border-radius: 0px;
			width: 100%;

			@media (max-width:1140px) {
				width: 100%;
				padding: 10px 10px;
			}

			@media (max-width: 767px) {
				padding: 20px 0;
			}

			/*@media (min-width:993px) {
				max-width: 1200px;
				width: 100%;
			}*/


			.searchbox {
				padding: 0;
				position: relative;
				right: -1px;


				@media (max-width:1140px) {
					padding-top: 0;
					display: inline-block;
					right: 0;
					width: 100%;
				}

				input,
				.search-btn,
				select {
					border-radius: 0;
				}

				.search-btn {
					width: 100%;
				}

				.search-popup-trigger {
					cursor: pointer;
				}

				.form-control:disabled,
				.form-control[readonly] {
					background-color: white;
				}
			}


		}

		.contact-section {
			background-color: #fff !important;
		}






		@media (max-width:767px) {

			.iheader {
				background: var(--introMobileImg) no-repeat center;
				-webkit-background-size: cover;
				background-size: cover;

				.intro {
					.hero {
						h2 {
							font-size: 18px;
						}
					}
				}


			}
		}
	}

	.secondary-bar {
		padding: 20px 10px;
		background: #eaf0f3;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 15px;
		font-weight: 600;
		flex-wrap: wrap;

		p {
			margin: 0;

			@media all (min-width: 768px) and (max-width: 991px) {
				width: 100%;
				text-align: center;
				margin: 0 0 10px 0;
			}
		}

		.list-group {
			font-size: 12px;
			font-weight: 400;
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			margin: 0 !important;

			a {
				width: 50%;

				@media (max-width: 767px) {
					width: 100%;
				}
			}
		}

		.box {
			width: 100%;
			max-width: 600px;
			height: 330px !important;
			overflow: auto !important;
		}

		.form-flex {
			@media (max-width: 767px) {
				width: 100%;
				margin: 10px 0 0 0 !important;
			}

			#facility-trigger {
				@media (max-width: 767px) {
					margin: 0;
				}
			}
		}

		#facility-trigger {
			background-color: var(--white);
			border: none;
			border-radius: 0;
			width: 100%;
			min-width: 250px;
			box-shadow: inherit;
			font-family: var(--d-font);
			padding: 0 5px;
			font-size: 13px;
			text-align: left;
			height: 40px;
			background-image: none !important;
			margin-left: 10px;
			font-weight: 400;
		}

		.facility-box {
			margin-right: 10px;
		}

		.name-order-box {
			margin-right: 10px;
			margin-left: 10px;

			input {
				background-color: var(--white);
				border: none;
				border-radius: 0;
				width: 100%;
				min-width: 250px;
				box-shadow: inherit;
				font-family: var(--d-font);
				padding: 0 5px;
				font-size: 13px;
				text-align: left;
				height: 40px;
				background-image: none !important;

				font-weight: 400;
			}
		}

		.searchbox {
			a {
				height: 40px;
				padding: 8px 25px;

				&:hover {
					background: var(--HoverColor);
				}

				@media (max-width: 767px) {
					width: 100%;
				}
			}
		}
	}
}

.unitsoftheday {
	.single-unit {
		.unit-place {
			display: block;
		}
	}
}