@import "fontawesome.css";

@define-mixin flexlayout $direction {

	/* OLD - iOS 6-, Safari 3.1-6, BB7 */
	display: -webkit-box;
	/* TWEENER - IE 10 */
	display: -ms-flexbox;
	/* NEW - Safari 6.1+. iOS 7.1+, BB10 */
	display: -webkit-flex;
	/* NEW, Spec - Firefox, Chrome, Opera */
	display: flex;

	-webkit-flex-direction: $(direction);
	-ms-flex-direction: $(direction);
	flex-direction: $(direction);
}

.regionSection>h2 {
	display: block;
	cursor: pointer;
	border: 1px solid #000;
	padding: 10px 15px;
	position: relative;
	line-height: 1;

	.toggle-icon {
		right: 5px;
		top: 8px;
		font-size: 30px;
		position: absolute;
		font-weight: 300;
	}

}


.fa {
	&.fa-heart {
		&:before {
			content: "";
			background-image: url(RESOURCE/img/icon-heart.svg);
			width: 24px;
			height: 24px;
			display: inline-block;
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center;
		}
	}
}


@font-face {
	font-family: 'EratoRegular';
	src: url('RESOURCE/fonts/EratoRegular.woff') format('woff'),
		url('RESOURCE/fonts/EratoRegular.woff2') format('woff2'),
		url('RESOURCE/fonts/EratoRegular.eot'),
		url('RESOURCE/fonts/EratoRegular.eot') format('embedded-opentype'),
		url('RESOURCE/fonts/EratoRegular.otf') format('truetype');

	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Europa-Light';
	src: url('RESOURCE/fonts/Europa-Light.woff') format('woff'),
		url('RESOURCE/fonts/Europa-Light.woff2') format('woff2'),
		url('RESOURCE/fonts/Europa-Light.eot'),
		url('RESOURCE/fonts/Europa-Light.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Europa-Light.otf') format('truetype');

	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Europa-Regular';
	src: url('RESOURCE/fonts/Europa-Regular.woff') format('woff'),
		url('RESOURCE/fonts/Europa-Regular.woff2') format('woff2'),
		url('RESOURCE/fonts/Europa-Regular.eot'),
		url('RESOURCE/fonts/Europa-Regular.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Europa-Regular.otf') format('truetype');

	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Europa-Bold';
	src: url('RESOURCE/fonts/Europa-Bold.woff') format('woff'),
		url('RESOURCE/fonts/Europa-Bold.woff2') format('woff2'),
		url('RESOURCE/fonts/Europa-Bold.eot'),
		url('RESOURCE/fonts/Europa-Bold.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Europa-Bold.otf') format('truetype');

	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'OpenSans-Regular';
	src: url('RESOURCE/fonts/OpenSans-Regular.woff') format('woff'),
		url('RESOURCE/fonts/OpenSans-Regular.woff2') format('woff2'),
		url('RESOURCE/fonts/OpenSans-Regular.svg#OpenSans-Regular') format('svg'),
		url('RESOURCE/fonts/OpenSans-Regular.eot'),
		url('RESOURCE/fonts/OpenSans-Regular.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/OpenSans-Regular.ttf') format('truetype');

	font-weight: normal;
	font-style: normal;
	font-display: swap;
}



/* comic-neue-300 - latin */
@font-face {
	font-family: 'Comic Neue';
	font-style: normal;
	font-weight: 300;
	src: local(''),
		url('RESOURCE/fonts/comic-neue-v6-latin-300.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/comic-neue-v6-latin-300.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	font-display: swap;
}

/* comic-neue-regular - latin */
@font-face {
	font-family: 'Comic Neue';
	font-style: normal;
	font-weight: 400;
	src: local(''),
		url('RESOURCE/fonts/comic-neue-v6-latin-regular.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/comic-neue-v6-latin-regular.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	font-display: swap;
}

@font-face {
	font-family: 'ComicNeue-Regular';
	src: url('RESOURCE/fonts/ComicNeue-Regular.woff') format('woff'),
		url('RESOURCE/fonts/ComicNeue-Regular.woff2') format('woff2'),
		url('RESOURCE/fonts/ComicNeue-Regular.svg#ComicNeue-Regular') format('svg'),
		url('RESOURCE/fonts/ComicNeue-Regular.eot'),
		url('RESOURCE/fonts/ComicNeue-Regular.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/ComicNeue-Regular.ttf') format('truetype');

	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

.layout-column {
	@mixin flexlayout column;
}

.layout-row {
	@mixin flexlayout row;
}

h1,
h2,
h3 {
	font-family: var(--d-bold);
}

.next-prev-btns {
	.owl-nav {
		button {
			span:before {
				color: #153154 !important;
				font-family: 'Font Awesome 5 Pro' !important;
				font-weight: 400 !important;
			}
		}
	}
}


/* single unit css */
.search-unit-bluebar {
	align-items: center;
	justify-content: space-between;
	padding: 5px 15px;
	width: 100%;
	background: #cbd5dd;
	display: flex;
	flex-wrap: wrap;
	min-height: 118px;

	@media (max-width: 992px) {
		flex-wrap: wrap;
		padding: 10px;
	}

	.units {
		width: 100%;

		ul {
			display: flex;
			align-items: center;
			list-style: none;
			flex-wrap: wrap;

			li {
				margin: 0 5px 0 0;

				@media all and (min-width: 768px) and (max-width: 1199px) {
					margin: 0 1px 0 0;
				}

				.icon-bg {
					background-image: url(RESOURCE/img/Icon_alle.svg);
					width: 30px;
					height: 30px;
					display: block;
					background-size: 55px;
				}

				.balcony {
					background-position: -12px -304px;
				}

				.sauna {
					background-position: -12px 142px;
				}

				.seeview {
					background-position: -12px -256px;
				}

				.pets {
					background-position: -12px -501px;
				}

				.wifi {
					background-position: -12px -646px;
				}

				.lift {
					background-position: -12px -699px;
				}

				.nonsmoking {
					background-position: -12px -600px;
				}

				.geschirrspuler {
					background-position: -12px -353px;
				}

				.kamin {
					background-position: -12px -550px;
				}

				.kinderbett {
					background-position: -12px -402px;
				}

				.kleinkindausstattung {
					background-position: -12px -452px;
				}

				.parking {
					background-position: -12px 241px;
				}

				.waschmaschine {
					background-position: -12px 43px;
				}

				img {
					width: 18px;
				}
			}
		}
	}

	.mehr-btn-area {
		width: 100%;
		display: flex;
		justify-content: flex-end;
		margin-top: 20px;

		@media (max-width: 992px) {
			width: 100%;

		}

		@media all and (min-width: 768px) and (max-width: 1199px) {
			display: flex;
			align-items: center;
			justify-content: flex-end;
		}

		a {
			background: var(--primary);
			padding: 5px 10px;
			color: #fff;
			font-size: 12px;
			letter-spacing: 1px;
			border: 1px solid var(--white);

			i {
				color: #fff;
				margin: 0 0 0 5px;

			}

			&:hover {
				border: 1px solid var(--white);
				color: #fff;
				background: var(--HoverColor);
			}

			&:hover i {
				color: var(--white);
			}
		}
	}

}

.unit-proposals {
	.single-unit {
		.unit-cont-bl {
			flex-direction: column;
			justify-content: flex-start;
			align-items: self-start;
			min-height: 180px;
		}
	}
}

.info-col {
	background: #bec8d2 !important;
	padding: 15px 15px;
	padding-bottom: 5px;
	min-height: 350px;

	.show-mob {
		.unit-distance {
			background-color: var(--white);
			border-radius: 15px;
			position: absolute;
			top: -55px;

			@media (max-width:992px) {
				top: -36px;
			}
		}
	}

	@media (max-width:992px) {
		background: rgb(203 213 221 / 30%);

	}



	.region-bl {
		display: inline-flex;
		align-items: center;
		position: absolute;
		top: 5px;
		right: 10px;

		span {
			color: var(--black);
			opacity: .5;
			font-size: 13px;
		}
	}

	.text-truncate {
		color: var(--secondary);
		font-size: 13px;
		font-family: var(--d-font2);
		margin-bottom: 20px;
	}

	.flex-align-center {
		padding-bottom: 14px;
		border-bottom: 1px solid #f2f2f2;
		margin-bottom: 14px;
	}

	.search-result-properties {
		margin: 0 !Important;

		li {
			display: inline-block;
			list-style: none;
			text-align: center;
			float: left;
			padding: 0 0 0 10px;

			@media (max-width:360px) {
				padding-left: 5px;
			}

			i {
				font-size: 16px;
				color: var(--d-grey);
				font-style: normal;
				width: 28px;
				height: 28px;
				background-color: var(--grey);
				border-radius: 50%;
				display: flex;
				align-items: center;
				justify-content: center;
				position: relative;

				@media (max-width:360px) {
					font-size: 14px;
					width: 26px;
					height: 26px;
				}

				&.cross {
					&:after {
						width: 1.5px;
						height: 100%;
						position: absolute;
						content: "";
						background-color: var(--d-grey);
						-webkit-transform: rotate(-45deg);
						transform: rotate(-45deg);
					}
				}
			}
		}
	}



	.unit-title {
		display: flex;
		align-items: baseline;
		flex-wrap: wrap;
		margin: 0 0 20px;

		h4 {
			display: flex;
			align-items: baseline;
			font-weight: 600;
			line-height: 24px;
			justify-content: space-between;
			flex-wrap: wrap;
			width: 100%;
			font-size: 20px;
			line-height: 26px;
			margin: 0 0 10px 0;
			font-family: var(--bold-font2);

			@media (max-width: 767px) {
				font-size: 20px;
				line-height: 26px;
			}

			small {
				width: 100%;
				font-size: 13px;
				padding: 5px 0;
				color: var(--textprimary);
			}
		}

		a {
			color: var(--textprimary);
			display: block;
			/*min-height: 55px;*/
			/*min-height: 55px;*/
			width: 100%;
		}

		span {
			width: 100%;
			font-size: 12px;
			font-weight: 400;
			display: flex;
			align-items: center;
			position: relative;
			top: 0;
			padding: 0;
			color: #565a5c;

			@media (max-width:767px) {
				justify-content: flex-start;
				padding: 0;
				top: 0;
			}

			.fav-icon {
				margin: 0 0 0 10px;
			}
		}

		@media (max-width:992px) {
			font-size: 16px;
		}

		@media (max-width:767px) {
			flex-wrap: wrap;
			margin: 0 0 20px 0;
		}
	}

	.unit-price-rat {
		display: -webkit-box;
		display: flex;
		flex-wrap: wrap;
		-webkit-box-pack: justify;
		justify-content: space-between;
		-webkit-box-align: center;
		align-items: center;
		flex-wrap: wrap;

		.prices {

			width: 100%;
			padding: 15px 0;

			.offer-price-box {
				display: flex;
				align-items: center;
				flex-wrap: wrap;

				.discount-price {
					margin: 0 5px 0 0;

					@media (max-width: 575px) {
						font-size: 16px;
					}
				}

				.pro {
					margin: 0 0 0 5px;
				}

			}
		}


		.unit-link {
			text-transform: uppercase;
			color: var(--primary);
			font-size: 13px;
			font-weight: 700;
			position: relative;
			top: 2px;
		}
	}

	.alternatives {

		margin: 20px 0 12px 0;
		font-size: 14px;

		.altheading {
			color: var(--red);
			font-family: var(--d-font2);
			margin-bottom: 10px;
			display: block;
		}

		.li-bullet {

			li {
				list-style: none;
				background-color: #cbd5dd;
				padding: 10px 15px;
				color: var(--black);
				opacity: 1;
				border: 1px solid #cfebf9;
				font-family: var(--d-font2);
				border-bottom: none;

				&:first-child {
					border-radius: 5px 5px 0 0;
				}

				&:last-child {
					border-radius: 0 0 0px 0px;
					border-bottom: 1px solid #cfebf9;
				}

				a {
					color: var(--secondary);
				}
			}
		}
	}

	.offer-price-box {
		.main-price {
			strong {
				text-decoration: line-through;
			}
		}

		.discount-price[title="Last-Minute"] {
			strong {
				color: var(--red);
			}
		}

		>.discount-price {
			font-size: 21px;
			font-weight: 600;
			color: var(--secondary);
		}

		.popover {
			@media (max-width: 374px) {
				max-width: 218px;
				margin-left: -79px;
			}

			@media all (min-width:375px) and (max-width: 767px) {
				margin-left: -79px;
				max-width: 218px;
			}

			@media all (min-width: 992px) and (max-width: 1199px) {
				margin-left: -95px;
				max-width: 230px;
			}
		}
	}

	.offer-bl-srch {
		text-align: center;
		position: relative;
		z-index: 1;

		.offer-box {
			padding-top: 12px;
			border-top: 1px solid #cbd5dd;
			margin-top: 0;
			margin-bottom: 12px;
			display: block;

			@media (max-width: 992px) {
				border-top: 1px solid rgb(0 0 0 / 10%);
			}
		}


		.popover {
			font-size: 12px;
			background-color: #fff6df;
			border: 1px solid #febb02 !important;
			z-index: 2;

			.popover-title {
				text-transform: capitalize;
				padding: 0 0 10px 0;
				margin: 0;
				font-size: 12px !important;
				background-color: transparent;
				border-bottom: none;
				border-radius: 5px 5px 0 0;
				font-family: var(--d-font);
				font-weight: 400;
				margin-bottom: 10px;
				padding-bottom: 4px;
				border-bottom: 1px solid #febb02;
				color: #47403a;
			}

			.arrow {
				&:after {
					border-top-color: #febb02 !important;
					border-bottom-color: #febb02 !important;
				}
			}
		}

		.offer-box {
			a {
				background-image: url(RESOURCE/img/offer-bg.png);
				position: relative;
				padding: 0;
				display: inline-flex;
				align-items: center;
				background-repeat: no-repeat;
				background-position: center;
				max-width: 270px;
				background-size: 270px;
				height: 38px;
				display: flex;
				align-items: center;
				justify-content: center;
				margin: 0 auto;
				color: var(--secondary);
				font-size: 14px;

				@media (max-width: 400px) {
					background-size: 240px;
				}

			}

			.offer-text {
				background-image: url(RESOURCE/img/bg-offer-text.png);
				background-repeat: no-repeat;
				background-position: center;
				text-transform: uppercase;
				font-size: 14px;
				width: 116px;
				height: 38px;
				display: flex;
				align-items: center;
				justify-content: center;
				text-transform: uppercase;
				font-weight: bold;
				background-size: 116px;
				color: var(--black);
			}

			ul {
				li {}
			}
		}
	}



	.rentRate {
		.minrent {
			margin: 0;

			strong {
				font-size: 18px;
				font-weight: 600;
				color: var(--textprimary);

				@media (max-width:992px) {
					font-size: 18px;
				}
			}

			.price-bl {
				display: flex;
				align-items: center;
				font-size: 14px;
				color: var(--textprimary);
				font-weight: 500;
				flex-wrap: wrap;

				i.fa {
					color: #7f7f7f;
					font-size: 12px;
					margin-left: 5px;
				}

				.price-start {
					font-size: 18px;
					font-weight: 600;
					color: var(--textprimary);
					margin: 0 5px 0 0;
				}

				.pro {
					width: 100%;
					display: block;
					padding: 5px 0 0 0;
					color: var(--textprimary);
				}
			}

			span {
				font-size: 12px;
				color: #7e7e7e;
				display: block;
				line-height: 18px;
			}
		}

		a {
			color: var(--textprimary);
			position: relative;
			z-index: 1;
		}

		.price-info {

			i {
				color: #7f7f7f;
				font-size: 12px;
				margin-left: 5px;
			}
		}
	}

	.unit-dt-right {
		display: flex;
		align-items: center;
	}

	.unit-distance {
		padding: 0 15px;
		font-weight: bold;
		right: 0;
		top: 0;
		font-size: 12px;
		line-height: 30px;
		color: rgba(0, 0, 0, .7);
		background-color: #e6f2f7;
		border-radius: 15px;
	}

	.stars {
		position: absolute;
		right: 0;
		bottom: 0;
	}

	.unit-place {
		font-size: 16px;
		font-family: var(--d-font2);


		li {
			display: inline-block;
			color: #555;
			width: 100%;
			padding: 0 0 0 10px !important;
			font-size: 14px;
			margin: 0 0 5px 0;
			position: relative;

			&:before {
				display: block;
				content: "";
				width: 1px;
				height: 12px;
				background: #555;
				position: absolute;
				left: 0;
				top: 4px;
			}

			@media (max-width:360px) {
				font-size: 14px;
				padding-left: 3px;
			}

			&:first-child {
				padding-left: 0;
				margin-left: 0;

			}

			i {
				font-size: 18px;
				opacity: .2;
				margin-right: 3px;

				@media (max-width:360px) {
					font-size: 15px;
					margin-right: 2px;
				}
			}
		}
	}

	.prices {
		.price {
			font-size: 20px;
		}
	}

	.search-result-properties {
		margin-top: 5px;
		margin-bottom: 15px;
	}
}

@define-mixin flex {
	/* OLD - iOS 6-, Safari 3.1-6 */
	-webkit-box-flex: 1;
	/* Safari 6.1+. iOS 7.1+, BB10 */
	-webkit-flex: 1 1 0;
	-ms-flex: 1 1;
	flex: 1 1 0;

	min-width: 0;
	min-height: 0;
}


blockquote {
	border-left-color: var(--secondary);

	footer {
		color: var(--secondary);
	}
}

.flex {
	@mixin flex;
}

a {
	&:hover {
		text-decoration: none;
	}
}


@define-mixin columnlayout $rows,
$gap: 1em {
	-webkit-column-count: $(rows);
	/* Chrome, Safari, Opera */
	-moz-column-count: $(rows);
	/* Firefox */
	column-count: $(rows);

	-webkit-column-gap: $(gap);
	/* Chrome, Safari, Opera */
	-moz-column-gap: $(gap);
	/* Firefox */
	column-gap: $(gap);
}


@define-mixin size100 {
	width: 100%;
	height: 100%;
}

.size100 {
	@mixin size100;
}

@define-mixin overflowscrolls {
	display: block;
	overflow: auto;
	position: relative;
	-webkit-overflow-scrolling: touch;
}

.overflowscrolls {
	@mixin overflowscrolls;
}


.text-truncate {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.wrap-btn {
	white-space: inherit;
	height: auto !important;
}

.text-white {
	color: var(--white);

	&:hover {
		color: var(--secondary);
	}
}

.hidden {
	display: none;
}

.btn-go {
	background-color: #007da7;
	color: #FFF !important;
	border: 1px solid #026B8E;
	//box-shadow: 0 1px 1px #eee;
}


.btn-go:hover,
.btn-go:focus,
.btn-go:active,
.btn-go.active {
	color: #FFF;
	background-color: #0187B4;
	border: 1px solid #09BDF3;
}


.mt-40 {
	margin-top: 40px;
}

.clean-form {
	select {
		-moz-appearance: none;
		-webkit-appearance: none;
	}
}

.grey-bg {
	border-top: 1px solid #eaebec;
	border-bottom: 1px solid #eaebec;
	background: #F7F8FA;
}

.glyph {
	width: 26px;
	height: 26px;
	display: inline-block;
}

.glyph.i-door {
	background: no-repeat url(RESOURCE/img/door.png) 0 0;
}

.confirmation {
	.fa-check {
		color: green;
	}
}

.list-striped li:nth-of-type(odd) {
	background-color: #f9f9f9;
}

.list-striped-dark li:nth-of-type(odd) {
	background-color: #dadada;
}

.pano {
	min-height: 400px;
	margin-bottom: 100px !important;
}


.slider.slider-horizontal {
	width: 100%;
}


.contact-form {

	label {
		font-weight: bold;
	}

	margin-bottom: 20px;
}

.invalid {
	text-decoration: line-through;
}

.special {
	color: red;
}

.asd__day--tada {
	background-color: red;
}

.cust-select {
	position: relative;

	select {
		cursor: pointer;
	}

	&:after {
		background-color: var(--white);
		width: 15px;
		height: 10px;
		position: absolute;
		content: "";
		right: 0;
		top: 13px;
	}
}

.datepicker-trigger {

	.asd__change-month-button {
		background-color: transparent;
	}

	.asd__change-month-button>button {
		background-color: var(--primary);
		color: #fff;
		border: none !important;

		svg {
			height: 19px;
			width: 19px;
			fill: #fff;
			display: block;
		}
	}

	.asd__month-table {
		background-color: transparent;
	}

	.asd__wrapper {
		background-color: transparent;
	}

	.asd__day--enabled {
		border: none !important;
	}

	.asd__day-button {
		font-size: 12px;
	}

	.asd__day-button {
		pointer-events: auto;
	}


	.asd__day-button:disabled,
	.asd__day-button[disabled] {
		pointer-events: none;
		/* color: #D54627; */
	}

	.asd__day--enabled.asd__day--disabled {
		color: #D54627 !important;
	}

	.asd__day--not-available {
		background: var(--not_available) !important;
		opacity: 1 !important;
	}

	.asd__day--arrival {
		background: linear-gradient(90deg, var(--available) 50%, var(--not_available) 50%) !important;
		opacity: 1 !important;
	}

	.asd__day--departure {
		background: linear-gradient(90deg, var(--not_available) 50%, var(--available) 50%) !important;
		opacity: 1 !important;
	}

	.asd__day--arrival:hover:not(.asd__day--disabled):not(.asd__day--selected) {
		background: linear-gradient(90deg, var(--disabled) 50%, var(--not_available) 50%) !important;
	}

	.asd__day--departure:hover:not(.asd__day--disabled):not(.asd__day--selected) {
		background: linear-gradient(90deg, var(--not_available) 50%, var(--disabled) 50%) !important;
	}

	.asd__day--disabled.asd__day--in-range {
		background: var(--selection_range) !important;
		opacity: 1 !important;
		border: 1px double var(--selected_border) !important;
		color: #000 !important;
	}

	.asd__day--enabled.asd__day--in-range {
		background: var(--selection_range) !important;
		opacity: 1 !important;
		color: #000 !important;
		/* border: 1px double var(--selected_border) !important; */
	}

	.asd__day--disabled.asd__day--hovered {
		/* background: var(--selection_range) !important;
		opacity: 1 !important;
		color: #000 !important; */
		/* border: 1px double var(--selected_border) !important; */
	}

	.asd__day--enabled.asd__day--hovered {
		/* background: var(--selection_range) !important;
		opacity: 1 !important; */
		/* border: 1px double var(--selected_border) !important; */
		color: #000 !important;
	}

	.asd__day--disabled.asd__day--arrival:not(.asd__day--selected),
	.asd__day--disabled.asd__day--departure:not(.asd__day--selected) {}

	.asd__day--enabled {
		background-color: var(--available) !important;
		/* color: var(--text_color) !important; */

		&:hover {
			background-color: #f5f1ec !important;
		}

		&.asd__day--disabled {
			background-color: var(--disabled) !important;
			color: var(--white);
			text-decoration: underline;
			opacity: 1 !important;
			color: #D54627;

			&.asd__day--selected {
				background-color: var(--selected) !important;
			}

			&.asd__day--in-range {
				background: var(--selection_range) !important;
				opacity: 1 !important;
				border: 0px double var(--selected_border) !important;
			}
		}

		&.asd__day--arrival {
			&:hover {
				background-color: var(--selected) !important;
			}
		}

		&.asd__day--not-available {
			background: var(--not_available) !important;
			opacity: 1 !important;
			color: #000 !important;
		}
	}

	.asd__day--selected {
		background: var(--selected) !important;
		color: #000 !important;
		opacity: 1 !important;
		/* border: 1px double var(--selected_border) !important; */

		/*Arrow display css start*/

		&.asd__day--arrival {
			background: linear-gradient(90deg, var(--selected) 50%, var(--not_available) 50%) !important;
			opacity: 1 !important;
			color: #000 !important;
		}

		&.asd__day--departure {
			background: linear-gradient(90deg, var(--not_available) 50%, var(--selected) 50%) !important;
			opacity: 1 !important;
			color: #000 !important;
		}

		&.asd__selected-date-one {
			position: relative;

			/* &:before {
				content: "";
				position: absolute;
				border-left: 12px solid transparent;
				border-top: 19px solid var(--selection_range);
				border-bottom: 20px solid var(--selection_range);
				right: 0px;
			} */
		}

		&.asd__selected-date-two {
			position: relative;

			/* &:before {
				content: "";
				position: absolute;
				border-right: 12px solid transparent;
				border-top: 19px solid var(--selection_range);
				border-bottom: 20px solid var(--selection_range);
				left: 0px;
			} */
		}

		/*Arrow display css end*/
	}

	.asd__month-table {
		.arrow {
			bottom: 0;
			left: 50%;
			margin-left: -5px;
			border-width: 5px 5px 0;
			position: absolute;
			width: 0;
			height: 0;
			border-color: transparent;
			border-style: solid;
			border-top-color: #000 !important;
		}

		.asd__week {
			display: flex;
		}
	}

}


/*
.pswp {
	display: none;
}
*/

.app-loaded #preloader {
	display: none;
}

.app-leaving #preloader {
	display: block;
	opacity: 0.6;
}



@font-face {
	font-family: 'DINRoundPro-Medi';
	src: url('RESOURCE/fonts/DINRoundPro-Medi.eot');
	src: local('RESOURCE/fonts/DINRoundPro-Medi'),
		url('RESOURCE/fonts/DINRoundPro-Medi.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/DINRoundPro-Medi.woff2') format('woff2'),
		url('RESOURCE/fonts/DINRoundPro-Medi.woff') format('woff'),
		url('RESOURCE/fonts/DINRoundPro-Medi.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'DINRoundPro';
	src: url('DRESOURCE/fonts/INRoundPro.eot');
	src: local('RESOURCE/fonts/DINRoundPro'),
		url('RESOURCE/fonts/DINRoundPro.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/DINRoundPro.woff2') format('woff2'),
		url('RESOURCE/fonts/DINRoundPro.woff') format('woff'),
		url('RESOURCE/fonts/DINRoundPro.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}



@font-face {
	font-family: 'DINRoundPro';
	src: url('RESOURCE/fonts/DINRoundPro-Bold.eot');
	src: local('RESOURCE/fonts/DINRoundPro-Bold'),
		url('RESOURCE/fonts/DINRoundPro-Bold.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/DINRoundPro-Bold.woff2') format('woff2'),
		url('RESOURCE/fonts/DINRoundPro-Bold.woff') format('woff'),
		url('RESOURCE/fonts/DINRoundPro-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

.fal {
	font-family: 'Font Awesome 5 Pro' !important;
	font-weight: 300;
}

.far {
	font-family: 'Font Awesome 5 Pro' !important;
	font-weight: 400;
}

.fab {
	font-family: 'Font Awesome 5 Brands';
	font-weight: 400;
}

body {
	font-family: var(--d-font);

	color: var(--black);
	font-size: 14px;
	background: #f4f4f4;

	@media (max-width:1580px) {
		font-size: 13px;
	}
}

.fa,
.fas {
	font-family: 'Font Awesome 5 Pro';
	font-weight: 300;
}

.checkbox input[type=checkbox]:checked+label:after {
	font-family: 'Font Awesome 5 Pro' !important;
	font-weight: 400;
}

@font-face {
	font-family: 'Font Awesome 5 Pro';
	font-style: normal;
	font-weight: 300;
	font-display: block;
	src: url("RESOURCE/fonts/fa-light-300.eot");
	src: url("RESOURCE/fonts/fa-light-300.eot?#iefix") format("embedded-opentype"),
		url("RESOURCE/fonts/fa-light-300.woff2") format("woff2"),
		url("RESOURCE/fonts/fa-light-300.woff") format("woff"),
		url("RESOURCE/fonts/fa-light-300.ttf") format("truetype"),
		url("RESOURCE/fonts/fa-light-300.svg#fontawesome") format("svg");
}

@font-face {
	font-family: 'Font Awesome 5 Brands';
	font-style: normal;
	font-weight: 400;
	font-display: block;
	src: url("RESOURCE/fonts/fa-brands-400.eot");
	src: url("RESOURCE/fonts/fa-brands-400.eot?#iefix") format("embedded-opentype"),
		url("RESOURCE/fonts/fa-brands-400.woff2") format("woff2"),
		url("RESOURCE/fonts/fa-brands-400.woff") format("woff"),
		url("RESOURCE/fonts/fa-brands-400.ttf") format("truetype"),
		url("RESOURCE/fonts/fa-brands-400.svg#fontawesome") format("svg");
}

@font-face {
	font-family: 'Font Awesome 5 Pro';
	font-style: normal;
	font-weight: 400;
	font-display: block;
	src: url("RESOURCE/fonts/fa-regular-400.eot");
	src: url("RESOURCE/fonts/fa-regular-400.eot?#iefix") format("embedded-opentype"),
		url("RESOURCE/fonts/fa-regular-400.woff2") format("woff2"),
		url("RESOURCE/fonts/fa-regular-400.woff") format("woff"),
		url("RESOURCE/fonts/fa-regular-400.ttf") format("truetype"),
		url("RESOURCE/fonts/fa-regular-400.svg#fontawesome") format("svg");
}

.container-fluid {
	width: 100%;
}

.hide-mob {
	@media (min-width:993px) {
		display: block !important;
	}

	@media (max-width:992px) {
		display: none !important;
	}


}

.show-mob {
	@media (max-width:992px) {
		display: block !important;
	}

	@media (min-width:993px) {
		display: none !important;
	}
}

.w-100 {
	width: 100%;
}

a,
input,
button,
.btn,
*,
a:focus,
input:focus,
button:focus,
.btn:focus,
.checkbox input[type=checkbox]:focus+label:before {
	outline: none !important;
	outline-offset: 0 !important;
	text-decoration: none;
}

h1,
h2 {
	font-family: var(--d-bold);
}

h3 {
	font-size: 24px;
	font-weight: bold;
	font-family: var(--d-bold);
}

h4 {
	font-size: 18px;
	font-weight: bold;
	font-family: var(--d-font);
}

h5 {
	font-size: 16px;
	font-family: var(--d-font);
}

h6,
p {
	font-family: var(--d-thin);
}

a {
	font-family: var(--d-thin);
}

.pad75 {
	padding: 50px 0 30px;

	@media (max-width:992px) {
		padding: 30px 0 20px;
	}
}

ul {
	padding: 0;
	margin: 0;

	li {
		font-family: var(--d-thin);
	}
}

.container {
	@media (min-width: 1340px) {
		width: 1170px;
	}
}

.txt-blue {
	color: var(--secondary);
}

.pb-130 {
	padding-bottom: 130px !Important;
}

.pb-240 {
	padding-bottom: 180px !Important;

	@media (max-width: 767px) {
		padding-bottom: 80px !Important;
	}
}

.p-0 {
	padding-left: 0;
	padding-right: 0;
}

.btn {
	border-radius: 0;
	font-size: 16px;
	padding: 10px 18px;

	@media (max-width:992px) {
		font-size: 14px;
		padding: 6px 10px;
	}

	&.btn-primary,
	&.btn-go,
	&.btn-warning {
		background-color: var(--primary);
		color: var(--white);
		border-color: var(--primary);

		&:hover {
			background-color: var(--secondary);
			border-color: var(--secondary);
		}

		&.btn-sm {
			font-size: 14px;
		}
	}

	&.btn-default {
		color: var(--black);
		border-color: var(--primary);

		&.btn-sm {
			font-size: 14px;
		}

		&:hover {
			border-color: var(--primary);
			background-color: var(--primary);
			color: var(--white);
		}
	}

	&.btn-sm {
		font-size: 16px;
		padding: 6px 12px;
	}

	&.btn-secondary {
		background-color: var(--secondary);
		color: var(--white);
		border-color: var(--secondary);

		&:hover {
			background-color: var(--primary);
			border-color: var(--primary);
			color: var(--white);

		}
	}

	&.btn-lg {
		height: 46px;
		font-size: 18px;
		padding: 10px 16px;
	}

	&.big-pad {
		padding: 8px 30px;
	}

	&:focus {
		outline: 0 !important;
		text-decoration: none !important;
	}

	&.btn-arrow {
		min-width: 220px;
		display: inline-flex;
		align-items: center;
		justify-content: space-between;
		text-transform: uppercase;
		font-size: 16px;
		padding: 10px 20px;
		border-radius: 0;
		letter-spacing: 1px;
		line-height: 17px;

		&:hover {
			background: var(--HoverColor);
		}

		i {
			margin: 0 0 0 10px;
		}

		@media (max-width:992px) {
			min-width: 160px;
			white-space: unset;
		}

		.far {
			opacity: .4;
			margin-left: 15px;
		}
	}
}

.form-control:focus {
	box-shadow: inherit;
	outline: none;
}

.pb-0 {
	padding-bottom: 0 !important;
}

.min-row {
	&.unit-content-bl {
		.single-unit .unit-cont-bl {
			.unit-title {
				height: 46px;
				overflow: hidden;
			}

			.heading-bl {
				height: 37px;
				overflow: hidden;
			}
		}

	}
}

.min-row {
	margin: 0 -10px;

	.col-md-7,
	.col-lg-4,
	.col-md-5,
	.col-md-6,
	.col-md-8,
	.col-md-4,
	.col-md-9,
	.col-md-3,
	.col-sm-7,
	.col-sm-5 {
		padding: 0 10px;
	}
}

.asd__wrapper--full-screen {
	z-index: 55 !important;
}

.search-bar,
.search-form-area {
	.datepicker-trigger .asd__wrapper {
		@media (max-width:767px) {
			padding-top: 75px;
		}
	}

	.datepicker-trigger .asd__wrapper,
	.box.full-xxs {
		@media (max-width:480px) {
			padding-top: 125px;
		}

		@media (min-width:481px) {
			min-width: 320px;
		}
	}
}

.flex-sp-between {
	display: flex;
	justify-content: space-between;
}

.flex-align-center {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.single-unit {
	border-radius: 0;
	width: 100%;
	padding: 0;
	margin: 0 auto 10px;
	max-width: 500px;
	transition: all 0.25s ease 0s;



	.unit-img {
		position: relative;
		border-radius: 0;
		display: block;



		img {
			width: 100%;
		}

		.fav-element {
			position: absolute;
			width: 40px;
			color: var(--primary);
			height: 40px;
			right: 10px;
			top: 10px;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			font-size: 24px;
			transition: all 0.4s;
			cursor: pointer;
		}

		.udetails {
			left: 10px;
			top: 10px;

			>span {
				line-height: 2;
				display: inline-block;
				margin-right: 5px;
				padding: 0 10px;
				background-color: rgba(153, 153, 153, .8);
				font-size: 12px;
				color: var(--white);
			}

			position: absolute;
		}
	}

	.unit-img {
		.search-unit-bluebar {
			position: relative;

			h6 {
				width: 100%;
				display: -webkit-box;
				display: flex;
				-webkit-box-pack: end;
				justify-content: flex-end;
				margin-top: 10px;
				margin-bottom: 2px;

				a {
					background: var(--primary);
					padding: 7px 10px;
					color: #fff;
					font-size: 12px;
					letter-spacing: 1px;
					border: 1px solid var(--white);


					&:hover {
						background: var(--HoverColor);
					}
				}
			}
		}
	}

	.unit-cont-bl {
		padding: 12px 10px;
		position: relative;
		margin: 0 25px;
		background-color: var(--white);
		top: -35px;
		border-radius: 5px;
		box-shadow: 0 2px 6px rgba(0, 0, 0, .2);
		min-height: 54px;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		justify-content: space-between;
		font-family: var(--d-font);

		&.vacation-homes {
			.unit-title {
				margin-bottom: 12px;
			}
		}

		@media (max-width:992px) {
			margin: 0 10px;
		}

		@media (max-width:992px) {
			padding: 15px 10px;
		}

		.unitlistingname {
			width: 100%;
			margin: 0 0 10px 0;
			//border-bottom: 1px solid rgb(0 0 0 / 20%);
			padding: 0 0 10px 0;
		}

		.flex-sp-between {
			min-height: 40px;
		}

		.unit-title {
			color: var(--textprimary);
			font-size: 20px;
			margin: 0;
			padding-bottom: 0;
			min-height: 42px;
			font-family: var(--d-thin);


			&:hover {
				color: var(--primary);
			}
		}

		span {
			width: 100%;
			display: block;
			margin: 0px 0 0px 0;
		}

		.unit-distance {
			padding: 0 15px;
			font-weight: bold;
			right: 0;
			top: 0;
			font-size: 12px;
			line-height: 30px;
			color: rgba(0, 0, 0, .7);
			background-color: var(--white);
			border-radius: 15px;
			position: absolute;
			top: -55px;
		}

		.unit-region {
			color: var(--secondary);
			font-size: 13px;
			font-family: var(--d-font);
			margin: 0 0 15px;
			min-height: 15px;
		}

		.unit-link {
			text-transform: uppercase;
			color: var(--primary);
			font-size: 12px;
			font-weight: bold;
			position: relative;
			top: 9px;

			&:hover {
				color: var(--secondary);
			}
		}

		.unit-place-only-icon {
			margin: 7px 0 5px 0;
		}

		.unit-place {
			font-size: 16px;
			font-family: var(--d-font);
			max-width: 260px;
			width: 100%;

			@media (max-width: 574px) {
				/* align-items: center;
				justify-content: center;
				max-width: 100%;
				width: 100%; */
			}

			@media all (min-width: 768px) and (max-width: 1199px) {}

			li {
				display: inline-block;
				position: relative;
				padding-left: 10px;
				margin-left: 5px;
				color: var(--textprimary);

				@media (max-width:360px) {
					font-size: 14px;
					padding-left: 3px;
				}

				&:first-child {
					padding-left: 0;
					margin-left: 0;

				}

				i {
					font-size: 18px;
					opacity: .2;
					margin-right: 3px;

					@media (max-width:360px) {
						font-size: 15px;
						margin-right: 2px;
					}
				}
			}

		}

		.box-price {
			width: auto;
			color: var(--textprimary);
			font-size: 15px;
			font-weight: 600;
			margin-top: 10px;

			@media (max-width: 574px) {
				padding: 5px 0 0;
				text-align: left;
				width: 100%;
			}

			@media all (min-width: 768px) and (max-width: 1199px) {
				padding: 5px 0 0;
				text-align: left;
				width: 100%;
			}

			.pro {
				.popover {
					.popover-content {
						li:before {
							font-family: Font Awesome\ 5 pro;
						}
					}
				}
			}
		}

		.unit-type {
			font-size: 11px;
			line-height: 1;

			li {
				display: inline-block;
				position: relative;
				padding-left: 10px;
				margin-left: 5px;

				&:first-child {
					list-style: none;
					margin-left: 0;
					padding-left: 0;

					&:before {
						display: none;
					}
				}

				&:before {
					width: 5px;
					height: 5px;
					border-radius: 50%;
					background-color: #d1c7c7;
					position: absolute;
					left: -2px;
					content: "";
					top: 3px;
				}
			}
		}



		.search-result-properties {

			li {
				display: inline-block;
				list-style: none;
				text-align: center;
				float: left;
				padding: 0 0 0 10px;

				&:first-child {
					padding-left: 0;
				}

				@media (max-width:360px) {
					padding-left: 5px;
				}

				i {
					font-size: 16px;
					color: var(--d-grey);
					font-style: normal;
					width: 28px;
					height: 28px;
					background-color: var(--grey);
					border-radius: 50%;
					display: flex;
					align-items: center;
					justify-content: center;
					position: relative;

					@media (max-width:360px) {
						font-size: 14px;
						width: 26px;
						height: 26px;
					}

					&.cross {
						&:after {
							width: 1.5px;
							height: 100%;
							position: absolute;
							content: "";
							background-color: var(--d-grey);
							-webkit-transform: rotate(-45deg);
							transform: rotate(-45deg);
						}
					}

				}
			}
		}

		.unit-dt {
			p {
				margin: 0;

				strong {
					font-size: 18px;
					font-weight: 600;
					color: var(--blue3);
				}

				span {
					font-size: 12px;
					color: #7e7e7e;
					display: block;
					line-height: 18px;
				}
			}

			a {
				color: var(--black);
			}

			.price-info {

				i {
					color: #7f7f7f;
					font-size: 12px;
					margin-left: 5px;
				}
			}
		}

		.unit-price-rat {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			align-items: center;
			margin: 15px -15px 0;
			padding: 10px 15px 0;
			border-top: 1px solid var(--grey);

			@media (max-width:360px) {
				margin: 15px -10px 0;
				padding: 10px 10px 0;
			}
		}

		.unit-rating {
			font-size: 14px;
			min-height: 22px;
			display: block;

			.stars {
				display: inline-block;
			}

			i {
				color: var(--green);
			}

			span {
				color: var(--black);
			}
		}
	}

	.unit-cont-bl.vacation-homes {
		.unit-place {
			max-width: 100%;
			justify-content: flex-start;
		}
	}

}

.flex-row-reverse {
	@media (min-width:993px) {
		flex-direction: row-reverse;
		display: flex;
	}
}

.col-md-5 {
	&.col-md-offset-1 {
		@media (max-width:992px) {
			text-align: center;
		}
	}
}

.flex-same-col {
	@media (min-width:993px) {
		display: -webkit-box;
		display: flex;
		-webkit-box-align: center;
		align-items: center;
		flex-wrap: wrap;
	}
}

.tooltip.top .tooltip-arrow {
	border-top-color: var(--grey);
}

.tooltip-inner {
	background-color: var(--grey);
	color: var(--black);

	.nowrap {
		white-space: nowrap;
	}

}

.form-control {
	&::-webkit-input-placeholder {
		/* Edge */
		color: #555;
	}

	&:-ms-input-placeholder {
		/* Internet Explorer 10-11 */
		color: #555;
	}

	&::placeholder {
		color: #555;
	}
}


.popover {
	border: 0px solid #ddd;
	box-shadow: 0 2px 6px rgba(0, 0, 0, .2);
	border-radius: 0px;
	padding: 10px;
	background: #fff;

	.arrow {
		border-top-color: #ddd;
	}

	.popover-title {
		padding: 0 0 10px 0;
		margin: 0;
		font-size: 13px !important;
		background-color: transparent;
		border-bottom: none;
		border-radius: 5px 5px 0 0;
		color: var(--secondary);
		font-family: var(--d-font);
		font-weight: 600;
		border-bottom: 1px solid var(--secondary);
		text-transform: uppercase;
	}

	.popover-content {
		ul {

			li {
				font-size: 12px;
				font-weight: 500;
				list-style: unset;
				padding-left: 0;
				font-family: var(--d-font);
				color: #555;
			}
		}
	}
}


.rentRate {
	font-size: 14px;

	.popover {
		font-size: 12px;
		background-color: #fff6df;
		max-width: 250px;
		width: 100%;
		margin-left: -105px;
		border: 1px solid #febb02 !important;
		z-index: 2;

		@media (max-width: 767px) {
			margin-left: -95px;
		}

		.arrow {
			border-top-color: #ddd;
			border-top-color: #febb02 !important;
			border-bottom-width: 0;
			right: 8px;
			left: unset !important;

			@media (max-width: 767px) {
				right: 18px;
			}

			&:after {
				border-top-color: #febb02 !important;
				border-bottom-color: #febb02 !important;
			}
		}

		.popover-title {
			text-transform: capitalize;
			padding: 0 0 10px 0;
			margin: 0;
			font-size: 12px !important;
			background-color: transparent;
			border-bottom: none;
			border-radius: 5px 5px 0 0;
			font-family: var(--d-font);
			font-weight: 400;
			margin-bottom: 10px;
			padding-bottom: 4px;
			border-bottom: 1px solid #febb02;
			color: #47403a;
		}

		.popover-content {
			ul {
				list-style: none !important;

				li {
					font-size: 11px;
					font-weight: 500;
					list-style: unset;
					padding-left: 0;
					font-family: var(--d-font);
					color: #47403a;
					position: relative;
					padding-bottom: 10px;
					padding-left: 15px;

					&:before {
						display: block;
						content: " ";
						font-family: "Font Awesome 5 pro";
						content: "\f054";
						font-weight: 900;
						color: #E2D69E;
						font-size: 12px !important;
						position: absolute;
						left: 0;
						right: 0;
					}
				}
			}
		}
	}
}

.box-price {
	font-size: 14px;

	.popover {
		font-size: 12px;
		background-color: #fff6df;
		max-width: 250px;
		width: 100%;
		margin-left: -105px;
		border: 1px solid #febb02 !important;
		z-index: 2;

		@media (max-width: 374px) {
			max-width: 218px;
		}

		@media all (min-width:375px) and (max-width: 767px) {
			margin-left: -95px;
		}

		@media all (min-width: 992px) and (max-width: 1199px) {
			margin-left: -95px;
			max-width: 230px;
		}


		.arrow {
			border-top-color: #ddd;
			border-top-color: #febb02 !important;
			border-bottom-width: 0;
			right: 8px;
			left: unset !important;

			@media (max-width: 767px) {
				right: 18px;
			}

			&:after {
				border-top-color: #febb02 !important;
				border-bottom-color: #febb02 !important;
			}
		}

		.popover-title {
			text-transform: capitalize;
			padding: 0 0 10px 0;
			margin: 0;
			font-size: 12px !important;
			background-color: transparent;
			border-bottom: none;
			border-radius: 5px 5px 0 0;
			font-family: var(--d-font);
			font-weight: 400;
			margin-bottom: 10px;
			padding-bottom: 4px;
			border-bottom: 1px solid #febb02;
			color: #47403a;
		}

		.popover-content {
			ul {
				list-style: none !important;

				li {
					font-size: 11px;
					font-weight: 500;
					list-style: unset;
					padding-left: 0;
					font-family: var(--d-font);
					color: #47403a;
					position: relative;
					padding-bottom: 10px;
					padding-left: 15px;

					&:before {
						display: block;
						content: " ";
						font-family: "Font Awesome 5 Free";
						content: "\f054";
						font-weight: 900;
						color: #E2D69E;
						font-size: 12px !important;
						position: absolute;
						left: 0;
						right: 0;
					}
				}
			}
		}
	}
}



.tooltip.in {
	opacity: 1;
}

h1 {
	font-size: 40px;
	margin-top: 0;
}

.privacy-bl {
	a {
		position: relative;
		top: -2px;
	}
}

.section-title {
	margin-bottom: 50px;
	max-width: 610px;

	@media (max-width:992px) {
		max-width: inherit;
	}

	/*&.align-left {
		text-align: left;

		@media (max-width:992px) {
			text-align: center;
		}
	}*/

	h5 {
		color: var(--secondary);
		font-weight: 700;
		margin-bottom: 25px;
		display: block;
		line-height: 1.5;
		margin-top: 0;

		@media (max-width:992px) {
			font-size: 14px;
		}
	}

	h1 {
		display: block;

		@media (max-width:992px) {
			font-size: 28px;
		}
	}

	&:after {
		background-color: var(--secondary);
		width: 90px;
		height: 2px;
		border-radius: 5px;
		content: "";
		display: inline-block;
		position: relative;
	}
}

.bg-grey-strip {
	background-color: var(--grey4);
	position: relative;
}

.next-prev-btns {
	position: relative;

	.owl-nav {
		position: absolute;
		top: 45%;
		left: 0;
		width: 100%;

		button {
			width: 25px;
			height: 25px;
			border-radius: 50%;
			border-radius: 50%;
			box-shadow: 0 0 8px rgba(0, 0, 0, .21);
			background-color: transparent !important;
			position: absolute;
			left: 0;
			box-shadow: none;

			@media (max-width:992px) {
				left: -6px !important;
			}

			span {
				font-family: "Font Awesome 5 Free";
				font-weight: 900;
				-webkit-font-smoothing: antialiased;
				display: inline-block;
				font-style: normal;
				font-variant: normal;
				text-rendering: auto;
				line-height: 1;
				color: transparent;

				&:before {
					color: #153154 !important;
					font-family: "Font Awesome 5 Free";
					position: relative;
					left: 2px;
					font-size: 25px;
					top: 1px;
				}
			}

			&.owl-next {
				position: absolute;
				right: -20px;
				left: initial;

				@media (max-width:992px) {
					right: -6px !important;
					left: unset !important;
				}

				span {

					&:before {
						content: "\f054";
						left: 3px;
					}
				}
			}

			&.owl-prev {
				left: -20px;

				span {

					&:before {
						content: "\f053";

					}
				}
			}
		}
	}
}

.pt-0 {
	padding-top: 0 !important;
}

.pad30 {
	padding: 30px 0;
}

.bg-li-blue {
	background-color: var(--li-blue);
}

.link {
	text-transform: uppercase;
	color: var(--primary);
	font-size: 12px;
	font-weight: 700;

	&:hover {
		color: var(--secondary);
	}
}

.pt-m-0 {
	@media (max-width:992px) {
		padding-top: 0 !important;
	}
}

.pad10 {
	padding: 10px 0;
}

p {
	font-family: var(--d-thin);
	color: #666;
	line-height: 30px;
	margin-bottom: 30px;
	font-size: 17px;
	line-height: 30px;

	@media (max-width:992px) {
		font-size: 14px;
		line-height: 20px;
	}
}

.stellenausschreibung {

	p {
		margin-top: 20px;
		margin-bottom: 10px;
	}



	font-family: var(--d-thin);
	color: #666;
	line-height: 30px;
	margin: 30px;
	font-size: 17px;
	line-height: 30px;

	@media (max-width:992px) {
		font-size: 14px;
		line-height: 20px;
	}
}

.checkbox label:before {
	width: 20px;
	height: 20px;
	margin-right: 15px;
}

.mb-40 {
	margin-bottom: 40px;
}

.mb-20 {
	margin-bottom: 20px;
}

.left-mob-align {
	text-align: left !important;
}

.pad60 {
	padding: 60px 0;

	@media (max-width:550px) {
		padding: 40px 0;
	}
}

.aside-widget {
	padding: 20px 15px;
	background-color: var(--grey4);
	font-size: 13px;

	&:last-child {
		border-bottom: none;
	}

	h3 {
		margin-bottom: 18px;
		margin-top: 0;
		color: var(--secondary);
	}


}

.three-units {

	.col-md-4,
	.col-sm-4 {
		display: none;

		&:first-child {
			display: block !important;
		}

		&:nth-child(2),
		&:nth-child(3) {
			display: block !important;
		}
	}
}

.contact-bl {
	display: flex;
	margin-bottom: 15px;
	position: relative;
	padding-left: 60px;
	min-height: 40px;
	align-items: center;
	font-size: 18px;
	font-weight: var(--d-font);

	&:last-child {
		margin-bottom: 0;
	}

	a {
		color: var(--black);

		&:hover {
			color: var(--primary);
		}
	}


	i {
		color: var(--white);
		background-color: var(--secondary);
		padding: 5px;
		width: 40px;
		height: 40px;
		border-radius: 50%;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		left: 0;
		top: 2px;
		box-shadow: 0 0 10px rgba(0, 0, 0, .18);
		font-size: 18px;
	}
}

#back_to_top {
	position: fixed;
	bottom: 50px;
	z-index: 50;
	border-radius: 0;
	border: none;
	display: flex;
	align-items: center;
	font-size: 16px;
	font-size: 0px;
	background: var(--secondary);
	color: rgb(255, 255, 255);
	width: 50px;
	height: 50px;
	padding: 0px 15px;
	right: 15px;
	box-shadow: 0 1px 10px 0 rgb(0 0 0 / 50%);

	&:hover {
		background: var(--HoverColor);
	}

	i {
		margin-right: 10px;
		font-size: 20px;
	}

	&:hover {
		border-color: var(--primary2);
	}

	@media (max-width:992px) {
		right: -54px;

		i {
			margin-right: 20px;
		}
	}
}

.checkbox-success input[type=checkbox]:checked+label:before {
	background-color: var(--primary) !important;
	border-color: var(--primary) !important;
}

.checkbox label:after {
	left: 2px;
}

.asd__mobile-close {
	width: 35px;
	font-size: 24px;
	height: 35px;

	.asd__mobile-close-icon {
		position: relative;
		top: -5px;
	}
}

.section-title.style2 {
	max-width: inherit;
}


.owl-carousel.owl-hidden {
	opacity: 100 !important;
}

/**** Select Dropdown Css *****/

.form-bx-area {
	select {
		@media (max-width: 992px) {
			background-position: 100% 15px;
		}
	}
}

.form-bx-area {
	.box.layout-column {
		select {
			background-position: 98% 15px;
		}
	}
}

select {
	/* for Firefox */
	-moz-appearance: none;
	/* for Chrome */
	-webkit-appearance: none;
	background-image: url(RESOURCE/img/dropdown-arrow.png) !important;
	background-repeat: no-repeat;
	background-size: 11px;
	background-position: 98% 15px;
	overflow: visible !important;
	padding-right: 20px !important;
}

.sort-price-new {
	.form-control {
		background-position: 93% 15px;
	}
}

.booking-view {
	.form-control {
		background-position: 98% 15px !important;
	}
}

.wrapper {
	max-width: 1240px;
	margin: 0 auto;
	width: 100%;
	background: #fff;

}

.search-form-area {
	display: flex;
	align-items: center;
	justify-content: space-between;

	>.facility-box {
		.box {
			max-width: 460px;
			max-height: 320px;
			overflow-y: scroll;
		}
	}

	@media (max-width:1140px) {
		flex-wrap: wrap;
	}

	@media (min-width:1141px) {
		padding-left: 0;
	}

	.form-flex.region-box {

		.box {
			max-width: 350px;
			width: 100%;
		}

		.Nordsee,
		.Ostsee {
			width: 50%;

			span {
				width: 100%;
				display: block;
				padding: 0;
				background: #ddd;
				margin: 0 0 10px 0;

				a {
					padding: 10px !important;
					background: transparent;
					text-decoration: none !important;
					color: #000;
					color: var(--black);

					&.active {
						color: #fff;
						background-color: #337ab7;
					}
				}
			}
		}


		.list-group {
			max-width: 100%;

			.list-group-item {
				width: 100% !important;
				border: none;
				padding: 10px 10px;
				text-decoration: underline;
			}
		}

	}

	.form-flex.region-box {
		@media (max-width:767px) {
			width: 100% !important;
		}
	}

	.form-flex {
		padding: 0 5px;
		width: 20%;


		@media (max-width:1140px) {
			width: 50% !important;
			padding: 5px 5px;
		}

		@media all and (min-width: 768px) and (max-width: 1140px) {
			width: 48% !important;
			padding: 10px 0;
		}

		&:first-child {
			border-left: none;
		}

		.list-group {
			max-width: 300px;
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			margin: 0;

			.checkbox label:after {
				left: 0;
				top: 0;
			}

			ul {
				list-style: none;
				-moz-column-count: 2;
				-moz-column-gap: 15px;
				-webkit-column-count: 2;
				-webkit-column-gap: 15px;
				column-count: 2;
				grid-column-gap: 15px;
				column-gap: 15px;
				display: block;


				li {
					width: 100%;
					max-width: 190px;
				}

				.Schlafzimmer-li {
					padding: 0;

					select {
						padding: 5px;
						border: 1px solid rgb(85 85 85 / 30%);
						min-height: 35px;
						color: #555;
						font-size: 13px;
						background-position: 97% 14px;
						max-width: 190px;
						margin: 5px 0;
						width: 100%;

					}
				}

				.Unterkunft-li {
					input {
						padding: 5px;
						border: 1px solid rgb(85 85 85 / 30%);
						min-height: 35px;
						color: #555;
						font-size: 13px;
						background-position: 136px 14px;
						max-width: 190px;
						margin: 5px 0;
						width: 100%;
					}
				}
			}

			.list-group-item {
				width: 50%;
				border-radius: 0;
			}
		}

		.form-bx-area {
			position: relative;
			padding-right: 85px;
			padding-left: 2px;
			border-bottom: transparent;
			width: 100%;
			padding: 0;

			&.pinarea {
				@media (min-width:1240px) {
					padding-right: 0;
					min-width: 100%;
				}
			}

			@media (max-width:1340px) {
				padding-right: 0;
			}

			button.form-control {
				width: 100%;

				@media (max-width:1340px) {
					font-size: 18px;
				}

				&.datepicker-open {
					padding-right: 35px;
				}
			}


		}

		.fa,
		.far {
			position: absolute;
			right: 15px;
			top: 10px;
			color: var(--black);
			z-index: 2;
			font-size: 20px;
			pointer-events: none;

			@media (max-width:1340px) {}

			@media (max-width:1140px) {
				top: 10px;

			}

			&.shadow {
				font-size: 65px;
				top: 0px;
				color: var(--grey);
				z-index: 1;
				right: 14px;

				@media (max-width:1340px) {
					font-size: 48px;
				}

				@media (max-width:1140px) {
					font-size: 37px;
					top: -4px;
					opacity: .5;
				}
			}

			&.fa-map-marker-alt {
				right: 8px;

				@media (max-width:1140px) {
					right: 0;
				}

				&.shadow {
					@media (max-width:1140px) {
						right: 9px !important;
					}
				}
			}
		}

		.date-box {
			.form-control {
				padding-right: 35px;
			}
		}


		.form-control {
			background-color: var(--white);
			border: none;
			border-radius: 0;
			width: 100%;
			padding: 28px 0 15px;

			height: inherit;
			box-shadow: inherit;
			font-family: var(--d-font);
			padding: 0 5px;
			font-size: 14px;
			text-align: left;
			height: 40px;
			background-image: none !important;


			@media (max-width:1340px) {
				font-size: 14px !important;
				padding: 0 10px;
			}

			@media (max-width:1140px) {
				font-size: 14px !important;
				padding: 0 10px;
			}

			@media (max-width:480px) {
				font-size: 12px !important;
			}
		}

		.Mehr-Suchoptionen {
			.form-control {
				background: #bfd5dc;
				border: 1px solid #fff;
				text-align: center;
			}

			.body {
				position: relative;

				.close-btn {
					position: absolute;
					top: 5px;
					width: 20px;
					right: 5px;
					height: 20px;
					background: #1c4867;
					color: #fff;
					display: flex;
					align-items: center;
					justify-content: center;
					border-radius: 100px;
					font-size: 10px;

					&:hover {
						background: var(--HoverColor);
					}
				}
			}

			.list-group {
				max-width: 100%;
			}

			.dropdown-menu {
				margin: 10px 0 0;
				padding: 15px;
				z-index: 999;
				background: #fff;
				border: 1px solid #ccc;
				border-radius: 8px;
				box-shadow: 0 0 8px 0 rgb(0 0 0 / 20%);
				width: 410px;
				left: -120px;

				&:before {
					position: absolute;
					width: 8px;
					height: 8px;
					z-index: -1;
					content: "";
					-webkit-transform: rotate(45deg);
					transform: rotate(45deg);
					background: #fff;
					top: -5px;
					left: 0;
					right: 0;
					margin: 0 auto;
				}


				@media (max-width: 767px) {
					max-width: 100%;
					left: 0;
					padding: 15px;
					width: 100%;
					height: auto;
				}


				li {
					width: 50%;
					display: inline-block;
					float: left;

					@media (max-width: 767px) {
						width: 100%;
					}
				}
			}

			.checkbox {
				margin: 5px 0;

				label {
					padding-left: 10px;
					color: #555;
					font-size: 13px;
				}
			}

			.box {
				min-width: 395px;
				width: 100%;
				max-width: 395px;

				@media (max-width: 400px) {
					min-width: 370px;
					max-width: 370px;
				}

				@media (max-width: 374px) {
					min-width: 320px;
					max-width: 320px;
				}
			}
		}


	}

	.form-flex.date-box {
		width: 35%;

		@media (max-width: 767px) {
			width: 100% !important;
		}

		.box.full-xxs {
			@media (max-width: 480px) {
				overflow: auto;

			}
		}

		.datepicker-trigger {
			.asd__wrapper {
				@media (max-width: 767px) {
					padding-top: 0 !important;
					margin: 0 auto !important;
				}
			}
		}
	}

	.form-flex.form-btn {
		@media (max-width: 767px) {
			width: 100% !important;
		}
	}

	.form-btn {

		@media (max-width:1140px) {
			width: 100%;
			text-align: center;
		}

		@media all and (min-width: 768px) and (max-width: 1140px) {
			width: 48%;
			text-align: center;
		}

		.btn {
			-webkit-box-align: center;
			align-items: center;
			display: -webkit-box;
			display: flex;
			-webkit-box-pack: center;
			font-size: 14px;
			border-radius: 0;
			font-weight: 500;
			position: relative;
			margin-left: 0;
			height: 40px;
			border: 1px solid #fff;
			background: transparent;
			padding: 0 10px;
			text-transform: uppercase;
			letter-spacing: 1.5px;

			&:hover {
				background: #fff;
				color: #555;
			}

			@media (max-width:1140px) {
				font-size: 16px;
				border-radius: 0;
				position: relative;
				height: 45px;
				width: 100%;
			}

			i {
				margin-left: 0;
				color: #fff;
				font-size: 15px;
				margin-right: 0;
				margin-top: 2px;

				@media (max-width:1140px) {
					font-size: 16px;
					margin-left: 0;
					margin-top: 4px;
				}
			}

			&:hover i {
				color: #555;
			}


		}
	}
}

.search-box-home {
	position: absolute;
	bottom: 0;
	text-align: center;
	width: 100%;
	z-index: 5;
	padding: 20px 10px;
	background: var(--primary);

	@media (max-width:1140px) {
		position: relative;
		background: #1c4867;
		background: var(--secondary);
		padding: 0 5px;
	}

}

.searchbar {

	display: inline-block;
	text-align: left;
	background-color: transparent;
	border-radius: 0px;
	width: 100%;

	@media (max-width:1140px) {
		width: 100%;
		padding: 10px 10px;
	}

	@media (max-width: 767px) {
		padding: 20px 0;
	}

	/*@media (min-width:993px) {
				max-width: 1200px;
				width: 100%;
			}*/


	.searchbox {
		padding: 0;
		position: relative;
		right: -1px;


		@media (max-width:1140px) {
			padding-top: 0;
			display: inline-block;
			right: 0;
			width: 100%;
		}

		input,
		.search-btn,
		select {
			border-radius: 0;
		}

		.search-btn {
			width: 100%;
		}

		.search-popup-trigger {
			cursor: pointer;
		}

		.form-control:disabled,
		.form-control[readonly] {
			background-color: white;
		}
	}
}


.pb-20 {
	padding-bottom: 20px !important;
}

.datepicker-ext-row {
	display: flex;
	align-items: center;
	margin: 20px 0 0 0 !important;

	@media (max-width: 767px) {
		flex-wrap: wrap;
		max-width: 300px;
		margin: 20px auto !important;
	}

	span {
		font-size: 15px;

		@media (max-width: 767px) {
			margin: 0 0 15px 0;
			max-width: 90px;
			display: inline-block;
			width: 100%;
		}
	}

	select {
		height: 40px;
		max-width: 130px;
		width: 100%;
		border-radius: 0;
		padding-left: 5px;
		padding-right: 5px;
		margin: 0 20px 0 10px;
		border: 1px solid rgba(0, 0, 0, .2);
		background-position: 96% 16px;

		@media (max-width: 767px) {
			margin: 0 20px 10px 10px;
			max-width: 160px;
		}

		@media all and (min-width: 400px) and (max-width: 480px) {
			max-width: 180px;
		}
	}
}



.unit-proposals {
	h2 {
		display: block;

		span {
			i {
				font-style: normal;
				margin: 0 0 0 5px;
			}
		}
	}
}

.pl-0 {
	padding-left: 0 !important;
}

.pr-0 {
	padding-right: 0 !important;
}

.pt-30 {
	padding-top: 30px !important;
}

.pb-30 {
	padding-bottom: 30px !important;
}

.mb-0 {
	margin-bottom: 0 !important;
}

.mb-40 {
	margin-bottom: 40px !important;
}

.mb-20 {
	margin-bottom: 20px !important;
}

.mt-0 {
	margin-top: 0 !important;
}

.page-title {
	.inner-pag-title {
		font-size: 36px;
		color: #1c4867;
		color: var(--secondary);
		line-height: normal;
		margin: 0 0 30px;
		position: relative;
		font-weight: 600;

		@media (max-width: 991px) {
			font-size: 30px;
		}

		&:after {
			display: block;
			background: #bfd5dc;
			content: " ";
			width: 100%;
			height: 2px;
			margin: 10px 0 0;
		}
	}
}

.newsletter-alert-info>p+p {
	margin-top: 20px !important;
}

.pswp__caption__center {
	@media (max-width: 767px) {
		margin-bottom: 60px !important;
		text-align: center !important;
	}
}



.pswp--touch .pswp__button--arrow--left,
.pswp--touch .pswp__button--arrow--right {
	visibility: unset !important;
}

.facility-text {
	font-family: var(--d-thin);
	color: #666;
	font-size: 17px;
	line-height: 30px;
}

.box {
	.guestbox {
		.gb-td {
			.btn-default {
				line-height: 23px;
				padding: 0 0 4px;
				display: flex;
				align-items: center;
				justify-content: center;
				font-family: var(--d-font);
			}
		}
	}
}

.region-map {
	.unit-map {
		padding-bottom: 20px;
		height: 500px;
	}
}

.quoteselect {
	display: inline-block;
	position: absolute;
	left: 290px;

	@media(max-width: 768px) {
		left: 190px;
	}
}

.weatherwidget-bl {
	background: #f9f9f9;
	padding-bottom: 50px;
	pointer-events: none;
}

.bold {

	font-family: "Europa-Bold";

}

.fa {
	&.fa-star {
		&:before {
			content: "";
			background-image: url(RESOURCE/img/star-solid.svg);
			width: 24px;
			height: 24px;
			display: inline-block;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;
		}
	}
}

.fa {
	&.fa-star-half-alt {
		&:before {
			content: "";
			background-image: url(RESOURCE/img/star-half-stroke-solid.svg);
			width: 24px;
			height: 24px;
			display: inline-block;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;
		}
	}
}

.fa {
	&.fa-star-o {
		&:before {
			content: "";
			background-image: url(RESOURCE/img/star-regular.svg);
			width: 24px;
			height: 24px;
			display: inline-block;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;
		}
	}
}