.static {
	display: block;
}


.inner-banner {
	/* background-image: url(RESOURCE/img/inner-banner-2.jpg); */
	position: relative;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	height: 100%;
	position: relative;
	margin-bottom: 0;

	img {
		width: 100%;
		object-fit: cover;
		height: auto;

		@media (max-width: 1920px) {
			height: calc(100vh - 135px);
		}

		@media (max-width: 1140px) {
			height: 100%;
		}
	}


	@media (max-width: 767px) {
		margin-top: 135px;
	}

	.page-title {
		position: static;
		width: 100%;
		left: 0;
		z-index: 2;
		margin: 0;
		border-bottom: none;

		@media (max-width: 767px) {
			bottom: -72px;
			top: unset;
		}

		@media all and (min-width: 768px) and (max-width: 991px) {
			top: unset;
			bottom: -72px;

		}


		h1 {
			font-size: 36px;
			margin-bottom: 0;
			color: var(--secondary);


			@media (max-width:992px) {
				font-size: 30px;
			}
		}

	}
}

/* .inner-banner.region-7468 {
	background-image: url(RESOURCE/img/Langeoog-Headerbild.jpg);
}

.inner-banner.region-7467 {
	background-image: url(RESOURCE/img/Bensersiel-Headerbild.jpg);
}

.inner-banner.region-7473 {
	background-image: url(RESOURCE/img/Boltenhagen-Headerbild-1.jpg);
}

.inner-banner.region-7476 {
	background-image: url(RESOURCE/img/Borgerende-Headerbild.jpg);
}

.inner-banner.region-7477 {
	background-image: url(RESOURCE/img/Graal-Muritz-Headerbild.jpg);
}

.inner-banner.region-7478 {
	background-image: url(RESOURCE/img/Großenbrode-Headerbild.jpg);
}

.inner-banner.region-7472 {
	background-image: url(RESOURCE/img/Kuhlungsborn-Headerbild.jpg);
}

.inner-banner.region-7469 {
	background-image: url(RESOURCE/img/Schillig-Headerbild.jpg);
}

.inner-banner.region-7479 {
	background-image: url(RESOURCE/img/Travemunde-Headerbild.jpg);
} 

.inner-banner.region-7470 {
	background-image: url(RESOURCE/img/Wangerooge-Headerbild.jpg);
	height: 600px;

	@media (max-width: 1920px) {
		height: calc(100vh - 135px);
	}


}

 .inner-banner.region-7475 {
	background-image: url(RESOURCE/img/Usedom-Headerbild.jpg);
}

.inner-banner.region-7480 {
	background-image: url(RESOURCE/img/Wismar-Headerbild.jpg);
}

.inner-banner.region-7471 {
	background-image: url(RESOURCE/img/Wremen-Headerbild.jpg);
}

.inner-banner.region-7464 {
	background-image: url(RESOURCE/img/Borkum-Headerbild.jpg);
}

.inner-banner.region-7474 {
	background-image: url(RESOURCE/img/Rugen-Headerbild.jpg);
}
 */

/* .inner-banner.fur-vermieter-banner {
	background-image: url(RESOURCE/img/Fur-Vermieter-banner.jpg);
}

.inner-banner.covid-banner {
	background-image: url(RESOURCE/img/covid-banner.jpg);
	background-position: 0 -170px;

	@media (max-width: 1199px) {
		background-position: center;
	}
}


.inner-banner.kontakt-banner {
	background-image: url(RESOURCE/img/kontakt-banner.jpg);
	background-position: top center;
} */

.unit-content-bl {
	.single-unit {
		position: relative;
		transition: box-shadow 0.4s;

		&:hover {
			box-shadow: 0 3px 20px 0 rgb(0 0 0 / 50%);
		}

		.object-info {
			span {
				display: flex;
				align-items: center;
				justify-content: center;
				height: auto;
				padding: 10px;
				position: absolute;
				z-index: 1;
				top: 0;
				right: 0;
				font-size: 12px;
				background: rgb(28 72 103 / 80%);
				color: #fff;
			}
		}
	}
}

h2 {
	font-size: 24px;
	color: #000;
	color: var(--textprimary);
	display: flex;
	align-items: baseline;
	font-weight: 600;
	line-height: normal;
	justify-content: flex-start;
	margin: 0 0 30px 0;
	flex-wrap: wrap;

	@media (max-width: 767px) {
		margin-top: 0;
	}
}

.aparthotel-banner {
	position: relative;
	min-height: 100%;
	z-index: 3;

	@media (max-width:767px) {
		min-height: 500px;
		margin-top: 123px;
	}

	.aparthotel-page-name {
		position: absolute;
		z-index: 2;
		text-align: center;
		width: 100%;
		padding: 0 0 0 0;

		img {
			margin: 0 auto;
		}

		h1 {
			color: var(--textprimary);
			font-size: 24px;
			text-transform: uppercase;
			font-weight: 800;
			display: block;
			padding: 20px 25px;
			font-family: var(--d-bold);
			background: rgb(255 255 255 / 60%);
			width: auto;
			max-width: 100%;
			margin: 0 auto;

			@media (max-width: 767px) {
				font-size: 16px;
			}
		}
	}

	.nxt-prev-btns {
		position: relative;

		.owl-nav {
			position: absolute;
			top: calc(50% - 50px);
			left: 0;
			width: 100%;

			@media (max-width: 767px) {
				top: calc(50% - 25px);
			}



			button {
				position: absolute;
				left: 40px;
				font-size: 45px;

				@media (max-width:767px) {
					left: 15px;
					font-size: 28px;
				}

				span {
					display: inline-block;
					font-style: normal;
					font-variant: normal;
					text-rendering: auto;
					line-height: 1;
					color: transparent;

					&:before {
						color: var(--white);
						font-family: "Font Awesome 5 Pro";
						position: relative;
						left: 3px;
					}
				}

				&:hover {
					span {}
				}

				&.owl-next {
					position: absolute;
					right: 40px;
					left: initial;

					@media (max-width:767px) {
						right: 10px;
					}

					span {

						&:before {
							content: "\f054";
							left: 8px;
						}
					}
				}

				&.owl-prev {
					span {

						&:before {
							content: "\f053";

						}
					}
				}
			}
		}
	}

	.item {
		width: 100%;
		height: 100%;
		background-size: cover;
		background-position: no-repeat;
		background-position: center;

		@media (max-width:992px) {
			/* height: calc(100vh - 80px); */
		}

		img {
			width: 100%;
			object-fit: cover;
			height: auto;

			@media (max-width: 1920px) {
				height: calc(100vh - 135px);
			}

			@media (max-width: 1140px) {
				height: 100%;
			}
		}

		/* &.banner1 {
			background-image: url(RESOURCE/img/aparthotel-banner-1.jpg);

			@media (max-width:992px) {
				background-image: url(RESOURCE/img/aparthotel-banner-1.jpg);
			}
		}

		&.banner2 {
			background-image: url(RESOURCE/img/aparthotel-banner-2.jpg);

			@media (max-width:992px) {
				background-image: url(RESOURCE/img/aparthotel-banner-2.jpg);
			}
		}

		&.banner3 {
			background-image: url(RESOURCE/img/aparthotel-banner-3.jpg);

			@media (max-width:992px) {
				background-image: url(RESOURCE/img/aparthotel-banner-3.jpg);
			}
		}

		&.banner4 {
			background-image: url(RESOURCE/img/aparthotel-banner-4.jpg);

			@media (max-width:992px) {
				background-image: url(RESOURCE/img/aparthotel-banner-4.jpg);
			}
		}

		&.banner5 {
			background-image: url(RESOURCE/img/aparthotel-banner-5.jpg);

			@media (max-width:992px) {
				background-image: url(RESOURCE/img/aparthotel-banner-5.jpg);
			}
		}

		&.banner6 {
			background-image: url(RESOURCE/img/aparthotel-banner-6.jpg);

			@media (max-width:992px) {
				background-image: url(RESOURCE/img/aparthotel-banner-6.jpg);
			}
		}

		&.banner7 {
			background-image: url(RESOURCE/img/aparthotel-banner-7.jpg);

			@media (max-width:992px) {
				background-image: url(RESOURCE/img/aparthotel-banner-7.jpg);
			}
		}

		&.banner8 {
			background-image: url(RESOURCE/img/aparthotel-banner-8.jpg);

			@media (max-width:992px) {
				background-image: url(RESOURCE/img/aparthotel-banner-8.jpg);
			}
		} */

	}


	.owl-dots {
		justify-items: center;
		position: absolute;
		max-width: 100%;
		width: 100%;
		left: 0;
		right: 0;
		margin: 0 auto;
		flex-wrap: wrap;
		flex-direction: initial;
		bottom: 100px;
		text-align: center;
		display: block;

		@media (max-width: 1140px) {
			bottom: 20px;
		}

		.owl-dot {
			margin: 0 5px;
			opacity: 0.5;

			span {
				width: 10px;
				height: 10px;
				background: #fff;
				display: block;
				border-radius: 100px;

			}

			&.active {
				opacity: 1;

			}
		}
	}

}

.das-restaurant-banner {
	margin: 0 0 30px 0;


	.owl-item {
		img {
			height: 600px;
			object-fit: cover;

			@media (max-width: 991px) {
				height: 450px;
			}

			@media (max-width: 574px) {
				height: 250px;
			}
		}
	}

	.nxt-prev-btns {
		position: relative;

		.owl-nav {
			position: absolute;
			top: calc(50% - 50px);
			left: 0;
			width: 100%;

			@media (max-width: 767px) {
				top: calc(50% - 25px);
			}



			button {
				position: absolute;
				left: 40px;
				font-size: 45px;

				@media (max-width:767px) {
					left: 15px;
					font-size: 28px;
				}

				span {
					display: inline-block;
					font-style: normal;
					font-variant: normal;
					text-rendering: auto;
					line-height: 1;
					color: transparent;

					&:before {
						color: var(--white);
						font-family: "Font Awesome 5 Pro";
						position: relative;
						left: 3px;
					}
				}

				&:hover {
					span {}
				}

				&.owl-next {
					position: absolute;
					right: 40px;
					left: initial;

					@media (max-width:767px) {
						right: 10px;
					}

					span {

						&:before {
							content: "\f054";
							left: 8px;
						}
					}
				}

				&.owl-prev {
					span {

						&:before {
							content: "\f053";

						}
					}
				}
			}
		}
	}
}

.aparthotel-waterkant-suites {
	/* .item {


		&.banner1 {
			background-image: url(RESOURCE/img/waterkant-suites-banner-1.jpg);
		}

		&.banner2 {
			background-image: url(RESOURCE/img/waterkant-suites-banner-4.jpg);
		}

		&.banner3 {
			background-image: url(RESOURCE/img/waterkant-suites-banner-3-1.jpg);
		}

		&.banner4 {
			background-image: url(RESOURCE/img/waterkant-suites-banner-4-1.jpg);
		}

		&.banner5 {
			background-image: url(RESOURCE/img/waterkant-suites-banner-5-1.jpg);
		}

		&.banner6 {
			background-image: url(RESOURCE/img/waterkant-suites-banner-6-1.jpg);
		}

		&.banner7 {
			background-image: url(RESOURCE/img/waterkant-suites-banner-7.jpg);
		}

		&.banner8 {
			background-image: url(RESOURCE/img/waterkant-suites-banner-8.jpg);
		}
	} */
}

.aparthotel-ostseeallee-boltenhagen {
	/* .item {
		&.banner1 {
			background-image: url(RESOURCE/img/ostseeallee-boltenhagen-banner-1.jpg);

			@media (max-width:992px) {
				background-image: url(RESOURCE/img/ostseeallee-boltenhagen-banner-1.jpg);
			}
		}

		&.banner2 {
			background-image: url(RESOURCE/img/ostseeallee-boltenhagen-banner-2.jpg);

			@media (max-width:992px) {
				background-image: url(RESOURCE/img/ostseeallee-boltenhagen-banner-2.jpg);
			}
		}

		&.banner3 {
			background-image: url(RESOURCE/img/ostseeallee-boltenhagen-banner-3.jpg);

			@media (max-width:992px) {
				background-image: url(RESOURCE/img/ostseeallee-boltenhagen-banner-3.jpg);
			}
		}

		&.banner4 {
			background-image: url(RESOURCE/img/ostseeallee-boltenhagen-banner-4.jpg);

			@media (max-width:992px) {
				background-image: url(RESOURCE/img/ostseeallee-boltenhagen-banner-4.jpg);
			}
		}

		&.banner5 {
			background-image: url(RESOURCE/img/ostseeallee-boltenhagen-banner-5.jpg);

			@media (max-width:992px) {
				background-image: url(RESOURCE/img/ostseeallee-boltenhagen-banner-5.jpg);
			}
		}

		&.banner6 {
			background-image: url(RESOURCE/img/ostseeallee-boltenhagen-banner-6.jpg);

			@media (max-width:992px) {
				background-image: url(RESOURCE/img/ostseeallee-boltenhagen-banner-6.jpg);
			}
		}
	} */
}


/* .aparthotel-ostseeheilbad-travemunde {
	.item {
		&.banner1 {
			background-image: url(RESOURCE/img/ostseeheilbad-travemunde-banner-101.jpg);

			@media (max-width:992px) {
				background-image: url(RESOURCE/img/ostseeheilbad-travemunde-banner-101.jpg);
			}
		}

		&.banner2 {
			background-image: url(RESOURCE/img/ostseeheilbad-travemunde-banner-2.jpg);

			@media (max-width:992px) {
				background-image: url(RESOURCE/img/ostseeheilbad-travemunde-banner-2.jpg);
			}
		}

		&.banner3 {
			background-image: url(RESOURCE/img/ostseeheilbad-travemunde-banner-3.jpg);

			@media (max-width:992px) {
				background-image: url(RESOURCE/img/ostseeheilbad-travemunde-banner-3.jpg);
			}
		}

		&.banner4 {
			background-image: url(RESOURCE/img/ostseeheilbad-travemunde-banner-4.jpg);

			@media (max-width:992px) {
				background-image: url(RESOURCE/img/ostseeheilbad-travemunde-banner-4.jpg);
			}
		}

		&.banner5 {
			background-image: url(RESOURCE/img/ostseeheilbad-travemunde-banner-5.jpg);

			@media (max-width:992px) {
				background-image: url(RESOURCE/img/ostseeheilbad-travemunde-banner-5.jpg);
			}
		}

		&.banner6 {
			background-image: url(RESOURCE/img/ostseeheilbad-travemunde-banner-6.jpg);

			@media (max-width:992px) {
				background-image: url(RESOURCE/img/ostseeheilbad-travemunde-banner-6.jpg);
			}
		}

		&.banner7 {
			background-image: url(RESOURCE/img/ostseeheilbad-travemunde-banner-7.jpg);

			@media (max-width:992px) {
				background-image: url(RESOURCE/img/ostseeheilbad-travemunde-banner-7.jpg);
			}
		}

		&.banner8 {
			background-image: url(RESOURCE/img/ostseeheilbad-travemunde-banner-8.jpg);

			@media (max-width:992px) {
				background-image: url(RESOURCE/img/ostseeheilbad-travemunde-banner-8.jpg);
			}
		}

		&.banner9 {
			background-image: url(RESOURCE/img/ostseeheilbad-travemunde-banner-9.jpg);

			@media (max-width:992px) {
				background-image: url(RESOURCE/img/ostseeheilbad-travemunde-banner-9.jpg);
			}
		}

		&.banner10 {
			background-image: url(RESOURCE/img/ostseeheilbad-travemunde-banner-10.jpg);

			@media (max-width:992px) {
				background-image: url(RESOURCE/img/ostseeheilbad-travemunde-banner-10.jpg);
			}
		}

		&.banner11 {
			background-image: url(RESOURCE/img/ostseeheilbad-travemunde-banner-11.jpg);

			@media (max-width:992px) {
				background-image: url(RESOURCE/img/ostseeheilbad-travemunde-banner-11.jpg);
			}
		}

		&.banner12 {
			background-image: url(RESOURCE/img/ostseeheilbad-travemunde-banner-12.jpg);

			@media (max-width:992px) {
				background-image: url(RESOURCE/img/ostseeheilbad-travemunde-banner-12.jpg);
			}
		}

		&.banner13 {
			background-image: url(RESOURCE/img/ostseeheilbad-travemunde-banner-13.jpg);

			@media (max-width:992px) {
				background-image: url(RESOURCE/img/ostseeheilbad-travemunde-banner-13.jpg);
			}
		}

		&.banner14 {
			background-image: url(RESOURCE/img/ostseeheilbad-travemunde-banner-14.jpg);

			@media (max-width:992px) {
				background-image: url(RESOURCE/img/ostseeheilbad-travemunde-banner-14.jpg);
			}
		}

		&.banner15 {
			background-image: url(RESOURCE/img/ostseeheilbad-travemunde-banner-15.jpg);

			@media (max-width:992px) {
				background-image: url(RESOURCE/img/ostseeheilbad-travemunde-banner-15.jpg);
			}
		}

		&.banner16 {
			background-image: url(RESOURCE/img/ostseeheilbad-travemunde-banner-16.jpg);

			@media (max-width:992px) {
				background-image: url(RESOURCE/img/ostseeheilbad-travemunde-banner-16.jpg);
			}
		}

		&.banner17 {
			background-image: url(RESOURCE/img/ostseeheilbad-travemunde-banner-17.jpg);

			@media (max-width:992px) {
				background-image: url(RESOURCE/img/ostseeheilbad-travemunde-banner-17.jpg);
			}
		}

		&.banner18 {
			background-image: url(RESOURCE/img/ostseeheilbad-travemunde-banner-18.jpg);

			@media (max-width:992px) {
				background-image: url(RESOURCE/img/ostseeheilbad-travemunde-banner-18.jpg);
			}
		}

		&.banner19 {
			background-image: url(RESOURCE/img/ostseeheilbad-travemunde-banner-19.jpg);

			@media (max-width:992px) {
				background-image: url(RESOURCE/img/ostseeheilbad-travemunde-banner-19.jpg);
			}
		}

		&.banner20 {
			background-image: url(RESOURCE/img/ostseeheilbad-travemunde-banner-20.jpg);

			@media (max-width:992px) {
				background-image: url(RESOURCE/img/ostseeheilbad-travemunde-banner-20.jpg);
			}
		}
	}
} */

.aparthotel-ostseebad-grossenbrode {
	.item {
		/* &.banner1 {
			background-image: url(RESOURCE/img/ostseebad-grossenbrode-banner-1.jpg);

			@media (max-width:992px) {
				background-image: url(RESOURCE/img/ostseebad-grossenbrode-banner-1.jpg);
			}
		}

		&.banner2 {
			background-image: url(RESOURCE/img/ostseebad-grossenbrode-banner-2.jpg);

			@media (max-width:992px) {
				background-image: url(RESOURCE/img/ostseebad-grossenbrode-banner-2.jpg);
			}
		}

		&.banner3 {
			background-image: url(RESOURCE/img/ostseebad-grossenbrode-banner-3.jpg);

			@media (max-width:992px) {
				background-image: url(RESOURCE/img/ostseebad-grossenbrode-banner-3.jpg);
			}
		}

		&.banner4 {
			background-image: url(RESOURCE/img/ostseebad-grossenbrode-banner-4.jpg);

			@media (max-width:992px) {
				background-image: url(RESOURCE/img/ostseebad-grossenbrode-banner-4.jpg);
			}
		}

		&.banner5 {
			background-image: url(RESOURCE/img/ostseebad-grossenbrode-banner-5.jpg);

			@media (max-width:992px) {
				background-image: url(RESOURCE/img/ostseebad-grossenbrode-banner-5.jpg);
			}
		}

		&.banner6 {
			background-image: url(RESOURCE/img/ostseebad-grossenbrode-banner-6.jpg);

			@media (max-width:992px) {
				background-image: url(RESOURCE/img/ostseebad-grossenbrode-banner-6.jpg);
			}
		}

		&.banner7 {
			background-image: url(RESOURCE/img/ostseebad-grossenbrode-banner-7.jpg);

			@media (max-width:992px) {
				background-image: url(RESOURCE/img/ostseebad-grossenbrode-banner-7.jpg);
			}
		}

		&.banner8 {
			background-image: url(RESOURCE/img/ostseebad-grossenbrode-banner-8.jpg);

			@media (max-width:992px) {
				background-image: url(RESOURCE/img/ostseebad-grossenbrode-banner-8.jpg);
			}
		}

		&.banner9 {
			background-image: url(RESOURCE/img/ostseebad-grossenbrode-banner-9.jpg);

			@media (max-width:992px) {
				background-image: url(RESOURCE/img/ostseebad-grossenbrode-banner-9.jpg);
			}
		}

		&.banner10 {
			background-image: url(RESOURCE/img/ostseebad-grossenbrode-banner-10.jpg);

			@media (max-width:992px) {
				background-image: url(RESOURCE/img/ostseebad-grossenbrode-banner-10.jpg);
			}
		}

		&.banner11 {
			background-image: url(RESOURCE/img/ostseebad-grossenbrode-banner-11.jpg);

			@media (max-width:992px) {
				background-image: url(RESOURCE/img/ostseebad-grossenbrode-banner-11.jpg);
			}
		}

		&.banner12 {
			background-image: url(RESOURCE/img/ostseebad-grossenbrode-banner-12.jpg);

			@media (max-width:992px) {
				background-image: url(RESOURCE/img/ostseebad-grossenbrode-banner-12.jpg);
			}
		} */
	}
}

/* .aparthotel-hansestadt-wismar {
	.item {
		&.banner1 {
			background-image: url(RESOURCE/img/hansestadt-wismar-header-1.jpg);

			@media (max-width:992px) {
				background-image: url(RESOURCE/img/hansestadt-wismar-header-1.jpg);
			}
		}

		&.banner2 {
			background-image: url(RESOURCE/img/hansestadt-wismar-header-2.jpg);

			@media (max-width:992px) {
				background-image: url(RESOURCE/img/hansestadt-wismar-header-2.jpg);
			}
		}

		&.banner3 {
			background-image: url(RESOURCE/img/hansestadt-wismar-header-3.jpg);

			@media (max-width:992px) {
				background-image: url(RESOURCE/img/hansestadt-wismar-header-3.jpg);
			}
		}

		&.banner4 {
			background-image: url(RESOURCE/img/hansestadt-wismar-header-4.jpg);

			@media (max-width:992px) {
				background-image: url(RESOURCE/img/hansestadt-wismar-header-4.jpg);
			}
		}

		&.banner5 {
			background-image: url(RESOURCE/img/hansestadt-wismar-header-5.jpg);

			@media (max-width:992px) {
				background-image: url(RESOURCE/img/hansestadt-wismar-header-5.jpg);
			}
		}

		&.banner6 {
			background-image: url(RESOURCE/img/hansestadt-wismar-header-6.jpg);

			@media (max-width:992px) {
				background-image: url(RESOURCE/img/hansestadt-wismar-header-6.jpg);
			}
		}

		&.banner7 {
			background-image: url(RESOURCE/img/hansestadt-wismar-header-7.jpg);

			@media (max-width:992px) {
				background-image: url(RESOURCE/img/hansestadt-wismar-header-7.jpg);
			}
		}

		&.banner8 {
			background-image: url(RESOURCE/img/hansestadt-wismar-header-8.jpg);

			@media (max-width:992px) {
				background-image: url(RESOURCE/img/ohansestadt-wismar-header-8.jpg);
			}
		}

		&.banner9 {
			background-image: url(RESOURCE/img/hansestadt-wismar-header-9.jpg);

			@media (max-width:992px) {
				background-image: url(RESOURCE/img/hansestadt-wismar-header-9.jpg);
			}
		}

		&.banner10 {
			background-image: url(RESOURCE/img/hansestadt-wismar-header-10.jpg);

			@media (max-width:992px) {
				background-image: url(RESOURCE/img/hansestadt-wismar-header-10.jpg);
			}
		}

		&.banner11 {
			background-image: url(RESOURCE/img/hansestadt-wismar-header-11.jpg);

			@media (max-width:992px) {
				background-image: url(RESOURCE/img/hansestadt-wismar-header-11.jpg);
			}
		}

		&.banner12 {
			background-image: url(RESOURCE/img/hansestadt-wismar-header-12.jpg);

			@media (max-width:992px) {
				background-image: url(RESOURCE/img/hansestadt-wismar-header-12.jpg);
			}
		}

		&.banner13 {
			background-image: url(RESOURCE/img/hansestadt-wismar-header-13.jpg);

			@media (max-width:992px) {
				background-image: url(RESOURCE/img/hansestadt-wismar-header-13.jpg);
			}
		}
	}
} */

.heading1 {
	font-size: 42px;
}


.teams-bl {
	max-width: 1040px;
	margin: 0 auto;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;

	.team-bl {
		border: 1px solid #e8e8e8;

		.team-dt {

			border-top: none;
			padding: 20px 8px;
			text-align: center;
		}
	}
}


.contact-page {
	.control-label {
		text-align: left;
		padding-bottom: 5px;
	}

	.form-control {
		border: none;
		border-bottom: 2px solid #f2f2f2;
		text-transform: uppercase;
		margin-bottom: 30px;
		border-radius: 0;
		box-shadow: inherit;
		padding: 0;
	}

	label {
		color: var(--black);
		font-weight: 700;
		font-size: 14px;
		text-transform: uppercase;
		margin-bottom: 0;
		padding-bottom: 10px;
	}

	.checkbox {
		label {
			text-transform: inherit;
			font-weight: normal;
		}

		a {
			color: var(--black);
			font-size: 14px;
			text-decoration: underline !important;
		}

		.privacy-link {
			position: relative;
			top: 0px;
		}

	}

	.btn-go {
		box-shadow: none;
		font-size: 16px;
		padding: 10px 20px;
		border-radius: 0;

		&:hover {
			background: var(--HoverColor);
		}
	}
}

.newsletter-form-main {
	display: flex;
	width: 100%;
	flex-wrap: wrap;

	.left {
		.input-boxes {
			max-width: 600px;
			margin-top: 20px;
			margin-bottom: 30px;

			p {
				color: #303537;
				font-size: 18px;
				font-weight: 400;

				@media (max-width: 767px) {
					text-align: left !important;
				}
			}
		}
	}

	.newsletter-form {
		.form-group {
			display: flex;
			align-items: center;

			@media (max-width: 767px) {
				flex-wrap: wrap;
			}

			.control-label {
				max-width: 150px;
				width: 100%;
				padding: 0 10px 0 0;
				font-weight: 400;
				text-transform: unset;
				text-align: left;
				color: #303537;
				font-size: 18px;

				@media (max-width: 767px) {
					width: 100%;
					max-width: 100%;
				}
			}

			.form-control {
				background: #f1f5f6;
				height: 45px;
				margin: 0;
				border-radius: 0;
				border: none;
				box-shadow: none;
				font-size: 15px;
			}
		}

		.btn {
			font-weight: 500;
			letter-spacing: 1px;
			padding: 10px 20px 10px 20px;
			border-radius: 0;
			font-size: 14px;

			i {
				margin: 0 0 0 15px;
				font-size: 14px;
			}

			&:hover {
				background: var(--HoverColor);
			}
		}

		.informieren {
			width: 100%;

			p {
				font-family: var(--d-thin);
				color: #666;
				margin-bottom: 30px;
				font-size: 17px;
				line-height: 30px;

				a {
					color: #607dbe;
					text-decoration: underline;
				}
			}
		}
	}

	.right {
		.box {
			width: 240px;
			height: 240px;
			text-align: center;
			background: #bbdce7;
			border-radius: 100%;
			margin: 0 auto;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: center;
			flex-direction: column;

			@media (max-width: 767px) {
				margin: 40px auto 0;
			}

			@media all and (min-width: 768px) and (max-width: 1199px) {
				width: 200px;
				height: 200px;
			}


			h6 {
				width: 100%;
				margin: 0 0 20px 0;

				img {
					margin: 0 auto;
					width: 100px;

					@media all and (min-width: 768px) and (max-width: 1199px) {
						width: 70px;
					}
				}
			}

			h5 {
				margin: 0;
				font-size: 20px;
				line-height: 26px;
			}
		}
	}
}

.newsletter-content {
	margin: 60px 0 0 0;

	p {
		font-family: var(--d-thin);
		color: #666;
		margin-bottom: 30px;
		font-size: 17px;
		line-height: 30px;

		a {
			color: #607dbe;

		}
	}
}

.newsletter-bottom {
	background: #eaf0f3;
	padding: 50px 0 20px 0;
	margin: 50px 0 0 0;

	.box {
		width: 265px;
		height: 265px;
		background: #bbdce7;
		border-radius: 100%;
		margin: 0 auto;
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		flex-direction: column;
		margin-bottom: 30px !important;
		margin: 0 auto;

		@media all and (min-width: 768px) and (max-width: 991px) {
			width: 190px;
			height: 190px;
		}

		h6 {
			margin: 0 0 30px 0;

			img {
				margin: 0 auto;
				height: 100px;

				@media all and (min-width: 768px) and (max-width: 991px) {

					height: 60px;
				}
			}
		}

		h5 {
			color: #1c2639;
			font-size: 20px;
			line-height: 26px;
			margin: 0 0 0 0;

			@media all and (min-width: 768px) and (max-width: 991px) {
				font-size: 18px;
				line-height: 24px;
			}
		}
	}
}

.faq-group {
	margin: 30px 0;

	.panel-heading {
		border: none;
		background: var(--secondary);
		border-radius: 0;
		padding: 0;

		h4 {
			a {
				padding: 13px 60px 13px 15px;
				display: block;
				color: #fff;
				font-weight: 500;
				font-size: 18px;
				line-height: 26px;
				position: relative;

				&:after {
					display: block;
					content: " \f054 ";
					font-size: 16px;
					color: #fff;
					font-family: 'Font Awesome 5 Pro';
					font-weight: 600;
					position: absolute;
					right: 20px;
					top: 14px;
					transform: rotate(90deg);
					transition: all 300ms ease 0ms;
				}
			}

			a.collapsed {
				&:after {
					transform: rotate(0deg);
					transition: all 300ms ease 0ms;
				}
			}
		}
	}

	.panel-collapse {
		background: rgb(203, 213, 221) !important;
	}
}

.Aparthotel-three-blocks {
	padding: 40px 0 50px;

	&.Aparthotel-three-blocks-wismar {
		.owl-stage-outer {
			.owl-item {
				img {
					height: 300px;

					@media (max-width: 767px) {
						height: 300px;
					}

					@media (max-width: 574px) {
						height: 200px;
					}

					@media all (min-width: 768px) and (max-width: 991px) {
						height: 180px;
					}
				}
			}
		}
	}

	.owl-stage-outer {
		.owl-item {
			margin: 0 0 30px 0;

			@media all (min-width: 768px) and (max-width: 991px) {
				margin-bottom: 0;
			}

			img {
				width: 100%;
				max-width: 800px;
				height: 240px;
				object-fit: cover;


				@media (max-width: 767px) {
					height: 300px;
				}

				@media (max-width: 574px) {
					height: 200px;
				}

				@media all (min-width: 768px) and (max-width: 991px) {
					height: 180px;
				}
			}
		}

	}

	.next-prev-btns {
		.owl-nav {
			top: 37%;

			button {
				background: transparent !important;
				box-shadow: none;

				span:before {
					text-shadow: 0 0 8px rgb(0 0 0 / 70%);
					font-size: 30px;
				}
			}
		}
	}

	.upstalsboom-aparthotel {
		/* display: flex;
		fle x-wrap: wrap; */
		width: 100%;
		padding: 40px 0 0 0;

		h2 {
			margin: 0 0 30px 0;
			line-height: 30px;
		}

		ul {
			padding: 0;
			list-style: none;

			li {
				position: relative;
				font-size: 16px;
				margin: 0 0 10px 0;
				line-height: 26px;
				color: #303537;
				padding: 0 0 0 15px;

				&:before {
					display: block;
					content: " ";
					width: 1px;
					height: 15px;
					background: #303537;
					position: absolute;
					left: 0;
					top: 5px;
				}

			}
		}

		.btn-area {
			width: 100%;

			h6 {
				display: flex;
				-webkit-box-align: center;
				align-items: center;
				-webkit-box-pack: end;
				justify-content: flex-end;
				padding: 0 20px 0 0;
				margin: 30px 0 0 0;
				width: 100%;

				a {
					background: var(--primary);
					padding: 10px 20px;
					color: #fff;
					font-size: 16px;
					letter-spacing: 1px;
					border: 1px solid var(--primary);

					i {
						margin: 0 0 0 10px;
					}

					&:hover {
						background: var(--HoverColor);
					}
				}
			}
		}
	}
}

.die-lage-section {
	margin: 0 0 70px 0;

	.die-lage-inner {
		background: #eaf0f3;
		padding: 40px 20px 0 20px;

		.map {
			.contact-map {
				#map {
					min-height: 500px !important;

					@media (max-width: 991px) {
						min-height: 450px !important;

					}

					@media (max-width: 574px) {
						min-height: 300px !important;

					}
				}
			}
		}


		h2 {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: space-between;
			line-height: 30px;
			margin: 0 0 20px 0;
			align-items: baseline;

			a,
			span {
				color: #3167b2;
				font-size: 20px;
				font-weight: 400;
				text-decoration: underline;

				@media (max-width: 991px) {
					margin: 12px 0 0 0;
				}
			}
		}

		h4 {
			margin: 0 0 20px 0;
			font-size: 20px;
			line-height: 24px;
		}

		.left {
			@media (max-width: 991px) {
				margin: 0 0 30px 0;
			}

			h2 {
				align-items: baseline;
			}

			h5 {
				margin: 27px 0;

				a {
					display: block;
					width: 100%;
					text-align: center;
					text-decoration: underline;
					color: #3167b2;
					font-size: 18px;
					line-height: normal;
					font-weight: 400;
					text-decoration: underline;
				}
			}

			.map {
				margin-bottom: 50px;

				@media (max-width: 991px) {
					margin-bottom: 20px;
				}
			}
		}


		.right {
			ul.time-li {
				padding: 0;
				list-style: none;
				margin-bottom: 30px;

				@media (max-width: 574px) {
					margin-left: 0;
				}

				&:before {
					display: none;
				}

				li.prop {
					position: relative;
					list-style: none;
					padding-left: 130px;
					margin-bottom: 18px;
					font-size: 16px;
					line-height: 31px;

					@media (max-width: 574px) {
						padding-left: 115px;
					}

					@media (max-width: 374px) {
						padding-left: 91px;
						font-size: 14px;
					}

					&:before {
						display: none;
					}

					strong {
						left: 0;
					}
				}
			}
		}


		.img {
			margin: 0 0 30px 0;
			position: relative;

			a {
				position: absolute;
				bottom: 15px;
				right: 15px;
				color: #fff;
				font-size: 16px;

				&:hover {
					opacity: 0.7;
				}
			}
		}

		.left {
			.img {
				img {
					height: 530px;
					width: 100%;
					object-fit: cover;

					@media (max-width: 574px) {
						height: 200px;
					}

					@media all and (min-width: 768px) and (max-width: 991px) {
						height: 330px;
					}

					@media all and (min-width: 992px) and (max-width: 1199px) {
						height: 400px;
					}
				}
			}
		}

		.right {
			.img {
				img {
					height: 250px;
					width: 100%;
					object-fit: cover;

					@media (max-width: 574px) {
						height: 200px;
					}

					@media all and (min-width: 768px) and (max-width: 991px) {
						height: 150px;
					}

					@media all and (min-width: 992px) and (max-width: 1199px) {
						height: 185px;
					}
				}
			}
		}

		p {
			font-size: 17px;
			margin: 0 0 20px 0;
			line-height: 28px;
			color: #303537;
			padding: 0;
		}

		h6 {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			padding: 0 20px 0 0;
			//margin: 30px 0 0;
			width: 100%;

			a {
				background: var(--secondary);
				padding: 10px 20px;
				color: #fff;
				font-size: 16px;
				letter-spacing: 1px;
				border: 1px solid var(--secondary);
				text-transform: uppercase;

				&:hover {
					background: var(--HoverColor);
				}
			}
		}
	}
}

.location-of-aparthotel {
	h2 {
		min-height: 60px !important;

		@media (max-width: 991px) {
			min-height: auto !important;
		}
	}
}

.uber-die-section {
	padding-bottom: 70px;

	p {
		font-size: 17px;
		margin: 0 0 20px 0;
		line-height: 28px;
		color: #303537;
		padding: 0;
	}

	h6 {
		display: flex;
		-webkit-box-align: center;
		align-items: center;
		-webkit-box-pack: end;
		justify-content: flex-end;
		padding: 0 20px 0 0;
		margin: 30px 0 0 0;
		width: 100%;

		a {
			background: var(--secondary);
			padding: 10px 20px;
			color: #fff;
			font-size: 16px;
			letter-spacing: 1px;
			border: 1px solid var(--secondary);

			i {
				margin: 0 0 0 10px;
			}

			&:hover {
				background: var(--HoverColor);
			}
		}
	}
}

.three-box {
	margin: 0 0 50px 0;

	.boxes {
		background: #eaf0f3;
		padding: 50px 0 20px 0;
		display: flex;
		flex-wrap: wrap;
	}

	.box {
		background: #bbdce7;
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		width: 280px;
		height: 280px;
		flex-direction: column;
		border-radius: 100%;
		padding: 10px 48px;
		margin: 0 auto 30px;

		h4 {
			margin: 0 0 20px 0;
		}

		h5 {
			margin: 0 0 0 0;
			font-size: 18px;
			line-height: 24px;
		}
	}
}

.aparthotel-bottom-unit {
	margin: 0 0 70px 0;

	.min-row {
		display: flex;
		flex-wrap: wrap;

		.single-unit {
			margin: 0 !important;
			height: 100%;
			padding-bottom: 30px;
			display: flex;
			flex-direction: column;
			background: transparent;

			.info-col {
				height: 100%;
			}
		}
	}
}

.impressum-section {
	p {
		a {
			color: var(--primary);
			text-decoration: underline;
		}
	}
}

.single-unit {

	.unit-place.unit-place-only-icon {

		@media all and (min-width: 992px) and (max-width: 1199px) {
			min-height: 78px;
			align-items: baseline;
		}

		li.prop {
			margin-right: 4px !important;

			@media all and (min-width: 768px) and (max-width: 991px) {
				margin-right: 2px !important;
			}
		}
	}

	.unit-place {
		display: block;
		max-width: 100% !important;
		min-height: 158px;

		.icon-bg {
			background-image: url(RESOURCE/img/Icon_alle.svg);
			width: 27px;
			height: 27px;
			display: block;
			background-size: 55px;
		}

		.balcony {
			background-position: -15px -304px;
		}

		.sauna {
			background-position: -15px 142px;
		}

		.seeview {
			background-position: -15px -256px;
		}

		.pets {
			background-position: -15px -501px;
		}

		.wifi {
			background-position: -15px -646px;
		}

		.lift {
			background-position: -15px -699px;
		}

		.nonsmoking {
			background-position: -15px -600px;
		}

		.geschirrspuler {
			background-position: -15px -353px;
		}

		.kamin {
			background-position: -15px -550px;
		}

		.kinderbett {
			background-position: -15px -402px;
		}

		.kleinkindausstattung {
			background-position: -15px -452px;
		}

		.parking {
			background-position: -15px 241px;
		}

		.waschmaschine {
			background-position: -15px 43px;
		}

		img {
			width: 18px;
		}


		.prop {
			display: flex !important;
			align-items: center !important;
			margin: 0 10px 0 0 !important;
			flex-wrap: wrap;

			i {
				font-style: normal;
				margin: 0 0 0 7px !important;
				font-size: 16px !important;
			}

			.unit-prop-icon {
				background-image: url(RESOURCE/img/Icon_alle.svg);
				width: 30px;
				height: 30px;
				display: block;
				background-size: 55px;
				margin: 0 0 0 0;
			}

			.beds {
				background-position: -12px -9px;
			}

			.zimmer {
				background-position: -12px -108px;
			}

			.haustiere {
				background-position: -14px -503px;
			}

			.meerblick {
				background-position: -12px -256px;
			}
		}
	}
}

.Angebote-content {
	margin: 0 0 30px 0;

	.left {
		margin-bottom: 20px;

		img {
			width: 100%;
			max-height: 250px;
			object-fit: cover;
		}
	}

	.right {
		h3 {
			font-size: 18px;
			font-weight: 600;
			line-height: 24px;
			margin: 0 0 10px;
			display: block;

			a {
				color: var(--secondary);
				font-family: var(--d-bold);
			}
		}

		.date {
			background: url(RESOURCE/img/calendar-icon.png);
			margin: 5px 0 15px 0;
			background-repeat: no-repeat;
			color: #5a6e83;
			font-weight: 400;
			font-size: 14px;
			background-size: 14px;
			padding: 0 0 0 20px;
		}

		p {
			color: #555;
			font-size: 14px;
			line-height: 24px;
		}

		h6 {
			margin: 0;

			a {
				background: var(--primary);
				padding: 10px 20px;
				color: #fff;
				font-size: 16px;
				letter-spacing: 1px;
				border: 1px solid var(--primary);
				border-radius: 0;
				text-transform: uppercase;
				line-height: 16px;

				&:hover {
					background: var(--HoverColor);
				}

				i {
					margin: 0 0 0 10px;
				}
			}
		}
	}
}

#accordion {
	width: 100%;
	margin: 0 0 30px 0;

	.panel-default {
		border: none;
		box-shadow: none;
		margin: 0 0 15px 0;

		.panel-collapse {
			border: 1px solid #ddd;
			border-top: 0;
		}


		.panel-heading {
			padding: 0;
		}

		.panel-title {
			margin: 0;

			a {
				display: block;
				width: 100%;
				color: #fff;
				font-size: 20px;
				font-weight: 500;
				padding: 13px 35px 13px 15px;
				min-height: 50px;
				background: var(--primary);
				position: relative;

				@media (max-width: 574px) {
					font-size: 16px;
					padding: 15px 35px 15px 15px;
				}

				&.collapsed {
					span {
						&:before {
							display: block;
							width: 30px;
							height: 30px;
							font-family: Font Awesome\ 5 Free;
							content: "\f067";
							color: #fff;
							font-weight: 800;
							font-size: 14px;
						}
					}
				}

				span {
					position: absolute;
					right: 0;
					top: 19px;

					&:before {
						display: block;
						width: 30px;
						height: 30px;
						font-family: Font Awesome\ 5 Free;
						content: "\f068";
						color: #fff;
						font-weight: 800;
						font-size: 14px;
					}
				}
			}
		}



		.panel-body {
			border-top: unset;

			.boxe {
				.bottom-list {
					padding: 0;
				}
			}
		}
	}
}

.ct-form-section {
	h5 {
		font-size: 16px;
		font-weight: 600;
		margin: 30px 0 20px 0;
	}

	.contact-form {
		margin-bottom: 30px;

		.form-group {
			display: flex;
			-webkit-box-align: center;
			align-items: center;
			margin-left: 0;
			margin-right: 0;

			@media (max-width: 767px) {
				flex-wrap: wrap;
			}

			label {
				max-width: 150px;
				width: 100%;
				padding: 0 10px 0 0;
				font-weight: 400;
				text-transform: unset;
				text-align: left;
				color: #303537;
				font-size: 18px;

				@media (max-width: 767px) {
					margin-bottom: 5px;
				}
			}

			label.w-100 {
				width: 100%;
				max-width: 100%;
				color: var(--black);
				font-size: 14px;
				margin: 0 0 0 0;
				line-height: 23px;
				padding-left: 5px;


				&:before {
					top: 2px;
				}

				&:after {
					top: -2px;
				}

			}

		}

		.form-control {
			background: #f1f5f6;
			height: 45px;
			margin: 0;
			border-radius: 0;
			border: none;
			box-shadow: none;
			font-size: 15px;
			padding: 6px 12px !important;
			text-transform: unset !important;
		}

		textarea.form-control {
			padding: 10px 12px !important;
			height: 90px !important;
		}
	}
}

.download-section {
	padding: 20px 0 0 0;

	.container {
		display: flex;
		flex-wrap: wrap;
	}

	.box {
		margin: 0 0 40px 0;
		width: 50%;

		@media (max-width: 991px) {

			width: 100%;
		}

		.reverse {
			@media (min-width: 768px) {
				display: flex;
				flex-wrap: wrap;
				flex-direction: row-reverse;
			}
		}

		h4 {
			font-family: var(--d-bold);
			margin: 0 0 30px;
			font-size: 26px;
			font-weight: 600;
			color: var(--secondary);
			text-align: center;

			@media (max-width: 768px) {
				font-size: 22px;
				line-height: 30px;
			}
		}

		.img {
			margin: 0 0 30px 0;

			a {
				display: block;
				overflow: hidden;
				transition: 0.8s;


			}

			&:hover {
				img {
					/* transform: scale(1.1); */
					opacity: 1;
					border: 3px solid var(--secondary);
				}

			}

			img {
				height: auto;
				max-width: 400px;
				width: 100%;
				-webkit-transition: all .5s;
				-moz-transition: all .5s;
				transition: all .5s;
				/* transition: 0.8s; */
				border: 3px solid var(--white);
				margin: 0 auto;
				height: 400px;
				object-fit: cover;

				@media (max-width: 991px) {
					height: auto;
				}
			}

		}

		.text {
			.btn {
				padding: 0;
				color: var(--secondary);
				font-size: 16px;
				font-weight: 700;
				box-shadow: none;

				@media (max-width: 768px) {
					font-size: 14px;
				}

				&:hover {
					color: var(--HoverColor);
				}
			}
		}
	}
}


.map {
	iframe {

		@media (max-width: 991px) {
			height: 450px !important;

		}

		@media (max-width: 574px) {
			height: 300px !important;

		}

	}
}


.fur-vermieter-section {

	h1 {
		font-size: 36px;
		margin-bottom: 0;
		color: var(--secondary);
		line-height: normal;
		margin: 0 0 30px 0;
		position: relative;

		@media (max-width: 991px) {
			font-size: 30px;
		}

		&:after {
			display: block;
			background: #bfd5dc;
			content: " ";
			width: 100%;
			height: 2px;
			margin: 10px 0 0 0;
		}
	}

	ul {
		padding: 0;
		list-style: none;
		margin-bottom: 30px;

		li {
			position: relative;
			font-size: 16px;
			margin: 0 0 10px;
			line-height: 26px;
			color: #303537;
			padding: 0 0 0 15px;

			&:before {
				display: block;
				content: " ";
				width: 1px;
				height: 15px;
				background: #666;
				position: absolute;
				left: 0;
				top: 5px;
			}
		}
	}

	p {
		position: relative;
		font-size: 16px;
		margin: 0 0 30px;
		line-height: 26px;
		color: #303537;
	}
}

.waterkant-kitchen {
	.das-restaurant-banner {
		margin-bottom: 0;

		.map {
			margin-bottom: 30px !important;

			iframe {
				@media (max-width: 575px) {
					height: 300px !important;
				}
			}
		}

		.owl-item {
			img {
				height: 350px;

				@media (max-width: 991px) {
					height: auto;
				}
			}
		}
	}

	.waterkant-kitchen-text {
		margin: 30px 0 0 0;
	}

	.owl-nav {
		top: calc(50% - 20px) !important;

		@media (max-width: 767px) {
			top: 43% !important;
		}

		button {
			left: 15px !important;

			span {
				display: block !important;
			}
		}

		button.owl-next {
			right: 15px !important;
			left: unset !important;
		}
	}
}

.wellness-section {
	.das-restaurant-banner {
		.owl-item {
			img {
				height: auto !important;
			}
		}
	}

	h3 {
		color: var(--secondary);
		font-family: var(--d-bold);
		margin-top: 0;
		margin-bottom: 20px;
		font-size: 20px;
		line-height: normal;
	}

	.owl-dots {
		justify-items: center;
		position: absolute;
		max-width: 100%;
		width: 100%;
		left: 0;
		right: 0;
		margin: 0 auto;
		flex-wrap: wrap;
		flex-direction: initial;
		bottom: 20px;
		text-align: center;
		display: block;

		@media all (min-width: 992px) and (max-width: 1140px) {
			padding: 0 15px;
			bottom: 220px;
		}

		.owl-dot {
			margin: 0 5px;
			opacity: 0.5;

			span {
				width: 10px;
				height: 10px;
				background: #fff;
				display: block;
				border-radius: 100px;

			}

			&.active {
				opacity: 1;

			}
		}
	}
}



.single-unit {
	.box-price {
		width: 100% !important;

		.ab_price {
			font-size: 18px;
			font-weight: 600;
			color: var(--textprimary);
			margin-bottom: 0;
			display: block;
			line-height: 18px;
			margin-bottom: 0;
		}

		.pro {
			font-weight: 400;
			margin: 0;
			font-size: 14px;
			padding-top: 5px;
		}

		i {
			color: #000;
		}
	}
}

.Datenschutz-content {


	ul {
		padding: 20px 20px 10px 20px;
		background-color: #eaf0f3;
		list-style: none !important;
		margin-bottom: 20px;

		li {
			width: 100%;
			color: #666;
			margin-bottom: 10px;
			font-size: 17px;
			line-height: 27px;
			position: relative;
			padding: 0 0 0 17px;

			@media (max-width:992px) {
				font-size: 14px;
				line-height: 20px;
			}

			&:before {
				display: block;
				content: "\f054";
				color: #e2d69e;
				position: absolute;
				left: 0;
				top: 2px;
				font-family: Font Awesome\ 5 Pro;
				font-size: 12px;
			}
		}
	}
}

.static-view {
	.unit-virtual-tour {
		padding-bottom: 50px;

		iframe {
			width: 100%;
			height: 550px;

			@media (max-width: 993px) {
				height: 450px;
			}

			@media (max-width: 767px) {
				height: 350px;
			}

			@media (max-width: 550px) {
				height: 250px;
			}
		}
	}
}

.agb-content {
	p {
		margin-bottom: 20px;
	}

	ul.number-list {
		position: relative;
		padding: 0;
		margin: 0 0 10px;
		list-style: none;
		display: inline-block;

		li {
			position: relative;
			padding-left: 45px;
			font-family: var(--d-font);
			color: #666;
			margin-bottom: 20px;
			font-size: 17px;
			line-height: 30px;

			@media (max-width: 992px) {
				font-size: 14px;
				line-height: 20px;
				padding-left: 40px;
			}

			span.number {
				position: absolute;
				left: 0;
			}

			ul {
				padding: 0 0 0 30px;
				list-style: disc;
				margin: 20px 0 20px 0;

				li {
					margin: 0;
					padding: 0;
				}
			}

			ol {
				margin: 15px 0 0 0;
				list-style: none;
				padding: 0;

				li {
					padding-left: 45px;
				}

			}
		}
	}
}